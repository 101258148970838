import React, { useContext, Fragment } from 'react';
import { Grid, Link, Container, CircularProgress } from '@material-ui/core';
import * as config from '../../config/config';
import SelectLanguage from './ui/SelectLanguage';
import { LangContext } from '../../providers/LangProvider';
import SelectCurrency from './ui/SelectCurrency';
import { useStyles } from '../../styles/common/footerMenu/index';
import _ from 'lodash';
import LinkCustom from '../ui/components/Link/Link';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TelegramIcon from '@material-ui/icons/Telegram';

const { supportPhone, supportMail, saleMail, footerUrl,
    facebookLink, mainUrl} = config.getWebConfig();

function FooterMenu (props) {
    const classes = useStyles();
    const { setWebCurrency, withoutFooter, availableCurrencies, webCurrency } = props;
    const { t } = useContext( LangContext );

    if (withoutFooter) {
        return null;
    }

    const hasIconPaymentMethod = () => {
        return _.includes(
            ['front.55fly.ru', 'beta.55fly.ru', '55fly.ru'],
            mainUrl
        );
    }

    return (
        <div className={ classes.footerBlock }>
            <Container fixed>
                <Grid container direction='row'>
                    <Grid item xs={ 12 } sm={ 12 } md={ 5 } lg={ 4 } className={ classes.FooterColumn }>
                        <Grid className={ classes.FooterPhoneRow }>{ supportPhone }</Grid>
                        <Grid className={ classes.contactBlock }>
                            <Grid container>
                                { t.customer_support }
                                <Link className={ classes.MailLink } href={ `mailto:${ supportMail }` }>{ supportMail }</Link>
                            </Grid>
                            <Grid container>
                                { t.financial_matters }
                                <Link className={ classes.MailLink } href={ `mailto:${ saleMail }` }>{ saleMail }</Link>
                            </Grid>
                            <Grid container className={ classes.socialIcons }>
                                <Grid item>
                                    <Link 
                                        className= { classes.facebookLink }
                                        target='_blank'
                                        href={ `${ facebookLink }`}
                                    >
                                        <FacebookIcon fontSize="large" />
                                    </Link>
                                    <Link 
                                        className= { classes.instagramLink }
                                        target='_blank'
                                        href={ 'https://www.instagram.com/fly.tj/?hl=ru' }
                                    >
                                        <InstagramIcon fontSize="large"/>
                                    </Link>
                                    <Link 
                                        className= { classes.telegramLink }
                                        target='_blank'
                                        href={ 'https://t.me/flytj' }
                                    >
                                        <TelegramIcon fontSize="large"/>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={ 12 } sm={ 12 } md={ 4 } lg={ 5 }>
                        <Grid container className={ classes.FooterColumn_2 }>
                            <StaticPages { ...props }/>
                        </Grid>
                    </Grid>
                    <Grid item xs={ 10 } sm={ 4 } md={ 3 } lg={ 3 }>
                        <Grid container className={ classes.FooterColumn_3 }>
                            <Grid item>
                                <SelectCurrency
                                    availableCurrencies={ availableCurrencies }
                                    onChange={ setWebCurrency }
                                    webCurrency={ webCurrency }
                                />
                            </Grid>
                            <Grid item className={ classes.SelectLanguageBlock }>
                                <SelectLanguage />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={ 12 }>
                        <Grid container className={ classes.FooterLogoBlock }>
                        { hasIconPaymentMethod() &&
                            <Fragment>
                                <div className={ classes.paymentSystemsLogoBlock }>
                                    <div className={ classes.TKPItem }>
                                        <div className={ classes.TKPLogo }/>
                                    </div>
                                </div>
                                <div className={ classes.paymentSystemsLogoBlock }>
                                    <div className={ classes.visaItem }>
                                        <div className={ classes.visaLogo }/>
                                    </div>
                                </div>
                                <div className={ classes.paymentSystemsLogoBlock }>
                                    <div className={ classes.masterCarItem }>
                                        <div className={ classes.masterCardLogo }/>
                                    </div>
                                </div>
                                <div className={ classes.paymentSystemsLogoBlock }>
                                    <div className={ classes.mirItem }>
                                        <div className={ classes.mirLogo }/>
                                    </div>
                                </div>
                                <div className={ classes.paymentSystemsLogoBlock }>
                                    <div className={ classes.unitellerItem }>
                                        <div className={ classes.unitellerLogo }/>
                                    </div>
                                </div>
                            </Fragment>
                        }
                        </Grid>
                    </Grid>
                    <Grid item xs={ 12 } className={ classes.AgreementBlock }>
                        { t.agreement_years } { footerUrl }. { t.agreement_footer_text }
                    </Grid>
                </Grid>    
            </Container>
        </div>
    );
}

const StaticPages = (props) => {
    const { staticPagesListData, isLoadList } = props;
    const { t } = useContext(LangContext);
    const classes = useStyles();

    if (!isLoadList) {
        return <StaticPagesLoader/>
    }

    if (_.isEmpty(staticPagesListData)) {
        return null;
    }

    const checkIsFlyTJ = () => {
        return _.includes(
            ['fly.tj', 'agent.fly.tj'],
            mainUrl
        );
    };

    return (
        <Grid container className={ classes.staticPagesBLock }>
            {
                checkIsFlyTJ() &&
                <Grid className={ classes.staticPageLinkBlock } item>
                    <LinkCustom
                        className={ classes.staticPageLink }
                        text={ t.they_trust_us }
                        to={ `/theyTrustUs` }
                        target='_blank'
                    />
                </Grid>
            }
            <PagesLinkBlock items={ staticPagesListData }/>
            { 
                checkIsFlyTJ() &&
                    <> 
                        <Grid className={ classes.staticPageLinkBlock } item>
                            <LinkCustom
                                className={ classes.staticPageLink }
                                text={ t.popular_destinations }
                                to={ `/popularDestinations` }
                                target='_blank'
                            />
                        </Grid>
                        <Grid className={ classes.staticPageLinkBlock } item>
                            <LinkCustom
                                className={ classes.staticPageLink }
                                text={ t.contacts }
                                to={ `/contacts` }
                                target='_blank'
                            />
                        </Grid>
                    </>
            }
        </Grid>
    )
}
const StaticPagesLoader = () => {
    const classes = useStyles();

    return <Grid
        container
        justify='center'
        alignItems='center'
        direction='column'
    >
        <CircularProgress
            size={ 36 }
            className={ classes.circularProgress }
        />
    </Grid>
}

const PagesLinkBlock = (props) => {
    const classes = useStyles();
    const { items } = props;

    return _.map(items, function (item, index) {
        return <Grid
            className={ classes.staticPageLinkBlock }
            item
            key={ index }
        >
            <LinkCustom
                className={ classes.staticPageLink }
                text={ item.title }
                to={ `/infoPage/${ item.slug }` }
                target='_blank'
            />
        </Grid>
    })
}

export default FooterMenu;
