import { combineReducers } from 'redux';
import { setClientDataThunk, setClientData } from "../containers/auth/reducers";
import { setAuthError } from "../containers/auth/login/reducers";
import {setSearchData} from "../containers/search/reducers";
import {setPreBookData} from "../containers/booking/reducers";
import {setOrderData} from "../containers/order/reducers";
import {setOrdersData} from "../containers/orders/reducers";
import {setAppFrameData} from "../containers/app-frame/reducers";
import {setResultData} from "../containers/result/reducers";
import { setPersonalUserData } from '../containers/personalInfo/reducers';
import { setPassengers } from "../containers/passengers/reducers";
import { setPartnerData } from '../containers/partnerSettings/reducers';
import { setUsersList } from '../containers/users/reducers';
import { setCompaniesList } from '../containers/companies/reducers';
import { setCmsStaticPagesList } from '../containers/common/footer/reducers';
import { setStaticInfo } from '../containers/staticInfo/mainInfoPage/reducers';
import { setBalanceMovementsData } from '../containers/balanceMovements/reducers';
// import { setTransactionsList } from '../containers/finance/transactions/reducers';
import { reducer as form } from 'redux-form';

export default combineReducers({
  setAuthError,
  form,
  setClientData,
  setSearchData,
  setAppFrameData,
  setResultData,
  setPreBookData,
  setOrderData,
  setOrdersData,
  setPersonalUserData,
  setPassengers,
  setPartnerData,
  setUsersList,
  setCompaniesList,
  setCmsStaticPagesList,
  setStaticInfo,
  setBalanceMovementsData
  // setTransactionsList
  // setClientDataThunk
});
