import { SET_CMS_STATIC_PAGES_LIST } from './constants';
import initialState from './initialState';

export const setCmsStaticPagesList = (state = initialState, action) => {
  switch (action.type) {
      case SET_CMS_STATIC_PAGES_LIST:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};



