import { BY_MAX_PRICE, BY_MIN_PRICE } from '../containers/result/constants';

export const handleFiltersChange = async payload => {
    const search = window.location.search;
    let searchParams = new URLSearchParams(search);
    searchParams = paramsToArrayObject(searchParams.entries());

    switch(payload.type) {
        case 'baggage': handleChangeBaggage(searchParams, payload.status); break;
        case 'transfers': handleChangeTransfers(searchParams, payload.status); break;
        case 'sortType': handleChangeSortType(searchParams, payload.status); break;
        case 'airlines': handleChangeAirlines(searchParams, payload.status); break;
        case 'routesData': handleChangeRoutesData(searchParams, payload.status); break;
        case 'priceRange': handleChangePrice(searchParams, payload.status); break;
        case 'flightNumbersList': handleChangeFlightNumbersList(searchParams, payload.status); break;
        default: break;
    }
};

const paramsToArrayObject = entries => {
    const result = [];

    for(const [key, value] of entries) {
        result.push({ key, value });
    }

    return result;
};

const paramsToObject = entries => {
    const result = {};

    for(const [key, value] of entries) {
        result[key] = value;
    }

    return result;
};

const objectsToParams = objects => {
    if(objects.length > 0) {
        let params = '';
        objects.map((value, index) => {
            if(index === 0) {
                params += `?${ value.key }=${ value.value }`;
            } else {
                params += `&${ value.key }=${ value.value }`;
            }
        });
        return params;
    } else {
        return window.location.pathname;
    }
};

const handleChangeBaggage = (searchParams, baggage) => {
    let params = [...searchParams];

    if(baggage.withBaggage) {
        params = params.filter(value => value.key != 'withBaggage');
    } else {
        if(params.findIndex(x => x.key === 'withBaggage') === -1) {
            params.push({ key: 'withBaggage', value: 0 })
        }
    }

    if(baggage.withoutBaggage) {
        params = params.filter(value => value.key != 'withoutBaggage');
    } else {
        if(params.findIndex(x => x.key === 'withoutBaggage') === -1) {
            params.push({ key: 'withoutBaggage', value: 0 })
        }
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeTransfers = (searchParams, transfers) => {
    let params = [...searchParams];

    if(transfers.withoutTransfers) {
        params = params.filter(value => value.key != 'withoutTransfers');
    } else {
        if(params.findIndex(x => x.key === 'withoutTransfers') === -1) {
            params.push({ key: 'withoutTransfers', value: 0 })
        }
    }

    if(transfers.oneTransfers) {
        params = params.filter(value => value.key != 'oneTransfers');
    } else {
        if(params.findIndex(x => x.key === 'oneTransfers') === -1) {
            params.push({ key: 'oneTransfers', value: 0 })
        }
    }

    if(transfers.manyTransfers) {
        params = params.filter(value => value.key != 'manyTransfers');
    } else {
        if(params.findIndex(x => x.key === 'manyTransfers') === -1) {
            params.push({ key: 'manyTransfers', value: 0 })
        }
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeSortType = (searchParams, sortType) => {
    let params = [...searchParams];

    if(sortType == 'BY_MIN_PRICE') {
        params = params.filter(value => value.key != 'sortType');
    } else {
        if(params.findIndex(x => x.key === 'sortType') === -1) {
            params.push({ key: 'sortType', value: sortType })
        }
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeAirlines = (searchParams, airlines) => {
    let params = [...searchParams];
    params = params.filter(value => value.key != 'airlines');

    if(airlines.length !== 0) {
        params.push({ key: 'airlines', value: airlines.join() });
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeRoutesData = (searchParams, routes) => {
    let params = [...searchParams];

    routes.map(value => {
        params = params.filter(x => x.key != `route_${value.index}_deps` && x.key != `route_${value.index}_arrs`);
        if(value.departureAirports.length > 0) {
            params.push({ key: `route_${value.index}_deps`, value: value.departureAirports.join() });
        }
        if(value.arrivalAirports.length > 0) {
            params.push({ key: `route_${value.index}_arrs`, value: value.arrivalAirports.join() });
        }

        params = params.filter(x => x.key != `route_${value.index}_dur`);
        if(value.duration.minValue && value.duration.maxValue) {
            params.push({ key: `route_${value.index}_dur`, value: `${value.duration.minValue}-${value.duration.maxValue}` });
        }

        params = params.filter(x => x.key != `route_${value.index}_dept`);
        if(value.departureTime.minValue && value.departureTime.maxValue) {
            params.push({ key: `route_${value.index}_dept`, value: `${value.departureTime.minValue}-${value.departureTime.maxValue}` });
        }

        params = params.filter(x => x.key != `route_${value.index}_arrt`);
        if(value.arrivalTime.minValue && value.arrivalTime.maxValue) {
            params.push({ key: `route_${value.index}_arrt`, value: `${value.arrivalTime.minValue}-${value.arrivalTime.maxValue}` });
        }
    });

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangePrice = (searchParams, prices) => {
    let params = [...searchParams];
    params = params.filter(value => value.key != 'price');

    if(prices) {
        params.push({ key: 'price', value: `${prices.minValue}-${prices.maxValue}` });
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeFlightNumbersList = (searchParams, flightNumbers) => {
    let params = [...searchParams];
    params = params.filter(value => value.key != 'flightNumbers');

    if(flightNumbers.length !== 0) {
        const numbers = flightNumbers.split(' ');
        params.push({ key: 'flightNumbers', value: numbers.join() });
    }

    window.history.pushState(null, null, objectsToParams(params));
};

export const decodeParamsFromUrl = (priceRange, routesDataTemplate) => {
    const search = window.location.search;
    let searchParams = new URLSearchParams(search);
    searchParams = paramsToObject(searchParams.entries());

    let result = {};

    if(searchParams.withBaggage || searchParams.withoutBaggage) {
        result['baggage'] = {
            withBaggage: searchParams.withBaggage != 0,
            withoutBaggage: searchParams.withoutBaggage != 0
        };
    }

    if(searchParams.withoutTransfers || searchParams.oneTransfers || searchParams.manyTransfers) {
        result['allTransfers'] = false;
        result['transfers'] = {
            withoutTransfers: searchParams.withoutTransfers != 0,
            oneTransfers: searchParams.oneTransfers != 0,
            manyTransfers: searchParams.manyTransfers != 0
        };
    }

    if(searchParams.sortType) {
        if(result['sortType'] === BY_MAX_PRICE || result['sortType'] === BY_MIN_PRICE) {
            result['sortType'] = searchParams.sortType;
        }
    }

    if(searchParams.airlines) {
        result['airlines'] = searchParams.airlines.split(',');
    }

    if(searchParams.price) {
        const prices = searchParams.price.split('-');
        if(prices.length === 2) {
            result['priceRange'] = {
                minValue: parseInt(prices[0]) >= priceRange.minValue ? parseInt(prices[0]) : priceRange.minValue,
                maxValue: parseInt(prices[1]) <= priceRange.maxValue ? parseInt(prices[1]) : priceRange.maxValue
            };
        }
    }

    if(searchParams.flightNumbers) {
        result['flightNumbers'] = true;
        result['flightNumbersList'] = searchParams.flightNumbers.split(',').join(' ');
    }

    const { airports, duration, departureTime, arrivalTime, template } = checkRoutesData(searchParams, routesDataTemplate);

    result['airports'] = airports;
    result['duration'] = duration;
    result['departureTime'] = departureTime;
    result['arrivalTime'] = arrivalTime;
    result['routesData'] = template;

    return result;
};

const checkRoutesData = (searchParams, routesDataTemplate) => {
    let airports = false;
    let duration = false;
    let departureTime = false;
    let arrivalTime = false;

    for(let i = 0; i <= routesDataTemplate.length; i++) {
        if(searchParams[`route_${i}_deps`]) {
            routesDataTemplate[i].departureAirports = searchParams[`route_${i}_deps`].split(',');
            airports = true;
        }
        if(searchParams[`route_${i}_arrs`]) {
            routesDataTemplate[i].departureAirports = searchParams[`route_${i}_deps`].split(',');
            airports = true;
        }
        if(searchParams[`route_${i}_dur`]) {
            let dur = searchParams[`route_${i}_dur`].split('-');
            if(dur.length === 2) {
                routesDataTemplate[i].duration = { minValue: parseInt(dur[0]), maxValue: parseInt(dur[1]) };
            }
            duration = true;
        }
        if(searchParams[`route_${i}_dept`]) {
            let dept = searchParams[`route_${i}_dept`].split('-');
            if(dept.length === 2) {
                routesDataTemplate[i].departureTime = { minValue: parseInt(dept[0]), maxValue: parseInt(dept[1]) };
            }
            departureTime = true;
        }
        if(searchParams[`route_${i}_arrt`]) {
            let arrt = searchParams[`route_${i}_arrt`].split('-');
            if(arrt.length === 2) {
                routesDataTemplate[i].arrivalTime = { minValue: parseInt(arrt[0]), maxValue: parseInt(arrt[1]) };
            }
            arrivalTime = true;
        }
    }

    return {
        airports,
        duration,
        departureTime,
        arrivalTime,
        template: routesDataTemplate
    }
};