import React, { useState, useContext } from 'react';
import { Container, Grid, CircularProgress } from '@material-ui/core';
import { useStyles } from '../../containers/search/styles';
import { LangContext } from '../../providers/LangProvider';
import config from '../../config/config';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

const SubscriptionBlock = () => {
    const classes = useStyles();
    const { t } = useContext(LangContext);
    const { isB2c } = config;
    const { mainUrl } = config.getWebConfig();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [emailError, setEmailError] = useState(false);

    const checkIsFlyTJ = () => {
        return _.includes(
            ['fly.tj'],
            mainUrl
        );
    };

    const sendData = () => {
        if(validateEmail()) {
            setEmailError(false);
            setIsLoading(true);
            axios.post(
                'https://sheet.best/api/sheets/3f4816c5-7c0a-4562-b870-10918148eff7',
                {
                    'Email': email,
                    'Date of subscription': moment().format('DD-MM-YYYY hh:mm:ss'),
                    'From site': mainUrl
                }
            )
                .then(response => {
                    setResult('success');
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    setResult('error');
                });
        } else {
            setEmailError(true);
        }
    };

    const validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    if(isB2c && checkIsFlyTJ()) {
        return (
            <div className={ classes.subscriptionContainer }>
                <Container fixed maxWidth={ 'lg' }>
                    <Grid container spacing={ 2 }>
                        <Grid item xs={ 12 } md={ 6 }>
                            <div className={ classes.subscriptionTitle }>
                                { t.newsletter_subscription }
                            </div>
                            <div>
                                { t.newsletter_subscription_text }
                            </div>
                        </Grid>
                        <Grid item xs={ 12 } md={ 6 } className={ classes.subscriptionForm }>
                            <Grid container spacing={ 2 }>
                                <Grid item xs={ 6 } sm={ 8 }>
                                    <input 
                                        value={ email } 
                                        onChange={ e => setEmail(e.target.value) } 
                                        placeholder={ t.email }
                                        className={ classes.emailInput }
                                    />
                                </Grid>
                                <Grid item xs={ 6 } sm={ 4 }>
                                    <button className={ classes.subscriptionButton } disabled={ isLoading } onClick={ () => sendData() }>
                                        {
                                            isLoading ? <CircularProgress size={ 26 } className={ classes.circularProgress } /> : t.subscribe
                                        }
                                    </button>
                                </Grid>
                                {
                                    result &&
                                        <Grid item xs={ 12 }>
                                            { result === 'success' && <span className={ classes.successResult }>{ t.successfully_subscribed }</span> }
                                            { result === 'error' && <span className={ classes.errorResult }>{ t.subscription_error }</span> }
                                        </Grid>
                                }
                                {
                                    emailError &&
                                        <Grid item xs={ 12 }>
                                            <span className={ classes.errorResult }>{ t.invalid_email }!</span>
                                        </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>  
        );
    }

    return null;
};

export default SubscriptionBlock
