import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    buttonLoading: {
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '16%',
        left: '40%'
    }
});

function ButtonUI (props) {
    const classes = useStyles();
    const { title, fontSize, height, isGreen, endIcon, onClick,
        disabled, isLoading } = props;

    const CustomButton = withStyles({
        root: {
            height: height ? height : '40px',
            borderRadius: '4px',
            color: '#fff',
            cursor: 'pointer',
            fontSize: fontSize ? fontSize : '16px',
            fontWeight: 500,
            textTransform: 'none',
            backgroundColor: isGreen ? '#4caf50' : '#ff7043',
            '&:hover': {
                backgroundColor: isGreen ? '#449d48' : '#f05e30'
            },
            '&:active': {
                backgroundColor: isGreen ? '#3d8b40' : '#c63f17'
            },
            '&:focus': {
                outline: 'none'
            },
            '&:disabled': {
                backgroundColor: '#cfd8dc',
                color: '#fff',
                cursor: 'default',
                borderColor: '#f2f4f5'
            }
        },
    })(Button);

    return (
        <Box className={ classes.buttonLoading }>
            <CustomButton
                variant='contained'
                disableRipple
                onClick={ onClick }
                endIcon={ endIcon }
                disabled={ disabled }
            >
                { title }
            </CustomButton>
            { isLoading &&
                <CircularProgress
                    style={{ color: '#ff7043' }}
                    size={ 24 }
                    className={ classes.buttonProgress }
                />
            }
        </Box>
    );
}

export default ButtonUI;