import { SET_CLIENT_DATA, SET_IS_AUTH, SET_PARTNER_LOGO, SET_CLIENT_DATA_ALERT } from './constants';
import initialState from './initialState';
import { API } from '../../lib/api';

export const setClientData = (state = initialState, action) => {
  switch (action.type) {
      case SET_CLIENT_DATA:
          return {
            ...state,
            ...action.payload,
            loaded: true
          };
      case SET_IS_AUTH:
          return {
              ...state,
              isAuth: action.payload
          };
      case SET_PARTNER_LOGO:
          return {
              ...state,
              partnerLogo: action.payload
          };
      case SET_CLIENT_DATA_ALERT:
          return {
              ...state,
              isShowClientDataAlert: action.payload
          };
        default:
            return state;
  }
};

export const setClientDataThunk = () => {
    return (dispatch) => {
        API.getUserData().then(response => {
            dispatch(setClientData(response));
        })
    }
};



