import { SET_USER_PASSWORD, SET_LOAD_USER_DATA } from './constants';
import initialState from './initialState';

export const setPersonalUserData = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_PASSWORD:
            return {
                ...state,
                dataPassword: action.payload.passwordData,
                isLoadPassword: action.payload.loadPasswordStatus
            };
        case SET_LOAD_USER_DATA:
            return {
                ...state,
                isLoadUserData: action.payload.loadUserData
            }
        default:
            return state;
    }
};