import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { StyledInput } from '../../../../styles/common/index';
import UI from "../../ui";

class Input extends Component {
    render(){
        const { id, type, uppercaseType, textTransform, name,
            disabled, value, defaultValue, widthSize,
            heightSize, ...input } = this.props;

        return(
            <StyledInput
                { ...input }
                disabled={ disabled }
                type={ type }
                textTransform={ textTransform }
                widthSize={ widthSize }
                heightSize={ heightSize }
                name={ name }
                id={ id }
                uppercase={ uppercaseType }
                value={ value || defaultValue }
            />
        );
    }
}

Input.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    textTransform: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

Input.defaultProps = {
    name: null,
    id: null,
    textTransform: 'none',
    disabled: null,
    type: 'text',
    placeholder: null,
    defaultValue: ''
};

export default Input;
