import React, { Component } from 'react';

class Button extends Component {
    render(){
        const { text } = this.props;
        return(
            <button {...this.props} >
                    {text}
            </button>
        );
    }
}

Button.defaultProps = {
    type: 'submit',
    disabled: false,
    text: null
};

export default Button;
