import React, { useContext } from 'react';
import { LangContext } from '../../../providers/LangProvider';
import _ from 'lodash';
import SelectTransparentUI from '../../ui/components/SelectTransparentUI/SelectTransparentUI';

export default function SelectCurrency(props) {
    const { t } = useContext(LangContext);
    const { availableCurrencies, onChange, webCurrency } = props;

    const currencyItems = _.map(availableCurrencies, function (item) {
        return { value: item, label: item }
    });

    return <SelectTransparentUI
        items={ currencyItems }
        value={ webCurrency }
        label={ t.currency }
        onChange={ onChange }
        color='#212121'
        fontSize='18px'
    />
}