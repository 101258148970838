import React, { useState, Fragment, useContext } from 'react';
import { Button, Dialog, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { LangContext } from '../../../../providers/LangProvider';

const useStyles = makeStyles({
    contentContainer: {
        padding: '16px 12px 0 32px',
        fontSize: 14
    },
    titleBlock: {
        paddingBottom: 8,
        fontWeight: 500
    },
    titleContent: {
        fontStyle: 'oblique'
    },
    session: {
        fontWeight: 500
    }
});

function ErrorModal(props) {
    const classes = useStyles();
    const { t } = useContext( LangContext );
    const { error_code, error_desc, sessionId, withArea } = props;
    const [open, setOpen] = useState(true);
    const [redirect, setRedirect] = useState(false);

    const handleClose = () => {
        setOpen(false);

        setRedirect(true);
    };

    const withAreaStyle = {
        height: withArea ? '600px' : '10px',
        position: 'relative'
    };

    return(
        <Fragment>
            { redirect && <Redirect to='/' /> }

            <div style={ withAreaStyle }>
                <Dialog
                    open={ open }
                    onClose={ handleClose }
                    style={{
                        position: 'absolute',
                        bottom: '25%'
                    }}
                >
                    <div className={ classes.contentContainer }>
                        <div className={ classes.titleBlock }>
                            <div>
                                <span>{ t.code }: </span><span className={ classes.titleContent }>{ error_code }</span>
                            </div>
                            <div>
                                <span>{ t.message }: </span><span className={ classes.titleContent }>{ error_desc }</span>
                            </div>
                        </div>
                        <div>
                            { RenderMessage(sessionId) }
                        </div>
                    </div>
                    <DialogActions>
                        <Button onClick={ handleClose }>
                            { t.close }
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Fragment>
    );
}

const RenderMessage = (sessionId) => {
    const classes = useStyles();
    const { t } = useContext( LangContext );

    if (sessionId) {
        return (
            <Fragment>
                <div>{ t.error_message_part_1 } { t.error_message_part_2 }:
                    <span className={ classes.session }>{ sessionId }</span>.
                </div>
                <div>{ t.error_message_part_3 }</div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div>{ t.error_message_part_1 }.</div>
            <div>{ t.error_message_part_3 }</div>
        </Fragment>
    );
};

ErrorModal.defaultProps = {
    error_code: null,
    error_desc: null,
    sessionId: null,
    withArea: true
};

export default ErrorModal;