import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import UI from '../../ui';
import { ErrorsFieldBlock, FormGroup } from '../../../../styles/common/index';
import TooltipUI from '../TooltipUI/TooltipUI';
import { LangContext } from '../../../../providers/LangProvider';

function ResponsiveFormField (props) {
    const { t } = useContext( LangContext );
    const {
        label,
        name,
        type,
        meta: { touched, error },
        placeholder,
        disabled,
        input,
        fieldError,
        autoFocus,
        isPadding,
        onClick,
        tooltipTitle,
        defaultValue,
        InputComponent
    } = props;

    return (
      <FormGroup>
        <UI.Label text={ label } />
        <TooltipUI title={ tooltipTitle }>
            <InputComponent
                { ...input }
                type={ type }
                name={ name }
                placeholder={ placeholder }
                disabled={ disabled }
                autoFocus={ autoFocus }
                isPadding={ isPadding }
                autoComplete='off'
                onClick={ onClick }
                defaultValue={ defaultValue }
                borderColor={ fieldError || (touched && error) ? '#c62828' : '' }
            />
        </TooltipUI>
        <ErrorsFieldBlock>
            { touched && ((error &&
                <UI.InputMessage text={ t[error] } />
            ))}
            { fieldError &&
                <UI.InputMessage text={ fieldError } />
            }
        </ErrorsFieldBlock>
      </FormGroup>
    );
}

ResponsiveFormField.propTypes = {
    input: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    tooltipTitle: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    fieldError: PropTypes.string,
    defaultValue: PropTypes.string,
    meta: PropTypes.object,
    autoFocus: PropTypes.bool,
};

ResponsiveFormField.defaultProps = {
    disabled: null,
    label: null,
    name: null,
    placeholder: null,
    tooltipTitle: null,
    type: 'text',
    fieldError: null,
    defaultValue: '',
    autoFocus: false,
    onClick: () => {},
    meta: {
        touched: null,
        error: null,
        warning:  null
    }
};

export default ResponsiveFormField;
