import React, { Fragment } from 'react';
import './App.css';
import Routes from "./routes";
import AppFrameContainer from "./containers/app-frame/appFrameContainer";
import Footer from "./containers/common/footer/index";
import { AppContainer } from "./styles/common/index";

function App() {
    return (
        <Fragment>
            <AppContainer id="AppContainer">
                <AppFrameContainer/>
                <Routes/>
            </AppContainer>
            <Footer/>
        </Fragment>
    );
}

export default App;