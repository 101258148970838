import styled from 'styled-components'

export const HeaderContainer = styled.div`
    text-align: center;
    width: 100%;
    color: #fff;

    ${props => props.headerWithoutImg && `
        background-color: #121c2f;
    `}

    @media screen and (max-width: 960px) {
        background-color: #121c2f;
    }
`;