import React, { useContext } from 'react';
import MaskedInput from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import { ErrorsFieldBlock } from "../../../../styles/common/index";
import InputMessage from "../InputMessage/InputMessage";
import InputWarning from "../InputWarning/InputWarning";
import { LangContext } from '../../../../providers/LangProvider';

const useStyles = makeStyles({
    container: {
        flexWrap: 'wrap',
    },
    formControl: {
        position: 'relative',
        display: 'flex'
    },
    input: {
        width: '100%',
        height: 'auto',
        borderRadius: '4px',
        border: 'solid 1px #cfd8dc',
        paddingLeft: '16px',
        paddingTop: '24px',
        paddingBottom: '3px'
    },
    inputFocused: {
        outline: 'none',
        border: 'solid 1px #ff7043'
    },
    label: {
        fontSize: '12px',
        color: '#9da8ae',
        position: 'absolute',
        top: '10px',
        left: '16px'
    }
});

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
        />
    );
}

export default function DatePickerForm(props) {
    const { t } = useContext( LangContext );
    const classes = useStyles();
    const {
        label,
        input,
        fieldError,
        warning,
        meta: { touched, error },
    } = props;

    let errorStyle = {};

    if(fieldError || (touched && error)) {
        errorStyle = {
            border: "solid 2px #c62828"
        };
    }

    return (
        <FormControl className={ classes.formControl }>
            <Box className={ classes.label }>
                <span>{ label }</span>
            </Box>

            <Input
                { ...input }
                className={ classes.input }
                autoComplete='off'
                inputComponent={ TextMaskCustom }
                placeholder={ t.placeholder_date_picker }
                disableUnderline={ true }
                classes={{ focused: classes.inputFocused}}
                style={
                    errorStyle
                }
            />

            <ErrorsFieldBlock>
                { touched && ((error &&
                    <InputMessage text={ t[error] } />
                ))}
                { touched && ((!error && warning) &&
                    <InputWarning text={ t[warning] } />
                )}
                { fieldError &&
                    <InputMessage text={ fieldError } />
                }
            </ErrorsFieldBlock>
        </FormControl>
    );
}

DatePickerForm.defaultProps = {
    label: null
};