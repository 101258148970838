import React, { Component } from 'react';
import UI from '../../ui';
import Box from '@material-ui/core/Box';
import * as Styled from "../../../../styles/common/ui/formSelect/index";
import onClickOutside from "react-onclickoutside";

class FormSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdown: false
        };
    }

    handleClickOutside = evt => {
        this.hideDropdown();
    };

    hideDropdown() {
        this.setState({
            showDropdown: false
        });
    }

    handleDropdown() {
        this.setState({
            showDropdown: !this.state.showDropdown
        });
    }

    handleOnClick(value) {
        this.props.onChange(value);

        this.setState({
            showDropdown: false
        });
    }

    render() {
        const { input, selectedValue, items, widthSize, onChange, fontSize,
            textColor, invert, widthDropdown, withoutBorder, withArea, label,
            isPadding, withoutLabel, heightSize, turned
        } = this.props;

        return (
            <Box
                onClick={this.handleDropdown.bind(this)}
                className="selectIgnoreLink"
                style={{
                    position: 'flex'
                }}
            >
                { !withoutLabel &&
                    <Box style={{
                        position: 'relative'
                    }}>
                        <UI.Label text={label} />
                    </Box>
                }
                    <Styled.DropdownSelectInput
                        {...input}
                        outsideClickIgnoreClass={"selectIgnoreLink"}
                        value={selectedValue['label']}
                        widthSize={widthSize}
                        heightSize={heightSize}
                        onChange={onChange}
                        textColor={textColor}
                        fontSize={fontSize}
                        withoutLabel={withoutLabel}
                        withoutBorder={withoutBorder}
                        withArea={withArea}
                        isPadding={isPadding}
                    />

                    { !withoutLabel &&
                        <Styled.ArrowDownContainer
                            withArea={withArea}
                            withoutLabel={withoutLabel}
                        >
                            <Styled.ArrowDown isInvert={invert}/>
                        </Styled.ArrowDownContainer>
                    }

                    { withoutLabel &&
                        <Styled.ArrowDownWithoutLabelContainer withoutLabel={withoutLabel}>
                            <Styled.ArrowDown isInvert={invert}/>
                        </Styled.ArrowDownWithoutLabelContainer>
                    }


                { this.state.showDropdown &&
                    <Styled.DropdownBlock >
                        <UI.Dropdown
                            width={widthDropdown}
                            items={items}
                            selected={selectedValue['value']}
                            onSelect={this.handleOnClick.bind(this)}
                            readOnly={true}
                            turned={ turned }
                        />
                    </Styled.DropdownBlock>
                }
            </Box>
        );
    }
}

FormSelect.defaultProps = {
    invert: false,
    isPadding: false,
    withoutLabel: false,
    textColor: null,
    fontSize: null,
    widthDropdown: null,
    withoutBorder: false,
    withArea: true,
    label: null,
    turned: false,
    onChange: () => {}
};

export default onClickOutside(FormSelect);