import React, { Fragment, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { LangContext } from '../../../../providers/LangProvider';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles({
    dialogContent: {
        padding: '8px 16px'
    },
    contentContainer: {
        fontSize: 16,
    },
    titleBlock: {
        textAlign: 'right',
        lineHeight: 1,
        padding: '16px'
    },
    closeButton: {
        cursor: 'pointer',
    },
    dialogActions: {
        padding: '16px'
    },
    cancelButton: {
        height: '40px',
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: '#fafbfc',
        '&:active': {
            backgroundColor: '#cfd8dc'
        },
        '&:disabled': {
            backgroundColor: '#f2f4f5',
            color: '#b9c1c5'
        },
    },
    buttonTitle: {
        paddingLeft: '8px',
        paddingRight: '8px'
    },
    submitButtonBlock: {
    },
    submitButton: {
        height: '40px',
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#ffffff',
        textTransform: 'none',
        backgroundColor: '#ff7043',
        '&:active': {
            backgroundColor: '#c63f17'
        },
        '&:hover': {
            backgroundColor: '#f05e30'
        },
        '&:disabled': {
            backgroundColor: '#f7a65e',
            color: '#fff',
            cursor: 'default',
            borderColor: '#f2f4f5'
        },
    }
});

export default function ConfirmationModal(props) {
    const classes = useStyles();
    const { t } = useContext( LangContext );
    const { message, setOpen, isOpen, onConfirmation } = props;

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <Fragment>
            <Dialog
                open={ isOpen }
                onClose={ handleClose }
                maxWidth={ 'xs' }
                fullWidth={ true }
            >
                <DialogTitle
                    id='dialog-title' 
                    className={ classes.titleBlock }
                    disableTypography={ true }
                >
                    <CloseIcon
                        className={ classes.closeButton }
                        onClick={ handleClose }
                    />
                </DialogTitle>
                <DialogContent className={ classes.dialogContent }>
                    <Box className={ classes.contentContainer }>
                        { message }
                    </Box>
                </DialogContent>
                <DialogActions className={ classes.dialogActions }>
                    <Button
                        onClick={ handleClose }
                        disableRipple
                        className={ classes.cancelButton }
                    >
                        <Box className={ classes.buttonTitle }>
                            { t.cancel }
                        </Box>
                    </Button>
                    <Box className={ classes.submitButtonBlock }>
                        <Button
                            onClick={ onConfirmation }
                            className={ classes.submitButton }
                            disableRipple
                        >
                            <Box className={ classes.buttonTitle }>
                                { t.confirm }
                            </Box>
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

ConfirmationModal.defaultProps = {
    message: null,
    isOpen: false,
    setOpen: () => {},
    onConfirmation: () => {}
};