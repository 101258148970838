import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Field, reduxForm } from 'redux-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Draggable from 'react-draggable';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { compose } from 'redux';
import { useStyles } from '../../containers/users/styles';
import FormField from '../ui/components/FormField/FormField';
import { normalizeUserName } from '../../lib/normalize';
import PhoneField from '../ui/components/PhoneField/PhoneField';
import { required, phone } from '../../lib/validate';
import { LangContext } from '../../providers/LangProvider';
import SubmitButton from '../ui/components/SubmitButton/SubmitButton';
import { CLIENT_NAME, PHONE } from '../../containers/users/constants';
import _ from 'lodash';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper { ...props } />
        </Draggable>
    );
}

function AddUserDataForm (props) {
    const classes = useStyles();
    const { t } = useContext( LangContext );
    const { openModal, setOpenModal, handleSubmit, saveUser, reset,
        setAlert, userData, setDefaultData } = props;
    const { clientPhone, clientName } = userData;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setDefaultData();
    }, [clientPhone, clientName])

    const handleCloseByButton = () => {
        if (!loading) {
            handleClose();
        }
    };

    const handleClose = () => {
        setOpenModal(false);
        reset();
    };

    const handelSaveUser = (data) => {
        setLoading(true);
        saveUser(data, setUpdateStatus);
    };

    const setUpdateStatus = (status) => {
        setLoading(false);
        setOpenModal(false);

        setAlert(
            status,
            status ? t.saved_successfully : t.error_saving
        );
    };

    return (
        <Dialog
            open={ openModal }
            onClose={ handleClose }
            PaperComponent={ PaperComponent }
            maxWidth='xs'
            fullWidth={ true }
        >
            <Box>
                <DialogTitle
                    style={{ cursor: 'move' }}
                    id='draggable-dialog-title'
                    className={ classes.titleBlock }
                >
                    <Box component='span' className={ classes.title }>
                        { t.add_your_details }
                    </Box>
                    <CloseIcon
                        className={ classes.closeButton }
                        onClick={ handleClose }
                    />
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={ handleSubmit((data) => handelSaveUser(data)) }>
                        <UserFields
                            clientPhone={ clientPhone }
                            clientName={ clientName }
                        />
                        <DialogActions>
                            <SubmitButtonForm
                                isLoading={ loading }
                                onClose={ handleCloseByButton }
                            />
                        </DialogActions>
                    </form>
                </DialogContent>
            </Box>
        </Dialog>
    );
}

const SubmitButtonForm = (props) => {
    const classes = useStyles();
    const { t } = useContext( LangContext );
    const { isLoading, onClose, disabled } = props;

    return (
        <Box className={ classes.submitBlock }>
            <Button
                onClick={ onClose }
                disableRipple
                disabled={ isLoading }
                className={ classes.cancelButton }
            >
                <Box className={ classes.cancelButtonTitle }>
                    { t.cancel }
                </Box>
            </Button>
            <Box className={ classes.submitButtonBlock }>
                <SubmitButton
                    isLoading={ isLoading }
                    disabled={ disabled }
                />
            </Box>
        </Box>
    )
}

const UserFields = ({ clientName, clientPhone }) => {
    const classes = useStyles();
    const { t } = useContext( LangContext );

    const handleOnBlur = (event) => {
        event.preventDefault()
    };

    return (
        <Grid container className={ classes.userContainer } spacing={ 0 }>
                { _.isEmpty(clientName) &&
                    <Grid item className={ classes.userFieldItem }>
                        <Field
                            component={ ItemInput }
                            name={ CLIENT_NAME }
                            label={ t.client_name }
                            onBlur={ handleOnBlur }
                            normalize={ normalizeUserName }
                            validate={[
                                required
                            ]}
                        />
                    </Grid>
                }
            { _.isEmpty(clientPhone) &&
                 <Grid item className={ classes.userFieldItem }>
                    <Field
                        component={ PhoneField }
                        onBlur={ handleOnBlur }
                        name={ PHONE }
                        label={ t.phone_number }
                        width='369px'
                        height='64px'
                        isPadding={ true }
                        validate={[
                            required,
                            phone
                        ]}
                    />
                </Grid>
            }
        </Grid>
    );
}

const ItemInput = (props) => {
    return <FormField
        { ...props }
        isPadding={ true }
        widthSize='350px'
        heightSize='24px'
    />
};

export default compose(
    reduxForm({
        form: 'userData'
    })
)(AddUserDataForm);