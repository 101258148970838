import React, { useContext, useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { required } from '../../../../lib/validate';
import FormField from '../FormField/FormField';
import {LangContext} from '../../../../providers/LangProvider';
import device from 'current-device';

const useStyles = makeStyles(() => {
    return {
        contentContainer: {
            fontSize: 14
        },
        rootDialog: {
            height: '440px',
            position: 'absolute',
            bottom: '25%'
        },
        titleBlock: {
            paddingBottom: 12,
            paddingTop: '8px',
            minHeight: '76px'
        },
        passengerNameBlock: {
            minHeight: '76px'
        },
        titleContent: {
            fontStyle: 'oblique'
        },
        submitButton: {
            width: '60px',
            height: '38px',
            fontSize: '14px',

            borderRadius: '4px',
            backgroundColor: '#ff7043',
            fontWeight: 500,
            color: '#ffffff',
            textTransform: 'none',

            '@media (min-width:375px)': {
                width: '90px',
                height: '38px',
                fontSize: '16px'
            },

            '&:hover': {
                backgroundColor: '#f05e30'
            },
            '&:active': {
                backgroundColor: '#c63f17'
            }
        },
        cancelButton: {
            width: '60px',
            height: '38px',
            fontSize: '14px',

            borderRadius: '4px',
            fontWeight: 500,
            textTransform: 'none',

            '@media (min-width:375px)': {
                width: '90px',
                height: '38px',
                fontSize: '16px'
            },
        },
        dialogTitle: {
            cursor: 'move',

            '@media (max-width:375px)': {
                paddingLeft: device.desktop() ? '16px' : '10px'
            },
        },
        dialogContent: {
            '@media (max-width:375px)': {
                paddingLeft: device.desktop() ? '16px' : '10px'
            },
        },
        buttonProgress: {
            color: '#ffffff',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        },
        wrapper: {
            position: 'relative'
        },
        alertMessage: {
            fontSize: '14px',
            color: '#c62828',
            width: '90%',
            position: 'absolute',
            left: device.desktop() ? '16px' : '12px',
            top: '49px',
            lineHeight: 1,

            '@media (min-width:375px)': {
                left: '26px'
            },
        }
    }
});

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const handleOnBlur = (event) => {
    event.preventDefault();
};

function RenderField(props) {
    return(
        <Field
            { ...props }
            component={ FormField }
            onBlur={ handleOnBlur }
            widthSize='97%'
            heightSize='15px'
            isPadding={ true }
            validate={[
                required
            ]}
        />
    );
}

function SearchOrderModal(props) {
    const classes = useStyles();
    const { t } = useContext( LangContext );

    const [loading, setLoading] = useState(false);

    const {
        openModal,
        setOpenModal,
        handleSubmit,
        searchOrder,
        reset,
        doNotFoundOrder,
        clearSearchOrderFailResult,
        widthSize
    } = props;

    useEffect(() => {
        if (doNotFoundOrder) {
            setLoading(false);
        }
    }, [doNotFoundOrder]);

    const handleClose = () => {
        if (!loading) {
            setOpenModal(false);
            reset();
            clearSearchOrderFailResult();
        }
    };

    const findOrder = (data) => {
        clearSearchOrderFailResult();
        setLoading(true);
        searchOrder(data);
    };

    return(
        <Dialog
            open={ openModal }
            onClose={ handleClose }
            PaperComponent={ PaperComponent }
            maxWidth='xs'
            fullWidth={ true }
            className={ classes.rootDialog }
        >
            <Box className={ classes.wrapper }>
                <DialogTitle
                    className={ classes.dialogTitle }
                    id='draggable-dialog-title'
                >
                    { t.search_order }
                </DialogTitle>
                <DialogContent className={ classes.dialogContent }>
                    { doNotFoundOrder &&
                        <Box className={ classes.alertMessage }>
                            { t.do_not_find_order_message }
                        </Box>
                    }
                    <form onSubmit={ handleSubmit((data) => findOrder(data)) }>
                        <Box className={ classes.contentContainer }>
                            <Box className={ classes.titleBlock }>
                                <RenderField
                                    name='bookingNumber'
                                    label={ t.locator }
                                    disabled={ loading }
                                    displaySize={ widthSize }
                                />
                            </Box>
                            <Box className={ classes.passengerNameBlock}>
                                <RenderField
                                    name='passengerName'
                                    label={ t.passenger_name }
                                    disabled={ loading }
                                    displaySize={ widthSize }
                                />
                            </Box>
                        </Box>
                        <DialogActions>
                            <Box className={ classes.wrapper }>
                                <Button
                                    autoFocus
                                    disableRipple
                                    type='submit'
                                    className={ classes.submitButton }
                                    disabled={ loading }
                                >
                                    { t.find }
                                </Button>
                                { loading &&
                                    <CircularProgress size={ 24 } className={ classes.buttonProgress } />
                                }
                            </Box>

                            <Button
                                onClick={ handleClose }
                                disableRipple
                                className={ classes.cancelButton }
                                disabled={ loading }
                            >
                                { t.cancel }
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Box>
        </Dialog>
    );
}

export default compose(
    reduxForm({
        form: 'searchOrder'
    })
)(SearchOrderModal);

SearchOrderModal.defaultProps = {
    openModal: false,
    setOpenModal: () => {}
};