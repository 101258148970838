import {SET_CITIES, SET_PASSENGERS, SET_PASSENGERS_COUNT,
    SET_INF_MAX, SET_PASSENGERS_MAX, SET_ALL_PASSENGERS
} from './constants';

export const setCities = data => dispatch => dispatch({
    type: SET_CITIES,
    payload: data
});

export const setPassengers = data => dispatch => dispatch({
    type: SET_PASSENGERS,
    payload: data
});

export const setAllPassengers = data => dispatch => dispatch({
    type: SET_ALL_PASSENGERS,
    payload: data
});

export const setPassengersCount = data => dispatch => dispatch({
    type: SET_PASSENGERS_COUNT,
    payload: data
});

export const setPassengersMax = data => dispatch => dispatch({
    type: SET_PASSENGERS_MAX,
    payload: data
});

export const setInfMax = data => dispatch => dispatch({
    type: SET_INF_MAX,
    payload: data
});
