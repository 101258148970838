const initialState = {
    citiesItems: [],
    passengers: {
        adt: 1,
        chd: 0,
        ins: 0,
        inf: 0,
    },
    passengersCount: 1,
    isMaximumPassengers: false,
    isMaximumInf: false
};

export default initialState;