import { SET_PARTNER_DATA, SET_PARTNER_FEES } from './constants';
import initialState from './initialState';

export const setPartnerData = (state = initialState, action) => {
    switch (action.type) {
        case SET_PARTNER_FEES:
            return {
                ...state,
                partnerFeesData: action.payload.partnerFees,
                isLoadPartnerFees: action.payload.loadPartnerFeesStatus
            };
        case SET_PARTNER_DATA:
            return {
                ...state,
                isLoadPartnerData: action.payload.loadPartnerDataStatus,
                inn: action.payload.inn,
                kpp: action.payload.kpp,
                partnerName: action.payload.partnerName,
            };
        default:
            return state;
    }
};