import React, { useState, useContext } from 'react';
import { Container, Grid } from '@material-ui/core';
import * as Styled from '../../styles/common/headerMenu/index';
import { useStyles } from '../../containers/common/header/styles';
import SearchOrderModal from '../ui/components/SearchOrderModal/SearchOrderModal';
import { LangContext } from '../../providers/LangProvider';
import MainLogo from './mainLogo';


export default function B2cHeaderMenu(props) {
    const classes = useStyles();
    const { t } = useContext(LangContext);
    const [ openSearchOrderModal, setOpenSearchOrderModal ] = useState(false);

    const {
        headerWithoutImg,
        searchOrder,
        doNotFoundOrder,
        clearSearchOrderFailResult,
        goToAgentsPage,
        agentsUrl
    } = props;


    const agentsBlock = () => (
        <div
            item
            className={ classes.agents }
            onClick={ goToAgentsPage }

        >
            { t.agents }
        </div>
    );

    return (
        <Styled.HeaderContainer headerWithoutImg={ headerWithoutImg }>
            <Container fixed>
                <Grid container justify={ 'space-between' }>
                    <Grid item className={ classes.logoBlock }>
                        <MainLogo { ...props }/>
                    </Grid>
                    <Grid item>
                        <div className={ classes.agentAndSearchButtons }>
                            <div className={ classes.agents }>
                                { !agentsUrl ? null : agentsBlock() }
                            </div>
                            <div
                                className={ classes.b2cHeaderMenuItem }
                                onClick={ () => setOpenSearchOrderModal(true) }
                            >
                                { t.search_order }
                            </div>
                        </div>
                        <div>
                            <SearchOrderModal
                                openModal={ openSearchOrderModal }
                                setOpenModal={ setOpenSearchOrderModal }
                                searchOrder={ searchOrder }
                                doNotFoundOrder={ doNotFoundOrder }
                                clearSearchOrderFailResult={ clearSearchOrderFailResult }
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Styled.HeaderContainer>
    );
}