import { SET_AUTH_ERROR } from './constants';
import initialState from './initialState';

export const setAuthError = (state = initialState, action) => {
  switch (action.type) {
      case SET_AUTH_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};



