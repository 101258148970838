import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AccessRoleContext } from '../providers/AccessRoleProvider';
import _ from 'lodash';
import LoadingCommonContainer from '../containers/loadingForms/mainLoading';

export const AuthRoute = ({ Component, path, exact = false, requiredRoles }) => {
    const { userRole } = useContext(AccessRoleContext);
    const hasAccessRole = _.includes(requiredRoles, userRole);

    if (_.isNull(userRole)) {
        return <LoadingCommonContainer/>;
    }

    return (
        <Route
            exact={ exact }
            path={ path }
            render={ (props) =>
                hasAccessRole ? <Component {...props}/> : <Redirect to='/notFoundPage'/>
            }
        />
    );
};