import device from "current-device";

const desktop = device.desktop();

const getCustomSize = {
    loginInputWidth (displaySize) {
        if (displaySize < 576) {
            return '236px'; // Для мобильной версии
        }
        else {
            return '360px'; // Стандартный размер
        }
    },
    cityInputDropdownWidth (displaySize) {
        if (displaySize < 345) {
            return desktop ? '288px' : '296px';
        }
        else if (displaySize < 375) {
            return desktop ? '305px' : '305px';
        }
        else if (displaySize < 390) {
            return desktop ? '327px' : '335px';
        }
        else if (displaySize < 410) {
            return '343px';
        }
        else if (displaySize < 414) {
            return '358px';
        }
        else if (displaySize === 414) {
            return desktop ? '358px' : '366px';
        }
        else if (displaySize < 576) {
            return desktop ? '359px' : '366px';
        }
        else if (displaySize < 768) {
            return desktop ? '531px' : '536px';
        }
        else if (displaySize < 1275) {
            return desktop ? '343px' : '344px';
        }
        else {
            return '298px'; // Стандартный размер
        }
    },
    isMobile (displaySize) {
        return displaySize < 768;
    },
    withWeekDayForMobile (displaySize) {
        return displaySize > 345;
    }
};

export { getCustomSize }