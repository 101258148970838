import React from 'react';
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider  as MaterialThemeProvider} from "@material-ui/styles";
import browser from 'browser-detect';

const device = require("current-device").default;

export const CustomThemeProvider = (props) => {
    const theme = {
        mobile: device.mobile(),
        tablet: device.tablet(),
        desktop: device.desktop(),
        browser: browser()
    };

    const themeMaterial = createMuiTheme({
        theme
    });

    return (
        <StyledThemeProvider theme={ theme } >
            <MaterialThemeProvider theme={ themeMaterial } >
                { props.children }
            </MaterialThemeProvider>
        </StyledThemeProvider>
    )
};