import { SET_ORDER_DATA, SET_PAYMENT_METHOD, SET_AVAILABLE_PAYMENT_METHODS } from './constants';
import initialState from './initialState';

export const setOrderData = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_DATA:
        return {
            ...state,
            orderData: action.payload.orderData,
            isLoad:  action.payload.loadStatus
        };
      case SET_AVAILABLE_PAYMENT_METHODS:
          return {
              ...state,
              isLoadPaymentMethod: action.payload.loadStatus,
              availablePaymentMethods: action.payload.methods
          };
      case SET_PAYMENT_METHOD:
          return {
              ...state,
              paymentMethod: action.payload.method
          };
    default:
        return state;
  }
};