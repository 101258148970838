import React, { useState, useContext, useEffect } from 'react';
import { bindActionCreators, compose } from 'redux'
import { setCities, setInfMax, setPassengers, setPassengersCount,
    setPassengersMax, setAllPassengers } from './actions';
import { setSearchParams } from '../result/actions';
import SearchForm from '../../components/search/searchForm';
import { API } from '../../lib/api';
import { connect } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import { OW, RT, CF } from './constants';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { setAppFrameData } from '../app-frame/actions';
import { LangContext } from '../../providers/LangProvider';
import _ from 'lodash';
import { parserLangCode } from '../../components/result/common';
import { encodeSearchId } from '../../lib/searchId';
import { appendToLocalStorage } from '../../lib/searchHistory';

function Search (props) {
    const { setAppFrameData, setCities, formValues, history, location, setPassengers, 
        setAllPassengers, setPassengersCount, citiesItems, passengers, passengersCount, 
        setInfMax, setPassengersMax, isMaximumPassengers, isMaximumInf, isResultPage, 
        formFieldActual, passengersData, hideHeader, setSearchParams } = props;

    const { t, language } = useContext( LangContext );
    const [minimizeSearch, setMinimizeSearch] = useState(false);

    useEffect(() => {
        setAppFrameData({
            withoutFooter: !_.isUndefined(isResultPage),
            headerWithoutImg: !_.isUndefined(isResultPage),
            withLogo: true,
            isResultPage: !_.isUndefined(isResultPage),
            hideHeader: false
        });
        if(!_.isUndefined(isResultPage)){
            setMinimizeSearch(true);
        }
    
        let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

        function handleScroll(e) {
            if(isResultPage){
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                if(scrollTop > lastScrollTop) {
                    setAppFrameData({ hideHeader: true });
                } else {
                    setAppFrameData({ hideHeader: false });
                }
                lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
            }
        }
        window.addEventListener('scroll', handleScroll);

        return () => {
            setAppFrameData({
                withoutFooter: true,
                headerWithoutImg: true,
                withLogo: false,
                isResultPage: false,
                hideHeader: false
            });
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isResultPage]);

    const getCities = (data) => {
        API.getCities(data)
            .then(response => {
                setCities(response)
            });
    };

    const onSend = data => {
        let flightType = OW;
        if(data.flightType === RT) {
            const route = _.first(data.routes);
            if(route.date_to) {
                flightType = RT;
            }
        } else if(data.flightType === CF) {
            if(data.routes.length > 2) {
                flightType = CF;
            } else if(data.routes.length === 2) {
                if(data.routes[0].from.code === data.routes[1].to.code && data.routes[0].to.code === data.routes[1].from.code) {
                    flightType = RT;
                    data.routes = [{
                        date_from: data.routes[0].date_from,
                        date_to: data.routes[1].date_from,
                        from: data.routes[0].from,
                        to: data.routes[0].to
                    }];
                } else {
                    flightType = CF;
                }
            }
        }
        data.flightType = flightType;

        let params = {
            'cabin': data.cabin,
            'flightType': flightType,
            'passengers': passengers
        };

        let dates = [];
        let loadingData = [];

        let key = 0;

        data.routes.map((route)=> {
            const {from, to, date_from, date_to} = route;

            if(params['flightType'] === RT) {
                dates[key] = getRoute(from, to, moment.unix(date_from));
                loadingData[key] = getLoadingRoute(from, to, moment.unix(date_from));

                key++;

                dates[key] = getRoute(to, from, moment.unix(date_to));
                loadingData[key] = getLoadingRoute(to, from, moment.unix(date_to));
            } else {
                dates[key] = getRoute(from, to, moment.unix(date_from));
                loadingData[key] = getLoadingRoute(from, to, moment.unix(date_from));

                key++;
            }

            params['routes'] = dates;
            params['loadingRoutes'] = loadingData;
        });

        const passengersData = {
            passengers,
            isMaximumPassengers,
            isMaximumInf,
            passengersCount
        };

        setSearchParams({
            formField: formValues,
            searchParams: params,
            passengersData: passengersData
        });

        const searchId = encodeSearchId(flightType, formValues, passengersData);
        appendToLocalStorage(searchId, {
            formField: formValues,
            passengersData: passengersData
        });

        history.push(`/result/${searchId}`);
        
        if(location.pathname !== `/result/${searchId}` && isResultPage) {
            window.location.reload();
        }
    };

    const getRoute = (from, to, date) => {
        return {
            'from': from['code'],
            'to': to['code'],
            'date': date
                .locale(parserLangCode(language))
                .format('YYYY-MM-DD')
        }
    };

    const getLoadingRoute = (from, to, date) => {
        return {
            'from': from['value'],
            'to': to['value'],
            'date': date
                .locale(parserLangCode(language))
                .format('DD MMM YYYY')
                .replace('.', '')
        }
    };

    const resetCitiesData = () => {
        setCities([]);
    };

    return (
        <SearchForm
            setPassengers={ setPassengers }
            setAllPassengers={ setAllPassengers }
            setPassengersCount={ setPassengersCount }
            onSend={ onSend }
            getCities={ getCities }
            cities={ citiesItems }
            passengers={ passengers }
            passengersCount={ passengersCount }
            setPassengersMax={ setPassengersMax }
            setInfMax={ setInfMax }
            isMaximumPassengers={ isMaximumPassengers }
            isMaximumInf={ isMaximumInf }
            resetCities={ resetCitiesData }
            formValues={ formValues }
            isResultPage={ isResultPage }
            formFieldActual={ formFieldActual }
            passengersData={ passengersData }
            t={ t }
            minimizeSearch={ minimizeSearch }
            setMinimizeSearch={ setMinimizeSearch }
            hideHeader={ hideHeader }
        />
    );
}

const mapStateToProps = state => ({
    citiesItems: state.setSearchData.citiesItems,
    passengers: state.setSearchData.passengers,
    passengersCount: state.setSearchData.passengersCount,
    isMaximumPassengers: state.setSearchData.isMaximumPassengers,
    isMaximumInf: state.setSearchData.isMaximumInf,
    formValues: getFormValues('search')(state),
    hideHeader: state.setAppFrameData.hideHeader
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setCities,
    setPassengers,
    setAllPassengers,
    setPassengersCount,
    setPassengersMax,
    setInfMax,
    setAppFrameData,
    setSearchParams
}, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(Search);
