import React, { Component } from 'react';
import { reduxForm, arrayPush, arrayRemove, change } from 'redux-form';
import _ from 'lodash';
import { compose } from 'redux';
import RouteFieldsForm from './routeFieldsForm';
import SearchOptionsForm from './searchOptionsForm';
import * as config from '../../config/config';
import Grid from '@material-ui/core/Grid';
import { SearchBackground, SearchResultBackground } from '../../styles/common/index';
import { RouteBlockStyled } from '../../styles/search/index';
import { RT, CF } from '../../containers/search/constants';
import BannerBlock from './bannerBlock';
import CarouselBlock from './carouselBlock';
import SubscriptionBlock from './subscriptionBlock';

const createRoute = ()=> ({
    from: null,
    to: null,
    date_from: null,
    date_to: null
});

const createFocuses = () => ({
    date_from: false,
    date_to: false
});

const { defaultCabin, defaultFlightType } = config;

class SearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: defaultFlightType,
            cabin: defaultCabin,
            routesCount: 1,
            focusCalendar: createFocuses()
        };
    }

    componentDidMount () {
        const { formFieldActual } = this.props;

        if( formFieldActual) {
            this.setResultSearchForm();
        } else {
            this.setMainSearchForm();
        }
    }

    setResultSearchForm() {
        const { formFieldActual } = this.props;

        this.props.dispatch(change('search', 'routes', formFieldActual.routes));
        this.props.dispatch(change('search', 'cabin', formFieldActual.cabin));
        this.props.dispatch(change('search', 'flightType', formFieldActual.flightType));

        this.setState({ cabin: formFieldActual.cabin });

        this.loadDirection();
        this.loadPassengersData();
    }

    setMainSearchForm() {
        this.props.dispatch(change('search', 'routes[0]', createRoute()));
        this.props.dispatch(change('search', 'cabin', defaultCabin));
        this.props.dispatch(change('search', 'flightType', defaultFlightType));

        this.loadDefaultPassengersData();
    }

    loadPassengersData () {
        const { passengers, passengersCount, isMaximumPassengers, isMaximumInf } = this.props.passengersData;

        this.props.setAllPassengers(passengers);
        this.props.setPassengersCount(passengersCount);
        this.props.setPassengersMax(isMaximumPassengers);
        this.props.setInfMax(isMaximumInf);
    }

    loadDefaultPassengersData () {
        this.props.setAllPassengers({
            adt: 1,
            chd: 0,
            ins: 0,
            inf: 0
        });
        this.props.setPassengersCount(1);
        this.props.setPassengersMax(false);
        this.props.setInfMax(false);
    }

    loadDirection () {
        const { formFieldActual } = this.props;
        const flightTypeValue = formFieldActual.flightType;

        if(flightTypeValue !== this.state.direction) {
            this.setState({
                direction: flightTypeValue,
                routesCount: formFieldActual.routes.length
            });
        }
    }

    getCalendarSelectedDates () {
        const { direction } = this.state;
        const { formValues } = this.props;

        let dates = [];

        if(formValues) {
            const routes = formValues.routes;

            if (direction === CF) {
                dates = routes.map((route)=> route.date_from);
            } else if (direction === RT) {
                let route = routes[0];
                dates = [route.date_from, route.date_to];
            } else {
                let route = routes[0];
                dates = [route.date_from];
            }
        }

        return dates;
    }

    handleAddRouteClick = () => {
        this.props.dispatch(arrayPush('search', 'routes', createRoute()));

        this.setState({
            routesCount: this.state.routesCount + 1
        })
    };

    handleSwitchRoutes = (route, index) => {
        const { formValues } = this.props;
        const preFromRoute = formValues.routes[index].from;
        const preTomRoute = formValues.routes[index].to;

        this.props.dispatch(change('search', route + 'from', preTomRoute));
        this.props.dispatch(change('search', route + 'to', preFromRoute));
    };

    handleChangeDirection = e => {
        this.props.dispatch(change('search', 'flightType', e));
        this.changeDirection(e);
    };

    changeDirection = (value, calendarChanger = false) => {
        if(calendarChanger) {
            this.props.dispatch(change('search', 'flightType', value));
        }

        if(value !== this.state.direction) {
            this.setState({
                direction: value,
                focusCalendar: createFocuses()
            });

            this.props.dispatch(change('search', 'routes[0].date_to', null));

            if(value === CF) {
                this.setState({ routesCount: 2 });

                this.props.dispatch(arrayPush('search', 'routes', createRoute()));
            } else {
                for(let i = this.state.routesCount; i >= 1; i--) {
                    this.props.dispatch(arrayRemove('search', 'routes', i));
                }

                this.setState({ routesCount: 1 });;
            }
        }
    };

    removeRoutesCount = () => {
        this.setState({ routesCount: this.state.routesCount - 1 });
    };

    checkIsFocus = routeType => {
        const { direction } = this.state;

        if(direction === RT) {
            const route = _.first(this.props.formValues.routes);

            if(_.isNull(route.date_from) && _.isNull(route.date_to)) {
                this.setState({
                    focusCalendar: {
                        date_from: 'date_from' !== routeType,
                        date_to: 'date_to' !== routeType,
                    }
                });
            } else {
                this.setState({
                    focusCalendar: createFocuses()
                });
            }
        }
    };

    handleUpdateDate = (route, routeType, day) => {
        this.checkIsFocus(routeType);

        let newValue = day.isValid() ? day : '';

        this.props.dispatch(change('search', route + routeType, newValue.unix()));
    };

    handleUpdateAirport = (route, routeType, value) => {
        if(value) {
            this.props.dispatch(change('search', route + routeType, value));
        }
    };

    handelOnChangeFlightClass = (value) => {
        this.props.dispatch(change('search', 'cabin', value));

        this.setState({ cabin: value });
    };

    removeCalendarValue = (route, fieldType) => {
        this.props.dispatch(change('search', route + fieldType, null));
    };

    renderRoutesFields = ({ fields }) => {
        const { focusCalendar, direction } = this.state;
        const { getCities, cities, resetCities } = this.props;
        const selectedDates = this.getCalendarSelectedDates();
        
        return (
            <Grid container>
                { fields.map((route, index) => (
                    <RouteBlockStyled
                        key={ index }
                        withPaddingTop={ index > 0 }
                    >
                        <Grid item>
                            <RouteFieldsForm
                                removeRoutesCount={ this.removeRoutesCount }
                                switchRoutes={ this.handleSwitchRoutes }
                                updateAirport={ this.handleUpdateAirport }
                                changeDirection={ this.changeDirection }
                                removeCalendarValue={ this.removeCalendarValue }
                                updateDate={ this.handleUpdateDate }
                                addRoute={ this.handleAddRouteClick }
                                index={ index }
                                fields={ fields }
                                route={ route }
                                focusCalendar={ focusCalendar }
                                direction={ direction }
                                getCities={ getCities }
                                cities={ cities }
                                resetCities={ resetCities }
                                selectedDates={ selectedDates }
                            />
                        </Grid>
                    </RouteBlockStyled>
                    ))
                }
            </Grid>
        );
    };

    renderSearchContainer() {
        const { direction, routesCount, cabin } = this.state;
        const { handleSubmit, isResultPage, onSend, passengers, setPassengers, setPassengersCount,
            passengersCount, setPassengersMax, setInfMax, isMaximumPassengers, isMaximumInf, minimizeSearch,
            setMinimizeSearch, formValues } = this.props;

        const passengersData = {
            passengers,
            setPassengers,
            setPassengersCount,
            passengersCount,
            setPassengersMax,
            setInfMax,
            isMaximumPassengers,
            isMaximumInf
        };

        return (
            <SearchOptionsForm
                changeDirection={ this.handleChangeDirection }
                addRoute={ this.handleAddRouteClick }
                routesFields={ this.renderRoutesFields }
                onChangeFlightClass={ this.handelOnChangeFlightClass }
                isResultPage={ isResultPage }
                handleSubmit={ handleSubmit }
                onSend={ onSend }
                routesCount={ routesCount }
                direction={ direction }
                cabin={ cabin }
                passengersData={ passengersData }
                minimizeSearch={ minimizeSearch }
                setMinimizeSearch={ setMinimizeSearch }
                formValues={ formValues }
            />
        )
    }

    render() {
        const { isResultPage, hideHeader, minimizeSearch } = this.props;
        const { routesCount } = this.state;

        if(isResultPage) {
            return (
                <SearchResultBackground 
                    blockCount={ routesCount } 
                    className={ hideHeader && 'hideHeader' }
                    minimizeSearch={ minimizeSearch }
                >
                    { this.renderSearchContainer() }
                </SearchResultBackground>
            );
        }

        return (
            <>
                <SearchBackground blockCount={ routesCount }>
                    { this.renderSearchContainer() }
                </SearchBackground>
                <BannerBlock />
                <SubscriptionBlock />
                <CarouselBlock />
            </>
        );
    }
}

export default compose(
    reduxForm({
        form: 'search'
    })
)(SearchForm);
