import React, { Component } from 'react';

export const windowSize = (ComposedComponent) => {
    class RedirectComponent extends Component {
        constructor(props) {
            super(props);

            this.state = {
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight
            };
        }

        componentDidMount() {
            window.addEventListener("resize", this.updateWidthAndHeight);
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.updateWidthAndHeight);
        }

        updateWidthAndHeight = () => {
            this.setState({
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight
            });
        };

        render() {
            const { windowWidth, windowHeight } = this.state;

            return < ComposedComponent
                { ...this.props }
                windowWidth={ windowWidth }
                windowHeight={ windowHeight }
            />
        }
    }

    return RedirectComponent;
};

export default windowSize;