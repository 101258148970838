import * as axios from 'axios'
import { formatResult } from './resultFormatter';
import Config from '../config/config';
import qs from 'qs';
import _ from 'lodash';

const baseURL = Config.apiHost;
const billingURL = Config.billingHost;
const countSearchCity = Config.countSearchCity;
const isB2c = Config.isB2c;
const b2cData = Config.b2cData();
const countOrdersLimit = Config.countOrdersLimit;
let https = require('https');

const accessToken = () => {
    if(isB2c) {
        return b2cData.access_token;
    }

    return localStorage.getItem('access_token');
};

const tokenType = () => {
    if(isB2c) {
        return b2cData.token_type;
    }

    return localStorage.getItem('token_type');
};

const baseParams = () => {
    const clientCode = isB2c ? b2cData.client_code : localStorage.getItem('client_code');
    const partnerCode = isB2c ? b2cData.partner_code : localStorage.getItem('partner_code');

    return {
        client_code: clientCode,
        partner_code: partnerCode,
        language: Config.language()
    };
};

const baseParamsWithoutClientCode = () => {
    return {
        partner_code: localStorage.getItem('partner_code'),
        language: Config.language()
    };
};

const loginInstance = () => axios.create({
    baseURL: baseURL,
    timeout: 500000,
    withCredentials: false,
    httpsAgent: new https.Agent({ keepAlive: true })
});

const billingInstance = () => axios.create({
    baseURL: billingURL,
    timeout: 100000,
    withCredentials: false,
    headers: {
        'Authorization': tokenType() +' '+ accessToken()
    },
    paramsSerializer: function(params) {
        return qs.stringify(params, { indices: true })
    },
    httpsAgent: new https.Agent({ keepAlive: true })
});

const apiInstance = () =>  axios.create({
    baseURL: baseURL,
    timeout: 100000,
    withCredentials: false,
    headers: {
        'Authorization': tokenType() +' '+ accessToken()
    },
    paramsSerializer: function(params) {
        return qs.stringify(params, { indices: true })
    },
    httpsAgent: new https.Agent({ keepAlive: true })
});

const apiInstanceForUploadFile = () =>  axios.create({
    baseURL: baseURL,
    timeout: 100000,
    withCredentials: false,
    headers: {
        'Authorization': tokenType() +' '+ accessToken(),
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    httpsAgent: new https.Agent({ keepAlive: true })
});

const API = {
    login(data) {
        let params = {
            ...data,
            language: Config.language()
        };

        return loginInstance().post('login', params).then(response => {
            return response
        })
    },

    activationUser(data) {
        let params = {
            ...data,
            language: Config.language()
        };

        return loginInstance().post('activate-client', params).then(response => {
            return response
        })
    },

    logout() {
        return apiInstance().post('logout', baseParams()).then(response => {
            return response
        })
    },

    getUserData() {
        return apiInstance().post('get-client', baseParams()).then(response => {
            return formatResult.userData(response.data)
        })
    },
    getCities(data, options = {}) {
        let params = {
            ...baseParams(),
            value: data,
            limit: countSearchCity,
            ...options
        };

        return apiInstance().post('cities', params)
            .then(response => {
                return formatResult.cities(response.data.data)
            })
            .catch(() => {
                return [];
            })
    },

    getLanguages() {
        let params = {
            ...baseParams()
        };

        return apiInstance().post('languages', params)
            .then(response => {
                return response.data;
            })
            .catch(() => {
                return [];
            })
    },

    getCountries(data) {
        let params = {
            ...baseParams(),
            value: data,
            limit: countSearchCity
        };

        return apiInstance().post('countries', params)
            .then(response => {
                return response.data.data
            })
            .catch(() => {
                return [];
            })
    },

    search(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().get('search', { params })
            .then(response => {
                return formatResult.searchResult(response.data);
            })
            .catch((error) => {
                return error.response.data;
            })
    },

    brandFares(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('brand-fares', params)
            .then(response => {
                return formatResult.searchBrandFares(response.data);
            })
            .catch((error) => {
                return error.response.data;
            })
    },

    apiPrebook(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('prebook', params)
            .then(response => {
                return response;
            })
    },

    apiBook(data) {
        const clientCode = isB2c ? b2cData.client_code : localStorage.getItem('client_code');
        const partnerCode = isB2c ? b2cData.partner_code : localStorage.getItem('partner_code');

        let params = {
            ...data,
            client_code: clientCode,
            partner_code: partnerCode,
        };

        return apiInstance().post('book', params)
            .then(response => {
                return response;
            })
    },

    apiGetOrder(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('order', params)
            .then(response => {
                return formatResult.orderData(response.data);
            })
    },

    apiGetOrders(data) {
        let params = {
            ...baseParams(),
            ...data,
            limit: countOrdersLimit
        };

        return apiInstance().post('order-list', params)
            .then(response => {
                return formatResult.orders(response.data);
            })
    },

    apiGetOrderId(data) {
        let params = {
            ...baseParams(),
            filters: {
                ...data
            },
            page: 1,
            limit: countOrdersLimit
        };

        return apiInstance().post('order-list', params)
            .then(response => {
                return _.first(response.data.data);
            })
    },

    billingPayment(data) {
        const clientCode = isB2c ? b2cData.client_code : localStorage.getItem('client_code');
        const partnerCode = isB2c ? b2cData.partner_code : localStorage.getItem('partner_code');

        let params = {
            ...data,
            client_code: clientCode,
            partner_code: partnerCode,
        };

        return billingInstance().post('payments', params)
            .then(response => {
                return response;
            })
    },

    billingGetPaymentSystems(data) {
        const partnerCode = isB2c ? b2cData.partner_code : localStorage.getItem('partner_code');
        const clientCode = isB2c ? b2cData.client_code : localStorage.getItem('client_code');

        const params = {
            ...data,
            partner_code: partnerCode,
            client_code: clientCode
        };

        return billingInstance().get('paymentSystem/getAllActive', { params })
            .then(response => {
                return formatResult.paymentSystems(response.data);
            })
    },

    apiGetDocument(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('document', params)
            .then(response => {
                return response.data;
            })
    },

    apiOrderCancel(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().delete('cancel', { data: { ...params } })
            .then(response => {
                return response.data;
            })
    },

    apiBalancePaymentApproved(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('order-approve-balance-payment', params)
            .then(response => {
                return response.data;
            })
    },

    apiBalancePaymentRequest(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('order-request-balance-payment', params)
            .then(response => {
                return response.data;
            })
    },

    apiChangeClientPassword(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('client-password', params)
            .then(response => {
                return response.data;
            })
            .catch((error) => {
                return error.response.data;
            })
    },

    apiCreatePassenger(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('create-passenger', params)
            .then(response => {
                return response;
            })
    },

    apiUpdatePassenger(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('update-passenger', params)
            .then(response => {
                return response;
            })
    },

    apiGetPassengersList() {
        let params = {
            ...baseParams(),
            limit: 10000,
            page: 1
        };

        return apiInstance().post('index-passengers', params)
            .then(response => {
                return formatResult.passengersList(response.data.data);
            })
    },

    apiRemovePassengerParams(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('delete-passenger-params', params)
            .then(response => {
                return response;
            })
    },

    apiRemovePassenger(data) {
        let params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('delete-passenger', params)
            .then(response => {
                return response;
            })
    },

    apiGetPartnerFees() {
        const params = {
            ...baseParamsWithoutClientCode()
        };

        return apiInstance().get('get-partner-fees', { params })
            .then(response => {
                return response.data;
            })
    },

    apiSetPartnerFees(data) {
        const params = {
            ...baseParamsWithoutClientCode(),
            ...data
        };

        return apiInstance().post('set-partner-fees', params)
            .then(response => {
                return response;
            })
    },

    apiSetPartnerData(data) {
        const params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('set-partner-data', params)
            .then(response => {
                return formatResult.partnerData(response.data.data);
            })
    },

    apiUploadImg(file) {
        let data = new FormData();

        data.append('image', file);

        return apiInstanceForUploadFile().post('upload-image', data)
            .then(response => {
                return response;
            })
    },

    apiGetUsers() {
        return apiInstance().post('list-clients', {
            ...baseParams()
        })
            .then(response => {
                return formatResult.usersList(response.data.data);
            })
    },

    apiRemoveUsers(data) {
        const params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('delete-clients', params)
            .then(response => {
                return response.data;
            })
    },

    apiUpdateUser(data) {
        const params = {
            language: Config.language(),
            data
        };

        return apiInstance().post('update-client', params)
            .then(response => {
                return response.data;
            })
    },

    apiCreateUser(data) {
        const params = {
            language: Config.language(),
            data
        };

        return apiInstance().post('create-client', params)
            .then(response => {
                return response.data;
            })
    },

    apiPartnersList() {
        return apiInstance().post('list-child-partners', baseParams())
            .then(response => {
                return formatResult.childPartnerList(response.data.data);
            })
    },

    apiCompaniesList(filters) {
        const params = {
            filters,
            ...baseParams()
        }

        return apiInstance().post('list-child-partners', params)
            .then(response => {
                return formatResult.companiesList(response.data.data);
            })
    },

    apiRefund(data) {
        const params = {
            ...baseParams(),
            ...data
        };

        return apiInstance().post('refund', params)
            .then(response => {
                return response.data;
            })
    },

    apiCreatePartner(data) {
        const params = {
            ...baseParams(),
            data
        };

        return apiInstance().post('create-child-partner', params)
            .then(response => {
                return response.data;
            })
    },

    apiDeactivatePartner(data) {
        const params = {
            ...baseParams(),
            data
        };

        return apiInstance().post('deactivate-partner', params)
            .then(response => {
                return response.data;
            })
    },

     getPartnerData(data) {
         const params = {
             ...baseParams(),
             data
         };

         return apiInstance().post('get-partner', params)
             .then(response => {
                 return formatResult.partnerData(response.data.data);
             })
     },

    getStaticPagesList(data) {
        let params = {
            ...data,
            language: Config.language()
        };

        return apiInstance().get('index-cms-pages', { params })
            .then(response => {
                return response.data;
            })
    },

    getStaticPageInfo(data) {
        let params = {
            language: Config.language(),
            ...data
        };

        return apiInstance().get('get-cms-page', { params })
            .then(response => {
                return response.data;
            })
    },

    getListPrebookOptions(data) {
        let params = {
            language: Config.language(),
            ...data
        };

        return apiInstance().post('list-prebook-options', params)
            .then(response => {
                return response.data;
            });
    },

    getTransactionsList(data) {
        let params = {
            language: Config.language(),
            ...data
        };

        return apiInstance().post('transaction-list', params)
            .then(response => {
                return response.data;
            });
    },

    applyPrebookOptions(data) {
        let params = {
            ...data
        };

        return apiInstance().post('apply-prebook-options', params)
            .then(response => {
                return response.data;
            });
    },

    getMyIp() {
        return axios.get(Config.getMyIpService)
                .then(response => {
                    return response.data;
                });
    },

    getMyIpInfo(ip) {
        return axios.get(`${Config.getIpLocationService}${ip}?key=${Config.getIpLocationToken}&objects=city`)
                .then(response => {
                    return response.data;
                });
    },
};

export { API }
