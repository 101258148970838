import React, { useContext } from 'react';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorsFieldBlock } from '../../../../styles/common/index';
import InputMessage from '../InputMessage/InputMessage';
import { LangContext } from '../../../../providers/LangProvider';

function TextMaskCustom (props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            { ...other }
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['+', /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

export default function PhoneField(props) {
    const {
        label,
        fieldError,
        width,
        height,
        meta: { touched, error }
    } = props;

    const useStyles = makeStyles({
        container: {
            flexWrap: 'wrap',
        },
        formControl: {
            position: 'relative',
            display: 'flex'
        },
        input: {
            width: width ? width : '256px',
            height: height ? height : '55px',
            borderRadius: '4px',
            border: 'solid 1px #cfd8dc',
            paddingLeft: '16px',
            paddingTop: '26px',
            paddingBottom: '1px'
        },
        inputFocused: {
            outline: 'none',
            border: 'solid 1px #ff7043'
        },
        label: {
            fontSize: '12px',
            color: '#9da8ae',
            position: 'absolute',
            top: '8px',
            left: '16px'
        }
    });

    const classes = useStyles();
    const { t } = useContext( LangContext );

    let errorStyle = {};

    if(fieldError || (touched && error)) {
        errorStyle = {
            border: "solid 2px #c62828"
        };
    }

    return(
        <FormControl className={ classes.formControl }>
            <Box className={ classes.label }>
                <span>{ label }</span>
            </Box>

            <Input
                { ...props.input }
                className={ classes.input }
                disableUnderline={ true }
                inputComponent={ TextMaskCustom }
                autoComplete='off'
                classes={{ focused: classes.inputFocused }}
                style={
                    errorStyle
                }
            />

            <ErrorsFieldBlock>
                { touched && ((error &&
                    <InputMessage text={ t[error] } />
                ))}
                { fieldError &&
                    <InputMessage text={ fieldError } />
                }
            </ErrorsFieldBlock>
        </FormControl>
    );
}

PhoneField.defaultProps = {
    label: null
};