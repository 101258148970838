import React, { useContext, Fragment } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { setAppFrameData } from '../app-frame/actions';
import * as Styled from '../../styles/loadingResult/index';
import { CF, RT, OW } from '../search/constants';
import _ from 'lodash';
import { getPassTypeCountString } from '../../components/result/common';
import { ADT, CHD, INS, INF } from '../search/constants';
import { LangContext } from '../../providers/LangProvider';

function LoadingResultContainer (props) {
    const { t } = useContext( LangContext );
    const { flightType, loadingRoutes, cabin, passengers } = props;

    const directionItems = [
        { value: OW, label: t.one_way },
        { value: RT, label: t.two_way },
        { value: CF, label: t.multi_way }
    ];

    const flightClassItems = [
        { value: 'economy', label: t.economy },
        { value: 'business', label: t.business },
        { value: 'first', label: t.first },
        { value: 'all', label: t.all_class }
    ];

    const cabinTypeData = _.find(flightClassItems, { 'value': cabin });
    const flightTypeData = _.find(directionItems, { 'value': flightType });
    const firstRoute = _.first(loadingRoutes);

    return (
        <div style={{ textAlign: 'center' }}>
            <MainContainer
                passengers={ passengers }
                loadingRoutes={ loadingRoutes }
                routeLabel={ flightTypeData.label }
                cabinLabel={ cabinTypeData.label }
                firstRoute={ firstRoute }
                flightType={ flightType }
            />
        </div>
    )
}

const MainContainer = (props) => {
    const { loadingRoutes, passengers, routeLabel, cabinLabel, firstRoute, flightType } = props;

    if (flightType === CF) {
        return <CfInfo
            loadingRoutes={ loadingRoutes }
            passengers={ passengers }
            routeLabel={ routeLabel }
            cabinLabel={ cabinLabel }
        />
    }
    else if (flightType === RT) {
        return <RtInfo
            loadingRoutes={ loadingRoutes }
            passengers={ passengers }
            routeLabel={ routeLabel }
            cabinLabel={ cabinLabel }
        />
    }
    else {
        return <OwInfo
            passengers={ passengers }
            firstRoute={ firstRoute }
            routeLabel={ routeLabel }
            cabinLabel={ cabinLabel }
        />
    }
}

const OwInfo = ({ routeLabel, firstRoute, cabinLabel, passengers }) => {
    const { language } = useContext( LangContext );

    return(
        <Fragment>
            <Styled.TitleBlock>
                <Styled.OwTitle>
                    <Styled.TitleItem>{ firstRoute.from[language] }</Styled.TitleItem>
                    <Styled.ArrowRight/>
                    <Styled.TitleItem>{ firstRoute.to[language] }</Styled.TitleItem>
                </Styled.OwTitle>
            </Styled.TitleBlock>
            <Styled.InfoBlock>
                <span>{ routeLabel }, </span>
                <OwDates firstRoute={ firstRoute }/>
                <PassengerAndCabinInfo
                    cabinLabel={ cabinLabel }
                    passengers={ passengers }
                />
            </Styled.InfoBlock>
            <LoadingSniper/>
        </Fragment>
    );
}

const CfInfo = ({ loadingRoutes, routeLabel, cabinLabel, passengers }) => {
    const { t } = useContext( LangContext );

    return(
        <Fragment>
            <Styled.TitleBlock>
                <Styled.CfTitle>
                    { t.looking_tickets }
                </Styled.CfTitle>
            </Styled.TitleBlock>
            <Styled.InfoBlock>
                <span>{ routeLabel }, </span>
                { _.map(loadingRoutes, (route, index) => {
                    return <CfDates
                        key={ `route-date-${index}` }
                        route={ route }
                        index={ index }
                    />
                })}
                <PassengerAndCabinInfo
                    cabinLabel={ cabinLabel }
                    passengers={ passengers }
                />
            </Styled.InfoBlock>
            <LoadingSniper/>
        </Fragment>
    );
}

const RtInfo = ({ loadingRoutes, routeLabel, cabinLabel, passengers }) =>  {
    const { language } = useContext( LangContext );

    return(
        <Fragment>
            <Styled.TitleBlock>
                <Styled.RtTitle>
                    <Styled.TitleItem>{ loadingRoutes[0].from[language] }</Styled.TitleItem>
                    <Styled.ArrowCompare/>
                    <Styled.TitleItem>{ loadingRoutes[0].to[language] }</Styled.TitleItem>
                </Styled.RtTitle>
            </Styled.TitleBlock>
            <Styled.InfoBlock>
                <span>{ routeLabel }, </span>
                <RtDates loadingRoutes={ loadingRoutes }/>
                <PassengerAndCabinInfo
                    cabinLabel={ cabinLabel }
                    passengers={ passengers }
                />
            </Styled.InfoBlock>
            <LoadingSniper/>
        </Fragment>
    );
}

const OwDates = ({ firstRoute }) => {
    return(
        <span>
            { firstRoute.date },
        </span>
    );
}

const RtDates = ({ loadingRoutes }) => {
    return(
        <span>
            { loadingRoutes[0].date } — { loadingRoutes[1].date },
        </span>
    );
}

const CfDates = ({ route, index }) => {
    return <span key={ index }>{ route.date }, </span>
}

const LoadingSniper = () => {
    return(
        <Styled.LoadingSniperBlock>
            <Styled.LoadingSniper/>
        </Styled.LoadingSniperBlock>
    );
}

const PassengerAndCabinInfo = ({ cabinLabel, passengers }) => {
    return(
        <Styled.PassData>
            <PassengerInfo passengers={ passengers }/>
            <span>
                { cabinLabel.toLowerCase() }
            </span>
        </Styled.PassData>
    );
}

const PassengerInfo = ({ passengers }) => {
    const { t } = useContext( LangContext );
    const passengerInfo = getPassengerInfo(passengers, t);

    return (
        <span>{ passengerInfo }</span>
    );
}

const getPassengerInfo = (passengers, t) => {
    let passengersString = passengers.adt + ' ' + getPassTypeCountString(passengers.adt, ADT, t).toLowerCase() + ', ';

    if(passengers.chd > 0) {
        passengersString += passengers.chd + ' ' + getPassTypeCountString(passengers.chd, CHD, t).toLowerCase() + ', ';
    }

    if(passengers.ins > 0) {
        passengersString += passengers.ins + ' ' + getPassTypeCountString(passengers.ins, INS, t).toLowerCase() + ', ';
    }

    if(passengers.inf > 0) {
        passengersString += passengers.inf + ' ' + getPassTypeCountString(passengers.inf, INF, t).toLowerCase() + ', ';
    }

    return passengersString;
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setAppFrameData
}, dispatch);

export default compose(
    connect(null, mapDispatchToProps)
)(LoadingResultContainer);
