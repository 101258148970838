import React, { useContext } from 'react';
import { useStyles } from '../../containers/auth/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { LangContext } from '../../providers/LangProvider';
import { StyledErrorActivationButton } from '../../styles/auth';

export default function ActivationErrorForm (props) {
    const classes = useStyles();
    const { t } = useContext( LangContext );
    const { setIsAuthRedirect } = props;

    return (
        <Grid container className={ classes.rootContainer }>
            <Box className={ classes.activationErrorBlock }>
                <Grid item className={ classes.loginTitleBlock }>
                    <Box className={ classes.loginTitle }>
                        { t.error }
                    </Box>
                </Grid>
                <Grid item className={ classes.activationErrorMess }>
                    { t.error_activating_user }
                </Grid>
                <Grid item className={ classes.activationErrorSubmitBlock }>
                    <StyledErrorActivationButton
                        onClick={ () => setIsAuthRedirect(true) }
                        type='button'
                    >
                        { t.to_login_form }
                    </StyledErrorActivationButton>
                </Grid>
            </Box>
        </Grid>
    );
}
