import { SET_STATIC_INFO } from './constants';
import initialState from './initialState';

export const setStaticInfo = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATIC_INFO:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};