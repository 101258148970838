import { defaultWebCurrency } from '../../config/config';

const initialState = {
    withHeader: false,
    withoutFooter: true,
    headerWithoutImg: false,
    showOrdersInHeader: false,
    withLogo: false,
    webCurrency: defaultWebCurrency
};

export default initialState;