import React, { useEffect } from 'react';
import LoginForm from '../../../components/loginForm.jsx';
import { API } from "../../../lib/api";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { setAuthError } from './actions';
import { setClientData, setIsAuth } from '../actions';
import { StyledAuthContainer } from '../../../styles/auth/index';
import { setAppFrameData } from '../../app-frame/actions';
import { removeAuthDataInStore, setAuthDataToStore } from '../../../components/result/common';
import windowSize from '../../../hoc/windowSize';

function LoginContainer (props) {
    const { setAppFrameData, setIsAuth, setAuthError, isAuth } = props;

    useEffect(() => {
        setAppFrameData({
            withHeader: false
        })

        return () => {
            setAppFrameData({
                withHeader: true
            });
        };
    }, [isAuth]);

    const onSend = (data) => {
        API.login(data)
            .then(response => {
                setAuthDataToStore(response.data);

                setIsAuth(true);
            })
            .catch((error) => {
                removeAuthDataInStore();

                setAuthError(error.response.data);
            })
    }

    const resetLoginError = () => {
        setAuthError({
            error_code: null,
            error_desc: null
        })
    }

    if (isAuth) {
        return <Redirect to='/'/>
    }

    return (
        <StyledAuthContainer>
            <LoginForm
                onSend={ onSend }
                resetLoginError={ resetLoginError }
            />
        </StyledAuthContainer>
    );
}

const mapStateToProps = state => ({
    isAuth: state.setClientData.isAuth
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setAuthError,
    setClientData,
    setIsAuth,
    setAppFrameData
}, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    windowSize
)(LoginContainer);