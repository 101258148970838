import { SET_CITIES, SET_PASSENGERS, SET_ALL_PASSENGERS,
    SET_PASSENGERS_COUNT, SET_PASSENGERS_MAX, SET_INF_MAX
} from './constants';

import initialState from './initialState';

export const setSearchData = (state = initialState, action) => {
  switch (action.type) {
    case SET_CITIES:
        return {
            ...state,
              citiesItems: action.payload
        };
      case SET_PASSENGERS:
          return {
              ...state,
              passengers: { ...state.passengers, [action.payload.type]: action.payload.count}
          };
      case SET_ALL_PASSENGERS:
          return {
              ...state,
              passengers: action.payload
          };
      case SET_PASSENGERS_COUNT:
          return {
              ...state,
              passengersCount: action.payload
          };
      case SET_PASSENGERS_MAX:
          return {
              ...state,
              isMaximumPassengers: action.payload
          };
      case SET_INF_MAX:
          return {
              ...state,
              isMaximumInf: action.payload
          };
    default:
        return state;
  }
};
