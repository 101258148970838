export const appendToLocalStorage = (searchId, data) => {
    let searchHistory = getActualHistory();
    
    searchHistory = searchHistory.filter(x => x.searchId !== searchId);

    searchHistory.push({
        expires: data.formField.routes[0].date_from + 86399,        // Из первого роута берем дату вылета
        searchTime: Math.floor(Date.now() / 1000),
        searchId,
        flightInfo: data.formField,
        passengers: data.passengersData
    });

    localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
};

export const addToLocalStorage = history => {
    localStorage.setItem('searchHistory', JSON.stringify(history));
};

export const getActualHistory = () => {
    const searchHistory = getFromLocalStorage();

    if(searchHistory){
        const actualHistory = removeExpiredSearches(JSON.parse(searchHistory));
        localStorage.setItem('searchHistory', JSON.stringify(actualHistory));
        return actualHistory
    } else {
        return []
    }
};

export const removeExpiredSearches = searches => {
    return searches.filter(search => search.expires > Math.floor(Date.now() / 1000))
};

export const getFromLocalStorage = () => {
    return localStorage.getItem('searchHistory')
};