import { SET_BALANCES_ITEMS, SET_FILTERS, SET_IS_LOADING, SET_PAGING, SET_BALANCE_MOVEMENTS } from './constants';
import initialState from './initialState';

export const setBalanceMovementsData = (state = initialState, action) => {
    switch (action.type) {
        case SET_BALANCES_ITEMS:
            return {
                ...state,
                balanceItems: action.payload
            };
        case SET_FILTERS:
            return {
                ...state,
                filters: action.payload
            };
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        default:
            return state;
    }
};