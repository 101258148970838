import React from 'react';
import mainLogo from '../../images/mainLogo/logo-invert.png';
import { MainLogoBlock } from '../../styles/common/index';
import { uploadHost } from '../../config/config';

function MainLogo (props) {
    const { history, withReload, withLogo, partnerLogo } = props;

    const handleOnClick = () => {
        history.push({
            pathname: '/',
        });

        if (withReload) {
            window.location.reload();
        }
    };

    if (!withLogo) {
        return null;
    }

    const src = partnerLogo ? `${ uploadHost }${ partnerLogo }` : mainLogo;

    return (
        <MainLogoBlock onClick={ () => handleOnClick() }>
            <img
                alt='logo-img'
                src={ src }
                height='32px'
                width='75px'
            />
        </MainLogoBlock>
    );

}

export default MainLogo;