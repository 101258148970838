import React, { useContext, useState, useEffect, useRef } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AirportField from './airportField';
import CalendarField from './calendarField';
import { required, requiredHash, notSameRoutes } from '../../lib/validate';
import { Field } from 'redux-form';
import {useStyles} from '../../containers/search/styles';
import { StyledSearchButton } from '../../styles/common/index';
import { RT, CF } from '../../containers/search/constants';
import { LangContext } from '../../providers/LangProvider';
import {
    RemoveButtonStyled,
    SwitchButtonStyled
} from '../../styles/search/index';
import { AddRouteButton } from './searchOptionsForm';
import useOnClickOutside from 'use-onclickoutside';

function RouteFieldsForm (props) {
    const classes = useStyles();
    const { t, language } = useContext( LangContext );

    const { route, index, fields, removeRoutesCount, focusCalendar,
        switchRoutes, direction, getCities, cities, resetCities,
        updateAirport, selectedDates, changeDirection,
        removeCalendarValue, updateDate, addRoute
    } = props;

    const handleChangeCities = (value) => {
        if(value.length > 1) {
            getCities(value);
        }
    };

    const getCalendarActiveDateIndex = (routeIndex, fieldName) => {
        if (direction === CF) {
            return routeIndex;
        }
        if (fieldName === 'date_from') {
            return 0;
        }

        return 1;
    };

    const handleOnBlur = (event) => {
        event.preventDefault();
    };

    return (
        <Container fixed disableGutters>
            <Grid container className={classes.airportFieldContainer}>
                <Grid item xs={12} lg={6}>
                    <Grid container className={classes.airportRoutesContainer}>
                        <Grid item className={classes.airportFromFieldBlock} xs={12} md={6}>
                            <Field
                                name={ `${route}.from` }
                                component={ AirportFieldForm }
                                onChange={ handleChangeCities }
                                onBlur={ handleOnBlur }
                                placeholder={ t.route_from }
                                cities={ cities }
                                resetCities={ resetCities }
                                onUpdate={ (value) => updateAirport(route, 'from', value) }
                                type={ 'from' }
                                validate={ [
                                    required,
                                    requiredHash,
                                    notSameRoutes
                                ] }
                            />
                        </Grid>
                        <Grid item>
                            <RenderSwitchRoutes
                                route={ route }
                                index={ index }
                                switchRoutes={ switchRoutes }
                            />
                        </Grid>
                        <Grid item className={classes.airportToFieldBlock} xs={12} md={6}>
                            <Field
                                name={ `${route}.to` }
                                component={ AirportFieldForm }
                                onChange={ handleChangeCities }
                                onBlur={ handleOnBlur }
                                placeholder={ t.route_to}
                                cities={ cities }
                                resetCities={ resetCities }
                                onUpdate={ (value) => updateAirport(route, 'to', value) }
                                type={ 'to' }
                                validate={ [
                                    required,
                                    requiredHash,
                                    notSameRoutes
                                ] }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container>
                        <Grid item xs={12} sm={8} md={6} lg={8}>
                            <Grid container className={ classes.dateContainer }>
                                <Grid item className={ classes.dateFromBlock } xs>
                                    <Field
                                        placeholder={ t.date_from }
                                        isFocus={ focusCalendar.date_from }
                                        name={`${route}.date_from`}
                                        component={ CalendarFieldComponent }
                                        onUpdate={ (value) => updateDate(route, 'date_from', value) }
                                        directionType={ direction }
                                        changeDirection={ changeDirection }
                                        onBlur={ handleOnBlur }
                                        selectedDates={ selectedDates }
                                        activeDateIndex={ getCalendarActiveDateIndex(index, 'date_from') }
                                        removeCalendarValue={ () => removeCalendarValue(route, 'date_from') }
                                        validate={ [required] }
                                        language={ language }
                                    />
                                </Grid>
                                { direction === RT &&
                                    <Grid item xs>
                                        <Field
                                            placeholder={ t.date_to }
                                            isFocus={ focusCalendar.date_to }
                                            name={`${route}.date_to`}
                                            component={ CalendarFieldComponent }
                                            onUpdate={ (value) => updateDate(route, 'date_to', value)}
                                            directionType={ direction }
                                            changeDirection={ changeDirection }
                                            onBlur={ handleOnBlur }
                                            selectedDates={ selectedDates }
                                            activeDateIndex={ getCalendarActiveDateIndex(index, 'date_to') }
                                            removeCalendarValue={ () => removeCalendarValue(route, 'date_to') }
                                            validate={ [] }
                                            language={ language }
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item className={ classes.searchSubmitBlock } sm={ 4 } md={ 6 } lg={ 4 }>
                            <ChangeCountRoutesForMobile
                                fields={ fields }
                                index={ index }
                                addRoute={ addRoute }
                                removeRoutesCount={ removeRoutesCount }
                                isCFType={ direction === CF }/>
                            <SearchSubmit
                                fields={ fields }
                                index={ index }
                                removeRoutesCount={ removeRoutesCount }
                                className={ classes.searchSubmitTest }/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

const AirportFieldForm = (props) => {
    const { language } = useContext( LangContext );
    let ref = useRef();
    const [showDropdown, setShowDropdown] = useState(false);

    useOnClickOutside(ref, () => setShowDropdown(false));

    return <AirportField
        { ...props }
        language={ language }
        showDropdown={ showDropdown }
        itemRef={ ref }
        setShowDropdown={ setShowDropdown }
    />
};

const CalendarFieldComponent = (props) => {
    const { isFocus } = props;
    const ref = useRef();
    const [showCalendar, setShowCalendar] = useState(false);

    useEffect( () => {
        setShowCalendar(isFocus)
    }, [isFocus])

    useOnClickOutside(ref, () => setShowCalendar(false));

    return (
        <CalendarField
            { ...props }
            showCalendar={ showCalendar }
            setShowCalendar={ setShowCalendar }
            refData={ ref }
        />
    );
};

const ChangeCountRoutesForMobile = (props) => {
    const classes = useStyles();
    const { fields, index, removeRoutesCount, addRoute, isCFType } = props;

    const handleRemoveRoutesCount = () => {
        removeRoutesCount();
        fields.remove(index);
    };

    if (isCFType) {
        return (
            <Box className={ classes.changeRoutesCountButtons }>
                { ((index + 1) === fields.length && fields.length < 4)  &&
                    <Box>
                        <AddRouteButton
                            addRoute={ addRoute }
                        />
                    </Box>
                }
                { fields.length !== 1 &&
                    <Box className={ classes.removeButtonBlockForMobile }>
                        <RemoveButtonStyled
                            onClick={ handleRemoveRoutesCount }
                            type='button'
                        />
                    </Box>
                }
            </Box>
        )
    }

    return null;
};

const RenderSwitchRoutes = (props) => {
    const { route, index, switchRoutes } = props;

    return (
        <SwitchButtonStyled
            onClick={ () => switchRoutes(route, index) }
            type='button'
        />
    )
};

const SearchSubmit = (props) => {
    const { t } = useContext( LangContext );
    const { fields, index, removeRoutesCount } = props;
    const classes = useStyles();

    const handleRemoveRoutesCount = () => {
        removeRoutesCount();
        fields.remove(index);
    };

    return (
        <Grid className={ classes.searchSubmitButtonsBlock }>
            { index === 0 ?
                <StyledSearchButton type='submit'>{ t.find }</StyledSearchButton>
                :
                <RemoveButtonStyled
                    onClick={ handleRemoveRoutesCount }
                    type='button'
                />
            }
        </Grid>
    )
};

export default RouteFieldsForm;
