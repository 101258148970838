import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

function SnackbarAlert(props) {
    const { severity , message, setIsOpen, isOpen } = props;

    const handleClose = () => {
        setIsOpen(false);
    };

    const Transition = (props) => {
        return <Slide
            {...props}
            direction='right'
            children={
                <Alert
                    variant='filled'
                    severity={ severity }
                    action={
                        <IconButton
                            color='inherit'
                            size='small'
                            disableRipple
                            onClick={ handleClose }
                        >
                            <CloseIcon fontSize='inherit'/>
                        </IconButton>
                    }
                >
                    { message }
                </Alert>
            }
        />;
    }

    return <Snackbar
                open={ isOpen }
                onClose={ handleClose }
                TransitionComponent={ Transition }
                autoHideDuration={ 6000 }
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            />
}

export default SnackbarAlert;