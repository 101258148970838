import React, { Component } from 'react';
import PageNotFound from "../components/notFoundPage";
import { isB2c } from "../config/config";

export const checkAccessForB2B = (ComposedComponent) => {
    class RedirectComponent extends Component {
        render() {
            if (isB2c) {
                return <PageNotFound/>
            }

            return <ComposedComponent {...this.props} />;
        }
    }

    return RedirectComponent;
};