import React from 'react';
import { Container, Grid } from '@material-ui/core';
import config from '../../config/config';
import { useStyles } from '../../containers/search/styles';
import _ from 'lodash';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

import b2 from '../../images/suppliersCarousel/b2.png';
import fz from '../../images/suppliersCarousel/fz.png';
import hy from '../../images/suppliersCarousel/hy.png';
import kc from '../../images/suppliersCarousel/kc.png';
import n4 from '../../images/suppliersCarousel/n4.png';
import s7 from '../../images/suppliersCarousel/s7.png';
import su from '../../images/suppliersCarousel/su.png';
import tk from '../../images/suppliersCarousel/tk.png';
import u6 from '../../images/suppliersCarousel/u6.png';
import ut from '../../images/suppliersCarousel/ut.png';

const CarouselBlock = () => {
    const classes = useStyles();
    const { isB2c } = config;
    const { mainUrl } = config.getWebConfig();

    const checkIsFlyTJ = () => {
        return _.includes(
            ['fly.tj'],
            mainUrl
        );
    };

    const settings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true,
        pauseOnHover: true,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 3000,
        draggable: false,
        useCSS: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

    if(isB2c && checkIsFlyTJ()) {
        return (
            <Container fixed maxWidth={ 'lg' } className={ classes.carouselBlock }>
                <Grid item xs={ 12 }>
                    <Slider { ...settings } className={ classes.carouselItemBlock }>
                        <div className={ classes.carouselItemBlock }>
                            <div className={ classes.carouselItem }>
                                <img src={ b2 } className={ classes.carouselItemImage } />
                            </div>
                        </div>
                        <div className={ classes.carouselItemBlock }>
                            <div className={ classes.carouselItem }>
                                <img src={ fz } className={ classes.carouselItemImage } />
                            </div>
                        </div>
                        <div className={ classes.carouselItemBlock }>
                            <div className={ classes.carouselItem }>
                                <img src={ hy } className={ classes.carouselItemImage } />
                            </div>
                        </div>
                        <div className={ classes.carouselItemBlock }>
                            <div className={ classes.carouselItem }>
                                <img src={ kc } className={ classes.carouselItemImage } />
                            </div>
                        </div>
                        <div className={ classes.carouselItemBlock }>
                            <div className={ classes.carouselItem }>
                                <img src={ n4 } className={ classes.carouselItemImage } />
                            </div>
                        </div>
                        <div className={ classes.carouselItemBlock }>
                            <div className={ classes.carouselItem }>
                                <img src={ s7 } className={ classes.carouselItemImage } />
                            </div>
                        </div>
                        <div className={ classes.carouselItemBlock }>
                            <div className={ classes.carouselItem }>
                                <img src={ su } className={ classes.carouselItemImage } />
                            </div>
                        </div>
                        <div className={ classes.carouselItemBlock }>
                            <div className={ classes.carouselItem }>
                                <img src={ tk } className={ classes.carouselItemImage } />
                            </div>
                        </div>
                        <div className={ classes.carouselItemBlock }>
                            <div className={ classes.carouselItem }>
                                <img src={ u6 } className={ classes.carouselItemImage } />
                            </div>
                        </div>
                        <div className={ classes.carouselItemBlock }>
                            <div className={ classes.carouselItem }>
                                <img src={ ut } className={ classes.carouselItemImage } />
                            </div>
                        </div>
                    </Slider>
                </Grid>
            </Container>
        )
    }
    
    return null;
};

export default CarouselBlock;