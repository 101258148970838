import React, { useEffect, useState } from 'react';
import { API } from '../../../lib/api';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { setAppFrameData } from '../../app-frame/actions';
import ActivationUserForm from '../../../components/activationUser/activationUserForm';
import { StyledAuthContainer } from '../../../styles/auth';
import { setIsAuth } from '../actions';
import { removeAuthDataInStore } from '../../../components/result/common';
import * as config from '../../../config/config';
import ActivationErrorForm from '../../../components/activationUser/activationErrorForm';

const bcrypt = require('bcryptjs');
const nonAuthRoutes = config.NonAuthRoutes();

function ActivationUserContainer (props) {
    const { match: { params }, setAppFrameData, setIsAuth } = props;
    const { activationCode }  = params;
    const [isAuthRedirect, setIsAuthRedirect] = useState(false);
    const [errorActivation, setErrorActivation] = useState(false);

    useEffect(() => {
        setAppFrameData({
            withHeader: false
        })

        handelLogout();

        return () => {
            setAppFrameData({
                withHeader: true
            });
        };
    }, []);

    const handelLogout = () => {
        removeAuthDataInStore();
        setIsAuth(false);
    };

    const handelOnSend = (data) => {
        const { password } = data;

        const salt = bcrypt.genSaltSync(12);
        const hashPassword = bcrypt.hashSync(password, salt);

        const params = {
            activation_code: activationCode,
            new_password: hashPassword
        };

        API.activationUser(params)
            .then(() => {
                setIsAuthRedirect(true);
            })
            .catch(() => {
                setErrorActivation(true);
            })
    }

    if (isAuthRedirect) return <Redirect to={ nonAuthRoutes.login }/>

    if (errorActivation) {
        return (
            <StyledAuthContainer>
                <ActivationErrorForm
                    setIsAuthRedirect={ setIsAuthRedirect }
                />
            </StyledAuthContainer>
        )
    }

    return (
        <StyledAuthContainer>
            <ActivationUserForm
                onSend={ handelOnSend }
            />
        </StyledAuthContainer>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setAppFrameData,
    setIsAuth
}, dispatch);

export default compose(
    connect(null, mapDispatchToProps),
)(ActivationUserContainer);