import React, { useContext } from 'react';
import { LangContext } from '../../providers/LangProvider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useStyles } from "./styles";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingForBlock () {
    const classes = useStyles();

    return (
        <Box className={ classes.rootContainerLoadingBlock }>
            <RenderLoading />
        </Box>
    );
}

const RenderLoading = () =>  {
    const classes = useStyles();
    const { t } = useContext(LangContext);

    return(
        <Grid
            container
            justify='center'
            alignItems='center'
            direction='column'
            className={ classes.rootBlockLoadingBlock }
        >
            <Box className={ classes.titleLoadingBlock }>
                { t.wait_please }
            </Box>
            <CircularProgress
                size={ 86 }
                className={ classes.circularProgress }
            />
        </Grid>
    );
}
