import { defaultAvailableCurrencies } from '../../config/config';

const initialState = {
    clientName: null,
    phone: null,
    email: null,
    partnerName: null,
    partnerCurrency: null,
    partnerCode: null,
    clientCode: null,
    ownFunds: null,
    creditLine: null,
    creditLimit: null,
    otb: null,
    availableCurrencies: defaultAvailableCurrencies,
    loaded: false,
    isAuth: true,
    partnerLogo: null,
    isShowClientDataAlert: false
};

export default initialState;