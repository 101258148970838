import React, { useContext } from 'react';
import { Container, Grid } from '@material-ui/core';
import bannerImg from '../../images/banners/flytj-korti-milli.jpg';
import config from '../../config/config';
import { useStyles } from '../../containers/search/styles';
import { NavLink } from 'react-router-dom';
import { LangContext } from '../../providers/LangProvider';
import _ from 'lodash';

const BannerBlock = () => {
    const classes = useStyles();
    const { language } = useContext(LangContext);
    const { isB2c } = config;
    const { mainUrl } = config.getWebConfig();

    const checkIsFlyTJ = () => {
        return _.includes(
            ['fly.tj'],
            mainUrl
        );
    };

    if(isB2c && checkIsFlyTJ()) {
        return (
            <Container fixed maxWidth={ 'lg' } className={ classes.bannersBlock }>
                <Grid item xs={ 12 }>
                    <NavLink to={ `/infoPage/korti-milli-${language}` } target={ '_blank' }>
                        <img src={ bannerImg } className={ classes.bannersImage } />
                    </NavLink>
                </Grid>
            </Container>
        )
    }
    
    return null;
}

export default BannerBlock;