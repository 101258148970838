import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingCommonContainer from '../containers/loadingForms/mainLoading';
import { API } from '../lib/api';
import { bindActionCreators } from 'redux';
import { setClientData, setIsAuth, setIsShowEmptyClientDataAlert } from '../containers/auth/actions';
import { setAppFrameData } from '../containers/app-frame/actions';
import _ from 'lodash';
import * as config from '../config/config';
import { AccessRoleContext } from '../providers/AccessRoleProvider';

const nonAuthRoutes = config.NonAuthRoutes();

export function withAuthRedirect(ComposedComponent) {
    const RedirectComponent = (props) => {
        const { setUserRole } = useContext( AccessRoleContext );
        const [isLoad, setIsLoad] = useState(false);
        const { setIsAuth, setClientData, setAppFrameData, isAuth, loaded,
            setIsShowEmptyClientDataAlert } = props;

        useEffect( () => {
            setUserData();
            setLocalStorageData();
        }, []);

        const setLocalStorageData = () => {
            if (_.isNull(localStorage.getItem('langData'))) {
                API.getLanguages().then(response => {
                    const serializedResponse = JSON.stringify(response);

                    localStorage.setItem('langData', serializedResponse)
                });
            }

            if(localStorage.getItem('webLanguage') === null) {
                localStorage.setItem('webLanguage', config.language());
            }
        };

        const setUserData = () => {
            API.getUserData().then(response => {
                setIsAuth(true);
                setUserRole(response.clientType);
        
                let webCurrency = '';
                let lsWebCurrency = localStorage.getItem('webCurrency');
        
                if (!lsWebCurrency) {
                    webCurrency = response.partnerCurrency;
                } else {
                    if(_.find(response.availableCurrencies, v => v === lsWebCurrency)){
                        webCurrency = lsWebCurrency;
                    } else {
                        webCurrency = response.partnerCurrency;
                    }
                }
        
                setAppFrameData({
                    webCurrency
                });
                localStorage.setItem('webCurrency', webCurrency);
        
                response.clientCode = localStorage.getItem('client_code');
                response.partnerCode = localStorage.getItem('partner_code');
        
                setClientData(response);
        
                if (_.isEmpty(response.phone) || _.isEmpty(response.clientName)) {
                    if (!loaded) {
                        setIsShowEmptyClientDataAlert(true);
                    }
                }
            }).catch(() => {
                setIsAuth(false);
            });
        
            setIsLoad(true);
        };

        if (!isLoad) {
            return <LoadingCommonContainer />;
        }

        if (isAuth) {
            if (loaded) {
                return <ComposedComponent {...props} />
            }

            return <LoadingCommonContainer />;
        }

        return <Redirect to={ nonAuthRoutes.login }/>;
    }

    const mapStateToProps = state => ({
        isAuth: state.setClientData.isAuth,
        loaded: state.setClientData.loaded
    });

    const mapDispatchToProps = dispatch => bindActionCreators({
        setClientData,
        setIsAuth,
        setAppFrameData,
        setIsShowEmptyClientDataAlert
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(RedirectComponent);
}

