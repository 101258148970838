import { SET_COMPANIES_LIST } from './constants';
import initialState from './initialState';

export const setCompaniesList = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANIES_LIST:
            return {
                ...state,
                companiesList: action.payload.data,
                isLoadCompaniesList: action.payload.loadStatus
            };
        default:
            return state;
    }
};