import React, { Component } from 'react';
import * as Styled from "../../../../styles/common/ui/dropdown/index";
import _ from 'lodash'
import PropTypes from 'prop-types';

function RenderItemComponent(props) {
    return props.component(props);
}

class Dropdown extends Component {
    defaultItemComponent(item) {
        return(
            <Styled.DropdownItem
                onClick={this.props.onSelect.bind(this, item['value'])}
                isSelected={this.props.selected === item['value']}
            >
                <Styled.ChaildItem>
                    { item['label'] }
                </Styled.ChaildItem>
            </Styled.DropdownItem>
        )
    }

    renderItems(){
        const { items, component } = this.props;

        return (
            _.map(items, (item, index) => (
                <div key={index}>
                    {component ? <RenderItemComponent item={item} { ...this.props }/> : this.defaultItemComponent(item) }
                </div>
            ))
        );
    }

    render() {
        const { items, turned } = this.props;

        if(items.length) {
            return (
                <Styled.DropdownContainer
                    turned={ turned }
                >
                    {this.renderItems()}
                </Styled.DropdownContainer>
            );
        }

        return null;
    }
}

Dropdown.propTypes = {
    items: PropTypes.array,
    onSelect: PropTypes.func,
    turned: PropTypes.bool
};

Dropdown.defaultProps = {
    items: [],
    turned: false,
    onSelect: ()=> {}
};

export default Dropdown;