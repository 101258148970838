import React, { Component } from 'react';
import { FormFieldLabel } from '../../../../styles/common/index';

class Label extends Component {
    render() {
        const { text } = this.props;

        return(
            <FormFieldLabel>
                <span>{ text }</span>
            </FormFieldLabel>
        );
    }
}

export default Label;