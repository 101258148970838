import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    rootContainer: {
        padding: '24px 0 64px',
        width: '100%'
    },
    mainBlock: {
        padding: '20px 0 20px 24px',
        backgroundColor: '#ffffff',
        borderRadius: '8px'
    },
    mainBlockItem: {
        width: '100%'
    },
    paperTable: {
        width: '100%',
        borderRadius: '8px',
        backgroundColor: '#ffffff'
    },
    headTableRow: {
        borderBottom: '3px solid #ecedee'
    },
    tableRow: {
        cursor: 'pointer',

        '&$selected, &$selected:hover': {
            backgroundColor: '#fbe9e7 !important'
        }
    },
    disabledTableRow: {
        cursor: 'default',
        backgroundColor: '#f2f4f5'
    },
    tableContainer: {
        maxHeight: '600px'
    },
    titleBlock: {
        paddingLeft: '32px',
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        cursor: 'pointer',
        right: '3%'
    },
    title: {
        fontSize: '24px',
        fontWeight: '500',
    },
    buttonProgressSaving: {
        position: 'absolute',
        top: '20%',
        left: '40%'
    },
    userContainer: {
        paddingLeft: '8px',
    },
    userFieldItem: {
        width: '100%',
        minHeight: '86px'
    },
    submitBlock: {
        display: 'flex'
    },
    cancelButton: {
        height: '40px',
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: '#fafbfc',
        '&:active': {
            backgroundColor: '#cfd8dc'
        },
        '&:disabled': {
            backgroundColor: '#f2f4f5',
            color: '#b9c1c5'
        },
    },
    cancelButtonTitle: {
        paddingLeft: '8px',
        paddingRight: '8px'
    },
    submitButtonBlock: {
        marginLeft: '12px'
    },
    removeButtonBlock: {
        position: 'relative'
    },
    buttonProgressRemoving: {
        position: 'absolute',
        top: '20%',
        left: '42%'
    },
    optionsBlock: {
        marginBottom: '24px'
    },
    searchFieldInput: {
        height: '48px'
    },
    searchIcon: {
        paddingLeft: '8px',
        color: '#b9c1c5'
    },
    buttonsBlock: {
        display: 'flex'
    },
    searchSelectFieldBlock: {
        paddingLeft: '8px'
    },
    checkIcon: {
        color: '#4caf50'
    },
    clearIcon: {
        color: '#c62828'
    },
    selected: {}
});