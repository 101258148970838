import React, { useContext } from 'react';
import { LangContext } from '../../providers/LangProvider';
import SelectTransparentUI from '../ui/components/SelectTransparentUI/SelectTransparentUI';

function DirectionField (props) {
    const { onChangeDirection, input, direction } = props;
    const { t } = useContext( LangContext );

    const directionItems = [
        { value: 'OW', label: t.one_way },
        { value: 'RT', label: t.two_way },
        { value: 'CF', label: t.multi_way }
    ];

    return <SelectTransparentUI
        items={ directionItems }
        value={ direction }
        input={ input }
        onChange={ onChangeDirection }
    />
}

export default DirectionField;