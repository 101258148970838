import React, { useContext, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { LangContext } from '../../../../providers/LangProvider';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const UploadButton = withStyles({
    root: {
        boxShadow: 'none',
        height: '40px',
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: '#fafbfc',
        '&:hover': {
            backgroundColor: '#f2f4f5',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#cfd8dc'
        }
    },
    contained: {
        '&:disabled': {
            backgroundColor: '#f2f4f5',
            color: '#b9c1c5'
        },
    }
})(Button);

const useStyles = makeStyles({
    rootContainer: {
        position: 'relative'
    },
    loadingProgress: {
        position: 'absolute',
        bottom: '8px',
        left: '55px'
    },
    inputBlock: {
        display: 'none'
    }
});

function FileInput(props) {
    const classes = useStyles();
    const { t } = useContext( LangContext );
    const { uploadFile, disabled, isLoading, component } = props;

    const ButtonComponent = () => {
        return (
            <Fragment>
                <label htmlFor="contained-button-file">
                    <UploadButton
                        variant='contained'
                        component='span'
                        startIcon={ <CloudUploadIcon /> }
                        disabled={ disabled || isLoading }
                        disableRipple
                    >
                        { t.upload }
                    </UploadButton>
                </label>
                { isLoading &&
                    <CircularProgress
                        style={{
                            color: '#ff7043'
                        }}
                        size={ 24 }
                        className={ classes.loadingProgress }
                    />
                }
            </Fragment>
        )
    }

    const Component = () => {
        return component;
    }

    return (
        <FormControl
            onChange={ (data) => uploadFile(data.target.files[0]) }
            className={ classes.rootContainer }
        >
            <div>
                <input
                    accept='.jpg, .png, .jpeg, .gif'
                    disabled={ disabled || isLoading }
                    className={ classes.inputBlock }
                    id='contained-button-file'
                    multiple
                    type='file'
                />
                <label htmlFor='contained-button-file'>
                    { component ? <Component /> : <ButtonComponent/> }
                </label>
            </div>
        </FormControl>
    );
}

export default FileInput;