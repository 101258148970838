import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import FooterMenu from '../../../components/common/footerMenu';
import { setAppFrameData } from '../../app-frame/actions';
import { bindActionCreators } from 'redux';
import { API } from '../../../lib/api';
import { LangContext } from '../../../providers/LangProvider';
import { setCmsStaticPagesList } from './actions';
import * as config from '../../../config/config';

const { mainUrl } = config.getWebConfig();

function Footer(props) {
    const { language } = useContext( LangContext );
    const { setCmsStaticPagesList } = props;

    useEffect( () => {
        API.getStaticPagesList({ site: mainUrl }).then(response => {
            setCmsStaticPagesList({
                isLoadList: true,
                staticPagesListData: response.data
            });
        })

        return () => {
            setCmsStaticPagesList({
                isLoadList: false,
                staticPagesListData: []
            });
        }
    }, [language])

    const setWebCurrency = (webCurrency) => {
        localStorage.setItem('webCurrency', webCurrency);
        props.setAppFrameData({ webCurrency })
    }

    return <FooterMenu
        {...props}
        setWebCurrency={ setWebCurrency }
    />
}

const mapStateToProps = state => ({
    withoutFooter: state.setAppFrameData.withoutFooter,
    webCurrency: state.setAppFrameData.webCurrency,
    availableCurrencies: state.setClientData.availableCurrencies,
    isLoadList: state.setCmsStaticPagesList.isLoadList,
    staticPagesListData: state.setCmsStaticPagesList.staticPagesListData
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setAppFrameData,
    setCmsStaticPagesList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (Footer);