import React, {useContext, useState} from 'react';
import { Field } from 'redux-form';
import { ArrowDown, ArrowUp } from "../../styles/common/index";

import { PassengersLink, StyledPassengerLink } from "../../styles/search/index";
import PassengersField from "./PassengersField";
import { LangContext } from '../../providers/LangProvider';

function PassengersSelectorForm (props) {
    const { passengersData } = props;
    const { t } = useContext( LangContext );
    const {
        passengers,
        setPassengers,
        setPassengersCount,
        passengersCount,
        setPassengersMax,
        setInfMax,
        isMaximumPassengers,
        isMaximumInf
    } = passengersData;

    const [passengersBlockShow, setPassengersBlockShow] = useState(false);
    const labelKey = `passenger_${ passengersCount }`
    const passLinkLabel = t[labelKey];

    const handlePassengersBlockChange = () => {
        setPassengersBlockShow(!passengersBlockShow)
    };

    return (
        <PassengersLink>
            <StyledPassengerLink
                onClick={ handlePassengersBlockChange }
                className="PassengerLink"
            >
                { passLinkLabel }
                { passengersBlockShow ? <ArrowUp /> : <ArrowDown /> }
            </StyledPassengerLink>

            { passengersBlockShow && <Field
                component={ PassengersField }
                name="passengersCount"
                setPassengersCount={ setPassengersCount }
                setPassengers={ setPassengers }
                passengersData = { passengers }
                passengersCount= { passengersCount }
                setPassengersMax={ setPassengersMax }
                setInfMax={ setInfMax }
                isMaximumPassengers={ isMaximumPassengers }
                isMaximumInf={ isMaximumInf }
                outsideClickIgnoreClass={"PassengerLink"}
                handlePassengersBlockChange={ handlePassengersBlockChange }
                t={ t }
            />
            }
        </PassengersLink>
    );
}

export default PassengersSelectorForm;

