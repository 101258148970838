import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { setAppFrameData } from '../app-frame/actions';
import * as Styled from '../../styles/loadingResult/index';
import LoadingResultContainer from './loadingResult';
import LoadingCommonContainer from './loadingCommon';
import LoadingPaymentContainer from './loadingPayment';

const MainLoadingContainer = (props) => {
    useEffect(() => {
        props.setAppFrameData({
            withHeader: false,
            withoutFooter: true
        });

        return () => {
            props.setAppFrameData({
                withHeader: true
            })
        };
    }, []);

    return (
        <Styled.LoadingContainer>
            <div>
                <RenderLoadingContainer { ...props} />
            </div>
        </Styled.LoadingContainer>
    );
};

const RenderLoadingContainer = (props) => {
    switch (props.type) {
        case 'result':
            return <LoadingResultContainer {...props}/>;
        case 'payment':
            return <LoadingPaymentContainer {...props}/>;
        default:
            return <LoadingCommonContainer {...props}/>;
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setAppFrameData
}, dispatch);

export default compose(
    connect(null, mapDispatchToProps)
)(MainLoadingContainer);
