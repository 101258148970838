import styled from 'styled-components'
import {InvertSelectArrowDown, StyledButton} from "../../index";
import {RemoveButtonStyled} from "../../../search/index";

export const StyledCalendarBlock = styled.div`    
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 4px;
    position: absolute;
    z-index: 101;
    top: 100%;
    left: 0;    
    width: 298px;
    margin: auto;
    height: 440px;
    
    @media (min-width:345px) {
        width: 316px;
    }

    @media (min-width: 375px) {
       ${props => props.theme.desktop ? `
            width: 342px;
       ` :
        `
            width: 335px;
       `}       
    }

    @media (min-width: 390px) {
        width: 348px;
    }

    @media (min-width: 410px) {
        width: 364px;
    }
    
    @media (min-width: 414px) {
        width: 374px;
    }
    
    @media (min-width: 576px) {
        width: ${props => props.theme.desktop ? '528px' : '542px'};
    }
    
    @media (min-width: 768px) {
        width: 497px;
    }

    @media (min-width: 1275px) {
        height: 457px;
        width: 524px;
    }

    @media (min-width: 1280px){
        left: 16px;
    }
`;

export const StyledCalendarButton = styled(StyledButton)`
    border-radius: 4px;
    background-color: ${props => props.isActive ? '#ff7043' : '#fafbfc'};
    color: ${props => props.isActive ? '#ffffff' : '#212121'};
    font-weight: 500;
    
    border: ${props => props.isActive ? 'solid 1px #cfd8dc' : 'none'};
    
    :hover {
        background-color: ${props => props.isActive ? '#f05e30' : '#f2f4f5'};
    }
    
    :active {
        background-color: ${props => props.isActive ? '#c63f17' : '#cfd8dc'};
    }
   
    width: ${props => props.theme.desktop ? '90px' : '98px'};
    height: 24px;
    font-size: 12px;
    margin-top: 8px;
    
    @media (min-width:345px) {
        
    }

    @media (min-width: 375px) {
        
    }

    @media (min-width: 390px) {
        
    }

    @media (min-width: 410px) {
        
    }

    @media (min-width: 576px) {
        width: 164px;
        height: 32px;
        font-size: 16px;
        margin-top: ${props => props.theme.desktop ? '20px' : '8px'};
    }
    
    @media (min-width: 768px) {
        width: 154px;
        margin-top: 20px;
    }
`;
export const StyledWeekDayBlock = styled.div`
    height: 20px;

    @media (min-width:345px) {
        
    }

    @media (min-width: 375px) {
        
    }

    @media (min-width: 390px) {
        
    }

    @media (min-width: 410px) {
        
    }

    @media (min-width: 576px) {
        height: 28px;
    }
`;

export const StyledCalendarButtonRight = styled(StyledCalendarButton)`
    width: ${props => props.theme.desktop ? '100px' : '108px'};
    margin-left: 6px;
    
    @media (min-width:345px) {
        
    }

    @media (min-width: 375px) {
        
    }

    @media (min-width: 390px) {
        
    }

    @media (min-width: 410px) {
        
    }

    @media (min-width: 576px) {
       width: 164px;
       margin-left: 11px;
    }
    
    @media (min-width: 768px) {
        width: 144px;
    }
`;

export const StyledMonthBlock = styled.div`
    height: 100%;
    background-color: #f2f4f5;
    float: left;
    
    width: 70px;
    
    @media (min-width:345px) {
        
    }

    @media (min-width: 375px) {
        
    }

    @media (min-width: 390px) {
        
    }

    @media (min-width: 410px) {
        
    }
    
    @media (min-width: 576px) {
        width: 124px;
    }

    @media (min-width: 768px) {
        width: 120px;
    }
`;

export const StyledDaysBlock = styled.div`
    height: 100%;
    float: right;
    
    width: 224px;
    
    @media (min-width:345px) {
         width: 245px;
    }
    
    @media (min-width: 375px) {
        width: 261px;
    }
    
    @media (min-width: 390px) {
        width: 265px;
    }
    
    @media (min-width: 410px) {
        width: 280px;
    }
    
    @media (min-width: 414px) {
        width: 300px;
    }
    
    @media (min-width: 576px) {
        width: 404px;
    }
    
    @media (min-width: 768px) {
        width: 375px;
    }
    
    @media (min-width: 1275px) {
        width: 404px;
    }
`;

export const StyledWeekDayShortName = styled.ul`
    width: 100%;
    height: 100%;
    text-align: center;
    
    padding-left: 0px;
    margin: 16px 0 8px 0;
    
    @media (min-width:345px) {
        
    }
    
    @media (min-width: 375px) {
        
    }
    
    @media (min-width: 390px) {
        
    }
    
    @media (min-width: 410px) {
        
    }
    
    @media (min-width: 576px) {
        padding-left: 16px;
        margin: 24px 0 8px 0;
    }
`;

export const StyledWeekDay = styled.li`
    display: inline-block;
    color:  ${props => props.isWeekday ? '#212121' : '#c63f17'};
    
    font-size: 16px;
    width: 20px;
    margin-right: 8px;
    
    @media (min-width:345px) {
        margin-right: 15px;
    }
    
    @media (min-width: 375px) {
        margin-right: 16px;
    }
    
    @media (min-width: 390px) {
         margin-right: 17px;
    }
    
    @media (min-width: 410px) {
         margin-right: 20px;
    }
    
    @media (min-width: 414px) {
         margin-right: 21px;
    }
    
    @media (min-width: 576px) {
        font-size: 18px;
        width: 18px;
        margin-right: 35px;
    }
`;

export const StyledBottomLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: #eeeeec;
`;

export const StyledMonthsScroll = styled.div(props => ({
    position: 'relative',
    'overflow-y': 'scroll',
    'overflow-x': 'hidden'
}));

export const StyledMonths = styled(StyledMonthsScroll)`
    margin: 12px 12px 13px 2px;
    height: 340px;
    
    @media (min-width:345px) {

    }

    @media (min-width: 375px) {

    }

    @media (min-width: 390px) {

    }

    @media (min-width: 410px) {

    }

    @media (min-width: 576px) {
        height: 278px;
        margin: 20px 13px 13px 13px;
    }
`;

export const StyledMonth = styled.div`
    height: ${props =>
        props.lastMonth ? "372px" : "100%"}
    
    @media (min-width:345px) {
    
    }

    @media (min-width: 375px) {

    }

    @media (min-width: 390px) {

    }

    @media (min-width: 410px) {

    }

    @media (min-width: 576px) {
        height: 100%;
    }
`;

export const StyledMonthTitle = styled.div`
    color: #212121;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    
    margin-left: 2px;
    padding-top: 8px;
    
    @media (min-width:345px) {

    }

    @media (min-width: 375px) {

    }

    @media (min-width: 390px) {

    }

    @media (min-width: 410px) {

    }

    @media (min-width: 576px) {
        margin-left: 8px;
        padding-top: 0px;
    }
`;

export const StyledWeek = styled.div`
    width: 100%;
    display: table;
    table-layout: fixed;
`;

export const StyledDayWrapper = styled.div`
    display: table-cell;
    text-align: center;
    
    line-height: 28px;
    
    @media (min-width:345px) {
    
    }
    
    @media (min-width: 375px) {
    
    }
    
    @media (min-width: 390px) {
    
    }
    
    @media (min-width: 410px) {
    
    }
    
    @media (min-width: 576px) {
        line-height: 50px;
    }
`;

export const StyledDay = styled.button`
    cursor: pointer;
    font-weight: 500;   
    border-radius: 4px;
    border: none;
    color:  ${props => props.isWeekday ? '#212121' : '#c63f17'};
   
    background-color: ${props => props.selected ? '#ff7043' : '#ffffff'}
   
    width: 27px;
    height: 28px;
    font-size: 14px;
    
    @media (min-width:345px) {
    
    }
    
    @media (min-width: 375px) {
    
    }
    
    @media (min-width: 390px) {
    
    }
    
    @media (min-width: 410px) {
    
    }
    
    @media (min-width: 576px) {
        width: 52px;
        height: 52px;
        font-size: 16px;
    }
    
    @media (min-width: 768px) {
        width: 48px;
        height: 48px;
        font-size: 16px;
    }
    
    @media (min-width: 1275px) {
        width: 52px;
        height: 52px;
        font-size: 16px;
    }
    
   ${props => props.disabled ? `
        color: #b9c1c5;
        cursor: default;
   `
    :
    `:hover {
        background-color: #fbe9e7;
    }
    
    :active {
        background-color: #ff7043;
    }
    `}
`;

export const StyledMonthName = styled.div`
    color: #212121;
    height: 36px;
    cursor: pointer;
    
    font-size: 12px;
    width: 71px;
    
    @media (min-width:345px) {
        
    }

    @media (min-width: 375px) {
        
    }

    @media (min-width: 390px) {
        
    }

    @media (min-width: 410px) {
        
    }

    @media (min-width: 576px) {
        width: 160px;
        font-size: 14px;
    }
`;

export const StyledYear = styled.div`
    margin-left: 6px;
    color: #90a4ae;
    position: absolute;
    font-size: 12px;
    
    left: 0px;
    top: 12px;
    
    @media (min-width:345px) {
        
    }

    @media (min-width: 375px) {
        
    }

    @media (min-width: 390px) {
        
    }

    @media (min-width: 410px) {
        
    }

    @media (min-width: 576px) {
        left: 50px;
        top: 1px;
    }
`;

export const StyledMonthNameBlock = styled.div`
    text-align: left;
    position: relative;
    
   margin-left: 8px;
    
    @media (min-width:345px) {
        
    }

    @media (min-width: 375px) {
        
    }

    @media (min-width: 390px) {
        
    }

    @media (min-width: 410px) {
        
    }

    @media (min-width: 576px) {
        margin-left: 16px;
        display: flex;
    }
`;

export const StyledCalendarFieldButtonBlock = styled.div`
    position: relative;
`;

export const CalendarArrowDown = styled(InvertSelectArrowDown)`
    position: absolute;
    bottom: 20px;
    right: 15px;
`;

export const StyledRemoveButton = styled(RemoveButtonStyled)`
    bottom:  ${props => props.isMobile ? '28px' : '10px'};
    right:  ${props => props.isMobile ? '-5px' : '5px'};
    background-size:  ${props => props.isMobile ? '16px' : '24px'};
    
    margin-top: 0;
    filter: invert(0);
    position: absolute;
`;