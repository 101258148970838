import React, { Fragment, useState, useContext, useRef, useLayoutEffect } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/tg';
import { Link, Element } from 'react-scroll';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './index.css';
import {
    StyledBottomLine,
    StyledCalendarBlock,
    StyledCalendarButton,
    StyledCalendarButtonRight,
    StyledDay,
    StyledDaysBlock,
    StyledDayWrapper,
    StyledMonth,
    StyledMonthBlock,
    StyledMonthName,
    StyledMonthNameBlock,
    StyledMonths,
    StyledMonthTitle,
    StyledWeek,
    StyledWeekDay,
    StyledWeekDayBlock,
    StyledWeekDayShortName,
    StyledYear
} from '../../../../styles/common/ui/calendar/index';
import { OW, RT, CF } from '../../../../containers/search/constants';
import { LangContext } from '../../../../providers/LangProvider';
import { parserLangCode } from '../../../result/common';

const formatSelectedDates = (date) => {
    if (date === null) {
        return date;
    }

    return moment.unix(date);
}

const isCFType = (directionType) => {
    return directionType === CF;
}

const findPreviousDate = (selectedDates, activeDateIndex) => {
    if (activeDateIndex < 0) {
        return null;
    }

    return selectedDates[activeDateIndex - 1] ||
        findPreviousDate(selectedDates, activeDateIndex - 1);
}

const findNextDate = (selectedDates, activeDateIndex) => {
    if (activeDateIndex >= selectedDates.length) {
        return null;
    }

    return selectedDates[activeDateIndex + 1] ||
        findNextDate(selectedDates, activeDateIndex + 1);
}

function Calendar (props) {
    const { t, language } = useContext( LangContext );
    const { activeDateIndex, monthsLimit, directionType, displayMonths,
        ignoreDisabledDates, changeDirection, isLeft, onSelect } = props;
    const dayRef = useRef();

    moment.updateLocale(parserLangCode(language), {
        week: { dow: 1 }
    });

    const startDate = moment();
    const [dateNow] = useState(startDate.clone().startOf('month'));
    const [lastDate] = useState(startDate.clone().add(monthsLimit, 'months'));
    const [actualYear] = useState(dateNow.format('YYYY'));
    const [selectedDates] = useState(_.map(props.selectedDates, formatSelectedDates));

    useLayoutEffect( () => {
        if (dayRef.current) {
            dayRef.current.focus();
        }
    })

    const getMonths = () => {
        let months = [];

        for (let i = 0; i < displayMonths; i++) {
            months.push({
                startDate: dateNow.clone().add(i, 'months')
            });
        }

        return months;
    }

    const getWeeks = (month) => {
        const activeDate = selectedDates[activeDateIndex];
        const monthLastDate = month.startDate.clone().endOf('month');
        const firstWeekDay = month.startDate.weekday();
        const firstDay = month.startDate.date();
        const lastDay = monthLastDate.date();
        const weeksCount = Math.ceil((lastDay + firstWeekDay) / 7);
        const previousSelectedDate = findPreviousDate(selectedDates, activeDateIndex);
        const nextSelectedDate = findNextDate(selectedDates, activeDateIndex);

        let leftDateLimit = startDate.clone().startOf('day');

        if (previousSelectedDate && previousSelectedDate.isAfter(leftDateLimit)) {
            leftDateLimit = previousSelectedDate;
        }

        let rightDateLimit = lastDate;

        if (nextSelectedDate && nextSelectedDate.isBefore(rightDateLimit)) {
            rightDateLimit = nextSelectedDate;
        }

        let weeks = [];

        for (let i = 0; i < weeksCount; i++) {
            weeks.push([]);
            for (let j = 0; j < 7; j++) {
                weeks[i].push(null);
            }
        }

        const focusedDate = () => {
            let active;

            if (_.isNull(activeDate)) {
                for (let i = (activeDateIndex - 1); i >= 0; i--) {
                    if (selectedDates[i]) {
                        active = selectedDates[i];
                        break;
                    }
                }
            }
            else {
                active = activeDate;
            }

            return !!active && active.unix();
        }

        let rightDateLimitTime = rightDateLimit.unix();
        let leftDateLimitTime = leftDateLimit.unix();
        let activeDateTime = !!activeDate && activeDate.unix();
        let selectedDateTimes = selectedDates.map((sel)=> !!sel && sel.unix());

        let weekIndex = 0;
        let dayIndex = firstWeekDay;

        for (let i = firstDay; i <= lastDay; i++) {
            let date = month.startDate.clone().date(i);
            let time = date.unix();
            let selectedIndex = _.findIndex(selectedDateTimes, (sel)=> sel === time);

            weeks[weekIndex][dayIndex] = {
                date,
                active: activeDateTime === time,
                focused: focusedDate() === time,
                selected: !!~selectedIndex,
                selectedIndex: selectedIndex,
                disabled: !ignoreDisabledDates && ( time < leftDateLimitTime || time > rightDateLimitTime )
            };

            dayIndex++;

            if (dayIndex > 6) {
                dayIndex = 0;
                weekIndex++;
            }
        }

        return weeks;
    }

    const handleDayClick = (day, ev) => {
        if (!day.disabled) {
            onSelect(day.date, ev);
        }
    }

    const renderMonthHead = (month) => {
        const monthTitle = month.startDate.format('MMMM');

        return (
            <StyledMonthTitle>
                { monthTitle }
            </StyledMonthTitle>
        );
    }

    const renderDay = (day, index) => {
        return <StyledDayWrapper key={ index }>
            { day &&
                <StyledDay
                    isWeekday={ index < 5 }
                    onClick={ (value) => handleDayClick(day, value) }
                    disabled={ day.disabled }
                    selected={ day.selected }
                    ref={ day.focused ? dayRef : null }
                >
                    { day.date.date() }
                </StyledDay>
            }
        </StyledDayWrapper>
    }

    const renderWeek = (week, index) => {
        return (
            <StyledWeek key={ index }>
                { week.map(renderDay) }
            </StyledWeek>
        );
    }

    const renderMonth = (month, index) => {
        const weeks = getWeeks(month);

        return (
            <StyledMonth
                key={ index }
                lastMonth={ index + 1 === monthsLimit }
            >
                <Element
                    name={ month.startDate.format('DDDD') }
                >
                    { renderMonthHead(month) }
                    { weeks.map(renderWeek) }
                </Element>
            </StyledMonth>
        );
    }

    const Months = () => {
        const months = getMonths();

        return (
            <StyledMonths id='scrollContainerElement'>
                <Element>
                    { months.map(renderMonth) }
                </Element>
            </StyledMonths>
        );
    }

    const isShowYear = (month) => {
        if (actualYear !== month.startDate.format('YYYY') && month.startDate.month() === 0) {
            return true;
        }
    }

    const MonthsList = () => {
        const months = getMonths();

        return(
            months.map(monthForList)
        );
    }

    const monthForList = (month, index) => {
        return(
            <StyledMonthName key={ index }>
                <Link
                    to={ month.startDate.format('DDDD') }
                    spy={ true }
                    smooth={ true }
                    containerId='scrollContainerElement'
                    activeClass='activeScrollMonth'
                >
                    <div className='MonthBlock'>
                        <StyledMonthNameBlock>
                            { month.startDate.format('MMMM') }

                            { isShowYear(month) &&
                                <StyledYear>
                                    { month.startDate.format('YYYY') }
                                </StyledYear>
                            }
                        </StyledMonthNameBlock>
                    </div>
                </Link>
            </StyledMonthName>
        )
    }

    const WeekDayShortName = () => {
        let weekDays = [];

        for (let i = 0; i < 7; i++) {
            weekDays[i] = i;
        }

        return (
            <StyledWeekDayBlock>
                <StyledWeekDayShortName>
                    { weekDays.map(day => (
                            <StyledWeekDay key={ day } isWeekday={ day < 5 }>
                                {
                                    moment().weekday(day).format('dd').toLowerCase()
                                }
                            </StyledWeekDay>
                        )
                    )}
                </StyledWeekDayShortName>
                <StyledBottomLine/>
            </StyledWeekDayBlock>
        );
    }

    const RouteButtons = () => {
        return(
            <Fragment>
                <StyledCalendarButton
                    isActive={ directionType === OW }
                    type='button'
                    onClick={ () => handleChangeDirection(OW)}
                >
                    { t.only_there }
                </StyledCalendarButton>
                <StyledCalendarButtonRight
                    isActive={ directionType === RT }
                    type='button'
                    onClick={ () => handleChangeDirection(RT)}
                >
                    { t.roundtrip }
                </StyledCalendarButtonRight>
            </Fragment>
        );
    }

    const handleChangeDirection = (type) => {
        changeDirection(type, true);
    }

    return(
        <StyledCalendarBlock isLeft={ isLeft }>
            <StyledMonthBlock>
                <MonthsList/>
            </StyledMonthBlock>
            <StyledDaysBlock>
                <RouteButtons />
                <WeekDayShortName/>
                <Months/>
            </StyledDaysBlock>
        </StyledCalendarBlock>
    );
}

Calendar.propTypes = {
    onSelect: PropTypes.func,
    monthsLimit: PropTypes.number,
    controlProps: PropTypes.object,
    selectedDates: PropTypes.array,
    displayMonths: PropTypes.number,
    activeDateIndex: PropTypes.number,
    ignoreDisabledDates: PropTypes.bool,
    directionType: PropTypes.string
};

Calendar.defaultProps = {
    controlProps: {},
    monthsLimit: 9,
    displayMonths: 10,
    selectedDates: [],
    activeDateIndex: 0,
    onSelect: ()=> {},
    ignoreDisabledDates: false,
    directionType: RT
};

export default Calendar;