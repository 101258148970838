import React, {useState, Fragment, useContext} from 'react';
import { withRouter } from 'react-router';
import AppFrame from '../../components/appFrame';
import { connect } from 'react-redux';
import { setClientData, setIsAuth, setIsShowEmptyClientDataAlert } from '../auth/actions';
import { API } from '../../lib/api';
import { bindActionCreators, compose } from 'redux';
import { setAppFrameData } from './actions';
import { removeAuthDataInStore } from '../../components/result/common';
import AddUserDataForm from '../../components/users/AddUserDataForm';
import { change } from 'redux-form';
import SnackbarAlert from '../../components/ui/components/SnackbarAlert/SnackbarAlert';
import { LangContext } from '../../providers/LangProvider';

function AppFrameContainer (props) {
    const { t } = useContext( LangContext );
    const { history, isShowClientDataAlert, setIsShowEmptyClientDataAlert,
        userData, dispatch } = props;
    const [doNotFoundOrder, setDoNotFoundOrder] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertData, setAlertData] = useState({
        severity: 'success',
        message: t.saved_successfully
    });

    const searchOrder = (data) => {
        API.apiGetOrderId(data).then(response => {
            if (response === undefined) {
                setDoNotFoundOrder(true);
            }
            else {
                redirectToOrder(response);
            }
        });
    };

    const saveClientData = (data, setUpdateStatus) => {
        let params = { ...data }

        if (params.phone) {
            params.phone = `+${ data.phone.replace(/[^\d]/g, '') }`;
        }

        API.apiUpdateUser(params)
            .then(() => {
                setUpdateStatus(true);
            })
            .catch(() => {
                setUpdateStatus(false);
            });
    }

    const handelSetSaveClientDataAlert = (status, message) => {
        if (status) {
            setAlertData({
                severity: 'success',
                message
            })
        }
        else {
            setAlertData({
                severity: 'error',
                message
            })
        }

        setOpenAlert(true);
    }

    const handelSetDefaultClientData = () => {
        const { clientCode, partnerCode, clientMail, clientPhone, clientName, clientType} = userData;

        dispatch(change('userData', 'client_code', clientCode));
        dispatch(change('userData', 'partner_code', partnerCode));
        dispatch(change('userData', 'email', clientMail));
        dispatch(change('userData', 'phone', clientPhone));
        dispatch(change('userData', 'client_name', clientName));
        dispatch(change('userData', 'client_type', clientType));
    }

    const clearSearchOrderFailResult = () => {
        setDoNotFoundOrder(false);
    };

    const redirectToOrder = (data) => {
        const { session_id, order_id } = data;
        const pathName = history.location.pathname.split('/');

        history.push({
            pathname: `/order/${order_id}/${session_id}`
        });

        if( pathName[1] === 'order' ) {
            window.location.reload();
        }
    };

    const logout = () => {
        API.logout().then(() => {
            removeAuthDataInStore();
        })
    };

    return (
        <Fragment>
            <AppFrame
                { ...props }
                doNotFoundOrder={ doNotFoundOrder }
                searchOrder={ searchOrder }
                logout={ logout }
                clearSearchOrderFailResult={ clearSearchOrderFailResult }
            />
            <AddUserDataForm
                openModal={ isShowClientDataAlert }
                setOpenModal={ setIsShowEmptyClientDataAlert }
                saveUser={ saveClientData }
                setAlert={ handelSetSaveClientDataAlert }
                setDefaultData={ handelSetDefaultClientData }
                userData={ userData }
            />
            <SnackbarAlert
                severity={ alertData.severity }
                message={ alertData.message }
                isOpen={ openAlert }
                setIsOpen={ setOpenAlert }
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    withLogo: state.setAppFrameData.withLogo,
    partnerLogo: state.setClientData.partnerLogo,
    isShowClientDataAlert: state.setClientData.isShowClientDataAlert,
    userData: {
        clientName: state.setClientData.clientName,
        clientPhone: state.setClientData.phone,
        clientCode: state.setClientData.clientCode,
        partnerCode: state.setClientData.partnerCode,
        clientMail: state.setClientData.email,
        clientType: state.setClientData.clientType
    }
});

let mapDispatchToProps = dispatch => bindActionCreators({
    setClientData,
    setIsAuth,
    setAppFrameData,
    setIsShowEmptyClientDataAlert,
    dispatch
}, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(AppFrameContainer);