import { PS, PSP, SR } from "../containers/booking/constants";
import _ from 'lodash';

export const normalizeDocumentNumber = (documentType) => (value, previousValue) => {
    if (!value) {
        return value
    }

    if(documentType === PS){
        const data = value.replace(/[^\d]/g, '');

        if (data.length > 10) {
            return previousValue;
        }

        return data.toUpperCase();
    }
    else if(documentType === PSP) {
        const data = value.replace(/[^\d]/g, '');
        if (data.length > 9) {
            return previousValue;
        }

        return data.toUpperCase();
    }
    else if(documentType === SR) {
        const data = value.replace(/[^0-9а-яА-ЯIVXivx]/g,'');

        if (data.length > 12) {
            return previousValue;
        }

        return data.toUpperCase();
    }
    else {
        const data = value.replace(/[^0-9a-zA-Z]/g,'');

        if (data.length > 15) {
            return previousValue;
        }

        return data.toUpperCase()
    }
};

export const normalizeMaxLength = (length) => (value, previousValue) => {
    if (value.length > length) {
        return previousValue;
    }

    return value;
};

export const normalizeClientNames = (value, previousValue) => {
    return value.toUpperCase();
};

export const normalizeFloat = (value, previousValue) => {
    value = value
        .replace(/[^0-9.]/g, '');

    const sections = value.split('.');

    if (sections[0] !== '0' && sections[0] !== '00') {
        sections[0] = sections[0].replace(/^0+/, '')
    } else {
        sections[0] = '0'
    }

    if (sections[1]) {
        return sections[0] + '.' + sections[1].slice(0, 2)
    } else if (value.indexOf('.') !== -1) {
        return sections[0] + '.'
    } else {
        return sections[0]
    }
};

export const normalizeInteger = (value, previousValue) => {
   return value.replace(/[^\d]/g, '');
};

const normalizeCardNumber = (value, previousValue) => {
    const data = value.replace(/[^\d]/g, '');

    if (data.length > 16) {
        return previousValue;
    }

    const newArray = _.chunk(data, 4);
    const formated = _.map(newArray, function (item) {
        return item.join('')
    });

    return formated.join(' ');
};

const normalizeCvc = (value, previousValue) => {
    const data = value.replace(/[^\d]/g, '');

    if (data.length > 3) {
        return previousValue;
    }

    return data;
};

const normalizeCardNames = (value, previousValue) => {
    const data = value.replace(/[^a-zA-Z -]/g,'');

    return data.toUpperCase();
};

export const normalizeUserName = (value, previousValue) => {
    if (previousValue) {
        const lastCharPre = value.substr(value.length - 1)
        const lastCharVal = previousValue.substr(previousValue.length - 1)

        if (lastCharPre === lastCharVal && _.includes(['-', ' ', '_', '.'], lastCharVal)) {
            return previousValue;
        }
    }

    return value.replace(/[^a-zA-Zа-яА-Я0-9._ -]/g,'');
};

const normalizeCardDate = (value, previousValue) => {
    const data = value.replace(/[^\d]/g, '');

    if (data.length > 2) {
        return previousValue;
    }

    return data;
};

export const normalizePrice = price => {
    price += '';
    let x = price.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2');
    }
    return x1 + x2;
};