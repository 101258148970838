import React, { useContext, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useStyles } from '../../containers/auth/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { LangContext } from '../../providers/LangProvider';
import { required, minLength6 } from '../../lib/validate';
import ResponsiveFormField from './../ui/components/ResponsiveFormField/ResponsiveFormField';
import ResponsiveInput from './../ui/components/ResponsiveInput/ResponsiveInput';
import { StyledLoginButton } from '../../styles/auth';
import SelectLanguage from "../common/ui/SelectLanguage";

function ActivationUserForm (props) {
    const classes = useStyles();
    const { t } = useContext( LangContext );
    const { onSend, windowWidth, handleSubmit } = props;
    const [passwordError, setPasswordError] = useState(false);

    const handelOnSubmit = (data) => {
        const { password, confirmationPassword} = data;

        if (password !== confirmationPassword) {
            return setPasswordError(true);
        }

        onSend(data);
    };

    return (
        <Grid container className={ classes.rootContainer }>
            <form onSubmit={ handleSubmit(handelOnSubmit) } className={ classes.form }>
                <Box className={ classes.activationBlock }>
                    <Grid item className={ classes.loginTitleBlock }>
                        <Box className={ classes.loginTitle }>
                            { t.activation_title }
                        </Box>
                    </Grid>
                    <Grid item className={ classes.inputBlock }>
                        <Box className={ classes.inputItem }>
                            <Field
                                name='password'
                                type='password'
                                component={ ResponsiveFormField }
                                label={ t.enter_activation_password }
                                fieldError={ passwordError ? t.passwords_must_match : null }
                                onChange={ () => setPasswordError(false) }
                                validate={[
                                    required,
                                    minLength6
                                ]}
                                isPadding={ true }
                                InputComponent={ ResponsiveInput }
                            />
                        </Box>
                    </Grid>
                    <Grid item className={ classes.inputBlock }>
                        <Box className={ classes.inputItem }>
                            <Field
                                name='confirmationPassword'
                                type='password'
                                component={ ResponsiveFormField }
                                label={ t.confirmation_activation_password }
                                fieldError={ passwordError ? t.passwords_must_match : null }
                                onChange={ () => setPasswordError(false) }
                                validate={[
                                    required,
                                    minLength6
                                ]}
                                isPadding={ true }
                                InputComponent={ ResponsiveInput }
                            />
                        </Box>
                    </Grid>
                    <Grid item>
                        <StyledLoginButton type='submit'>
                            { t.activation }
                        </StyledLoginButton>
                    </Grid>
                    <Box className={ classes.langBLock }>
                        <SelectLanguage
                            withoutTitle={ true }
                            fontSize='16px'
                        />
                    </Box>
                </Box>
            </form>
        </Grid>
    );
}

export default compose(
    reduxForm({ form: 'activationUser' })
)(ActivationUserForm);
