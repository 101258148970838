import React, { Component } from 'react';
import { InvalidMessage } from '../../../../styles/common/index';

class InputMessage extends Component {
    render() {
        const { text } = this.props;

        return (
            <InvalidMessage>
                { text }
            </InvalidMessage>
        );
    }
}

export default InputMessage;