import React, { useState, useContext } from 'react';
import { Divider, Container, Grid, Backdrop, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as Styled from '../../styles/common/headerMenu/index';
import { StyledHeaderMenuButton } from '../../styles/common/index';
import { useStyles } from '../../containers/common/header/styles';
import SearchOrderModal from '../ui/components/SearchOrderModal/SearchOrderModal';
import { LangContext } from '../../providers/LangProvider';
import MainLogo from './mainLogo';

export default function B2cMobileHeaderMenu(props) {
    const classes = useStyles();
    const { t } = useContext( LangContext );

    const [ openSearchOrderModal, setOpenSearchOrderModal ] = useState(false);

    const {
        headerWithoutImg,
        searchOrder,
        doNotFoundOrder,
        clearSearchOrderFailResult,
        handleClickShowMenu,
        setShowMobileMenu,
        showMobileMenu,
        heightSize,
        goToSearchPage,
        agentsUrl,
        goToAgentsPage
    } = props;

    const agentsBlockForMobile = () => (
        <div
            onClick={ goToAgentsPage }
            className={ classes.agency }
        >
            { t.agents }
        </div>
    );

    return (
        <Styled.HeaderContainer headerWithoutImg={ headerWithoutImg }>
            <Container fixed>
                <Grid container justify={ 'space-between' }>
                    <Grid item className={ classes.logoBlock }>
                        <MainLogo { ...props }/>
                    </Grid>
                    <Grid item>
                        <StyledHeaderMenuButton onClick={ handleClickShowMenu }/>
                    </Grid>
                </Grid>
                <Backdrop 
                    className={ classes.backdrop } 
                    open={ showMobileMenu } 
                    onClick={ () => setShowMobileMenu(false) }
                >
                    <Slide
                        direction='left'
                        in={ showMobileMenu }
                        mountOnEnter
                        unmountOnExit
                    >
                        <div
                            className={ classes.menuDropdown}
                            style={{ height: `${heightSize}px` }}
                        >
                            <div
                                className={ classes.closeMenuIconBlock }
                                onClick={ handleClickShowMenu }
                            >
                                <CloseIcon className={ classes.closeMenuIcon } />
                            </div>
                            <div className={ classes.mobileMenuItem }>
                                <div onClick={ goToSearchPage }>
                                    { t.to_main }
                                </div>
                            </div>
                            <div className={ classes.lineBlock }>
                                <Divider className={classes.line}/>
                            </div>
                            <div className={ classes.mobileMenuItem }>
                                <div onClick={ () => setOpenSearchOrderModal(true) }>
                                    { t.search_order }
                                </div>
                                <Divider className={classes.line}/>
                            </div>
                            { !agentsUrl ? null : agentsBlockForMobile() }
                        </div>
                    </Slide>
                </Backdrop>
                <div>
                    <SearchOrderModal
                        openModal={ openSearchOrderModal }
                        setOpenModal={ setOpenSearchOrderModal }
                        searchOrder={ searchOrder }
                        doNotFoundOrder={ doNotFoundOrder }
                        clearSearchOrderFailResult={ clearSearchOrderFailResult }
                    />
                </div>
            </Container>
        </Styled.HeaderContainer>
    );
}