import { uploadHost } from '../config/config';

export const getSupplierIcon = (validatingSupplier) => {
    try {
        return require('../images/suppliers/'+validatingSupplier+'.png');
    } catch (error) {
        console.warn(error);
    }
};

export const getFlightIcon = (index) => {
    return require('../images/flightIcon/'+index+'_flight-takeoff.png');
};

export const getTransferArrowIcon = (transferCount) => {
    return require('../images/transfersArrow/'+transferCount+'_transfer.png');
};

export const getRectangleImg = (item) => {
    return require('../images/recommendationGuide/'+item+'_rectangle.png');
};

export const getCountryIcon = (code) => {
    try {
        return require('../images/flags/'+code+'.png');
    } catch (error) {
        console.warn(error);
    }
};

export const getImgFromUploads = (img) => {
    return `${ uploadHost }${ img }`;
};