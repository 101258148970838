import React from 'react';
import {connect} from "react-redux";
import HeaderMenu from "../../../components/common/headerMenu";

function Header (props) {
    return <HeaderMenu {...props}/>
}

const mapStateToProps = state => ({
    hideHeader: state.setAppFrameData.hideHeader,
    withHeader: state.setAppFrameData.withHeader,
    headerWithoutImg: state.setAppFrameData.headerWithoutImg,
    showOrdersInHeader: state.setAppFrameData.showOrdersInHeader,
    clientData: {
        ownFunds: state.setClientData.ownFunds,
        otb: state.setClientData.otb,
        creditLine: state.setClientData.creditLine,
        partnerCurrency: state.setClientData.partnerCurrency,
        partnerName: state.setClientData.partnerName,
        clientName: state.setClientData.clientName
    }
});

export default connect(mapStateToProps, null) (Header);