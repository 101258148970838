import React, { useEffect } from 'react';
import UI from '../ui/ui';
import * as Styled from '../../styles/search/index';
import * as config from '../../config/config';
import axios from 'axios';
import _ from 'lodash';
import { API } from '../../lib/api';

const getFormattedValue = (value, language) => {
    if (typeof value === 'object') {
        return value['value'][language].substr(0, 22);
    }

    return value.substr(0, 22);
};

const getDefaultCities = () => {
    return config.defaultCitiesList()
};

const getCitiesList = (cities, isValue) => {
    if(isValue === 0) {
        return getDefaultCities();
    }

    return cities;
};

const AirportField = props => {
    const {
        resetCities,
        onUpdate,
        meta: { touched, error },
        placeholder,
        disabled,
        input,
        fieldError,
        cities,
        language,
        showDropdown,
        setShowDropdown,
        itemRef,
        type
    } = props;

    const { mainUrl } = config.getWebConfig();

    useEffect(() => {
        if(_.includes(['55fly.ru', 'fly.tj'], mainUrl) && type === 'from')
            getMyCity();
    }, []);

    const getMyCity = async () => {
        let ip = '';
        await API.getMyIp().then(response => {
            ip = response.ip;
        });
        
        if (ip.length > 0) {
            const myCity = await API.getMyIpInfo(ip).then(response => {
                                return response;
                            });
            const city = await API.getCities(myCity.city, { language: 'en' }).then(response => {
                                return response;
                            });
            if(city.length > 0){
                const first = _.first(city);
                input.onChange(first.value[language]);
                onUpdate({ value: first.value, code: first.code });
            }
        }
    };

    const handleOnSelect = (value, code) => {
        setShowDropdown(false);
        resetCities();
        onUpdate({ value, code });
    };

    const handleChange = (e) => {
        setShowDropdown(true);
        input.onChange(e.target.value);
    };

    const handleOnClick = (e) => {
        setShowDropdown(true);
    };

    const renderItemComponent = (props) => {
        const { item } = props;

        return (
            <Styled.DropdownAirportItem
                isMaim={ item['isMain'] }
                onClick={ (value) => handleOnSelect(item['value'], item['code'], value)}
            >
                <Styled.MainItem isMaim={ item['isMain'] }>
                    <Styled.AirportNamesBlock>
                        <Styled.AirportItem>
                            { item['value'][language] }
                        </Styled.AirportItem>
                        { item['isMain'] ?
                            <Styled.CountryMame>
                                { item['countryName'][language] }
                            </Styled.CountryMame>
                            :
                            null
                        }
                    </Styled.AirportNamesBlock>
                    <Styled.CodeItem isMaim={ item['isMain'] }>
                        { item['code'] }
                    </Styled.CodeItem>
                </Styled.MainItem>
            </Styled.DropdownAirportItem>
        );
    };

    const formattedValue = getFormattedValue(input.value, language);
    const citiesList = getCitiesList(cities, input.value.length);

    return(
        <Styled.AirportFieldContainer>
            <Styled.StyledAirportInput
                { ...input }
                value={ formattedValue }
                placeholder={ placeholder }
                disabled={ disabled }
                onChange={ handleChange }
                borderColor={ fieldError || (touched && error) ? '#c62828' : '' }
                onClick={ handleOnClick }
            />
            { input.value['code'] &&
                <Styled.CodeIata>
                    { input.value['code'] }
                </Styled.CodeIata>
            }
            { showDropdown &&
                <Styled.DropdownArea ref={ itemRef }>
                    <UI.Dropdown
                        items={ citiesList }
                        component={ renderItemComponent }
                    />
                </Styled.DropdownArea>
            }
        </Styled.AirportFieldContainer>
    );
};

export default AirportField;
