import React, { Component } from 'react';
import UI from '../ui/ui';
import _ from 'lodash'
import {PASSENGERS_LIST, PASSENGERS_MIN_ITEMS, LIMITS, INF} from "../../containers/search/constants";
import onClickOutside from "react-onclickoutside";
import {
    StyledNumberInputLabelContainer, StyledNumberInputMainLabel, StyledNumberInputSecondLabel,
    StyledPassengersBlock, StyledPassengersContainer
} from "../../styles/search/index";

class PassengersField extends Component {
    handleClickOutside = evt => {
        this.props.handlePassengersBlockChange();
    };

    handleChange(type, count) {
        const hashPassengersData = this.getHashPassengersData(type, count);

        this.props.setPassengers({ type, count });

        let passengersCount = this.getCountPassengers(hashPassengersData);

        this.props.setPassengersCount(passengersCount);

        if(passengersCount === LIMITS) {
            this.setMaximumPassengers(true);
        }
        else{
            this.setMaximumPassengers(false);
        }

        if(hashPassengersData['inf'] < hashPassengersData['adt']) {
            this.setIsMaximumInf(false);
        }
        else{
            this.setIsMaximumInf(true);
        }

        if(hashPassengersData['inf'] > hashPassengersData['adt']) {
            const count = hashPassengersData['adt'];
            const type = _.clone(INF);

            this.props.setPassengers({ type, count });

            this.props.setPassengersCount(passengersCount - 1);
        }
    }

    getHashPassengersData(type, count) {
        const { passengersData } = this.props;

        let hashPassengersData = _.clone(passengersData);

        hashPassengersData[type] = count;

        return hashPassengersData;
    }

    getCountPassengers(hashPassengersData) {
        let passengersCount = 0;

        _.mapValues(hashPassengersData, function(count) { passengersCount += count; });

        return passengersCount;
    }

    getIsMaximumPassengers(type) {
        const { isMaximumPassengers, isMaximumInf } = this.props;

        if(type === INF) {
            return  isMaximumPassengers || isMaximumInf;
        }

        return isMaximumPassengers;
    }

    setMaximumPassengers(status){
        this.props.setPassengersMax(status);
    }

    setIsMaximumInf(status) {
        this.props.setInfMax(status)
    }

    passengerLabel(type, t) {
        const mainLabelKey = `${ type }_main_label`
        const secondLabelKey = `${ type }_second_label`
        const mainLabel = t[mainLabelKey];
        const secondLabel = t[secondLabelKey];

        return(
            <StyledNumberInputLabelContainer>
                <StyledNumberInputMainLabel>{ mainLabel }</StyledNumberInputMainLabel>
                <StyledNumberInputSecondLabel>{ secondLabel }</StyledNumberInputSecondLabel>
            </StyledNumberInputLabelContainer>
        )
    }

    renderPassengerItems() {
        const { passengersData, t } = this.props;

        const passengersBlock = _.map(PASSENGERS_LIST, (type, index) => (
            <UI.NumberInput
                {...this.props}
                key={index}
                value={passengersData[type]}
                label={this.passengerLabel(type, t)}
                min={PASSENGERS_MIN_ITEMS[type]}
                onChange={this.handleChange.bind(this, type)}
                isMaximum={this.getIsMaximumPassengers(type)}
            />
        ));

        return (
            <StyledPassengersContainer>
                <StyledPassengersBlock>
                    { passengersBlock }
                </StyledPassengersBlock>
            </StyledPassengersContainer>
        )
    }

    render() {
        return(
            this.renderPassengerItems()
        );
    }
}

export default onClickOutside(PassengersField);