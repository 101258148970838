const config = {
    appName: 'Fly TJ',
    countSearchCity: 7,
    countOrdersLimit: 12,
    environment: process.env.NODE_ENV,
    apiHost: process.env.REACT_APP_API_HOST,
    billingHost: process.env.REACT_APP_BILLING_URL,
    uploadHost: process.env.REACT_APP_UPLOAD_URL,
    hostURL: process.env.REACT_APP_HOST_URL,
    serverTimeZone: 'Asia/Dushanbe',
    serverDateFormat: 'DD MMM YYYY, dd',
    serverDateTimeFormat: 'DD MMM YYYY, dd, HH:mm',
    serverDateTimeFormatWithoutYear: 'DD MMM, HH:mm',
    webTransferTimeFormat: 'DD.MM.YYYY HH:mm',
    DateFormatWithoutPoint: 'DD MMM YYYY',
    formatForGroupOrders: 'MMMM YYYY',
    defaultFlightType: 'RT',
    defaultCabin: 'all',
    pageRecLimit: 10,
    friendlyCountriesCodes: ['RU', 'AM', 'BY', 'АЦ', 'KZ', 'ЮО', 'KG'],
    defaultAvailableCurrencies: ['RUB', 'USD', 'TJS'],
    defaultWebCurrency: 'RUB',
    isB2c:  process.env.REACT_APP_B2C === undefined ? false : process.env.REACT_APP_B2C.length > 0,
    googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    getMyIpService: process.env.REACT_APP_GET_IP_SERVICE,
    getIpLocationService: process.env.REACT_APP_GET_IP_LOCATION_SERVICE,
    getIpLocationToken: process.env.REACT_APP_GET_IP_LOCATION_TOKEN,
    language() {
        return localStorage.getItem('webLanguage') || 'ru';
    },
    defaultCitiesList() {
        return defaultCitiesList;
    },
    defaultCountriesList() {
        return defaultCountriesList
    },
    getWebConfig() {
        return webConfig();
    },
    b2cData() {
        return b2cData();
    },
    NonAuthRoutes() {
        return  NonAuthRoutesList();
    },
    AuthRoutes() {
        return  AuthRoutesList();
    },
    UserRoles() {
        return UserRolesList();
    }
};

const AuthRoutesList = () => {
    return {
        result:  '/result/:searchId',
        booking: '/booking',
        order: '/order/:orderId/:sessionId',
        staticPage: '/infoPage/:staticUrl',
        orders: '/orders',
        personalInfo: '/contractSettings/personalInfo',
        partnerSittings: '/contractSettings/partnerSettings',
        passengers: '/contractSettings/passengers',
        users: '/contractSettings/users',
        companies: '/contractSettings/companies',
        balanceMovements: '/finances/balanceMovements',
        main: '/',
        notFoundPage: '*'
    }
};

const NonAuthRoutesList = () => {
    return {
        login:  '/login',
        activationUser:  '/activationUser/:activationCode',
        popularDestinations: '/popularDestinations',
        contactsPage: '/contacts',
        theyTrustUs: '/theyTrustUs'
    }
};

const UserRolesList = () => {
    return {
        user: 'user',
        user_agent: 'user_agent',
        manager: 'manager',
        admin: 'admin'
    }
}

const webConfig = () => {
    return {
        supportPhone: process.env.REACT_APP_SUPPORT_PHONE,
        supportMail: process.env.REACT_APP_SUPPORT_MAIL,
        infoMail: process.env.REACT_APP_INFO_MAIL,
        techSupportMail: process.env.REACT_APP_TECH_SUPPORT_MAIL,
        saleMail: process.env.REACT_APP_SALE_MAIL,
        footerUrl: process.env.REACT_APP_FOOTER_URL,
        facebookLink: process.env.REACT_APP_FACEBOOK_URL,
        mainUrl: process.env.REACT_APP_MAIN_URL,
        agentsUrl: process.env.REACT_APP_AGENTS_LINK
    }
};

const defaultCitiesList = [
    {
        code: "MOW",
        countryCode: "RU",
        countryName: {
            ru: "Российская Федерация (Россия)",
            en: "Russian Federation",
            tj: "Федератсияи Русия (Русия)"
        },
        value: {
            ru: "Москва",
            en: "Moscow",
            tj: "Moscow"
        },
        isMain: 1
    },
    {
        code: "BKA",
        countryCode: "RU",
        countryName: {
            ru: "Российская Федерация (Россия)",
            en: "Russian Federation",
            tj: "Федератсияи Русия (Русия)"
        },
        value: {
            ru: "Быково",
            en: "Bykovo",
            tj: "Bykovo"
        },
        isMain: 0
    },
    {
        code: "SVO",
        countryCode: "RU",
        countryName: {
            ru: "Российская Федерация (Россия)",
            en: "Russian Federation",
            tj: "Федератсияи Русия (Русия)"
        },
        value: {
            ru: "Шереметьево",
            en: "Sheremetyevo International",
            tj: "Sheremetyevo International"
        },
        isMain: 0
    },
    {
        code: "VKO",
        countryCode: "RU",
        countryName: {
            ru: "Российская Федерация (Россия)",
            en: "Russian Federation",
            tj: "Федератсияи Русия (Русия)"
        },
        value: {
            ru: "Внуково",
            en: "Vnukovo International",
            tj: "Vnukovo International"
        },
        isMain: 0
    },
    {
        code: "MOW",
        countryCode: "ZIA",
        countryName: {
            ru: "Российская Федерация (Россия)",
            en: "Russian Federation",
            tj: "Федератсияи Русия (Русия)"
        },
        value: {
            ru: "Жуковский",
            en: "Zhukovsky",
            tj: "Zhukovsky"
        },
        isMain: 0
    },
    {
        code: "DME",
        countryCode: "RU",
        countryName: {
            ru: "Российская Федерация (Россия)",
            en: "Russian Federation",
            tj: "Федератсияи Русия (Русия)"
        },
        value: {
            ru: "Домодедово",
            en: "Domodedovo",
            tj: "Domodedovo"
        },
        isMain: 0
    },
    {
        code: "ALA",
        countryCode: "KZ",
        countryName: {
            ru: "Казахстан",
            en: "Kazakhstan",
            tj: "Қазоқистон"
        },
        value: {
            ru: "Алматы",
            en: "Almaty",
            tj: "Almaty"
        },
        isMain: 1
    },
    {
        code: "IST",
        countryCode: "TR",
        countryName: {
            ru: "Турция",
            en: "Turkey",
            tj: "Туркия"
        },
        value: {
            ru: "Стамбул",
            en: "Istanbul",
            tj: "Istanbul"
        },
        isMain: 1
    },
    {
        code: "SAW",
        countryCode: "TR",
        countryName: {
            ru: "Турция",
            en: "Turkey",
            tj: "Туркия"
        },
        value: {
            ru: "Сабиха-Гокен",
            en: "Sabiha Gokcen",
            tj: "Sabiha Gokcen"
        },
        isMain: 0
    },
    {
        code: "IST",
        countryCode: "TR",
        countryName: {
            ru: "Турция",
            en: "Turkey",
            tj: "Туркия"
        },
        value: {
            ru: "Ататурк",
            en: "Ataturk",
            tj: "Ataturk"
        },
        isMain: 0
    },
    {
        code: "URC",
        countryCode: "CN",
        countryName: {
            ru: "Китай",
            en: "China",
            tj: "Хитой"
        },
        value: {
            ru: "Урумчи",
            en: "Urumqi",
            tj: "Urumqi"
        },
        isMain: 1
    }
];

const defaultCountriesList = [
    {
        code: "RU",
        country: {
            ru: "Российская Федерация (Россия)",
            en: "Russian Federation",
            tj: "Федератсияи Русия (Русия)"
        }
    },
    {
        code: "KZ",
        country: {
            ru: "Казахстан",
            en: "Kazakhstan",
            tj: "Қазоқистон"
        }
    },
    {
        code: "TR",
        country: {
            ru: "Турция",
            en: "Turkey",
            tj: "Туркия"
        }
    },
    {
        code: "CN",
        country: {
            ru: "Китай",
            en: "China",
            tj: "Хитой"
        }
    },
    {
        code: "TJ",
        country: {
            ru: "Республика Таджикистан",
            en: "Tajikistan",
            tj: "Ҷумҳурии Тоҷикистон"
        }
    },
    {
        code: "UZ",
        country: {
            ru: "Республика Узбекистан",
            en: "Uzbekistan",
            tj: "Ҷумҳурии Ӯзбекистон"
        }
    },
    {
        code: "KG",
        country: {
            ru: "Кыргызская Республика",
            en: "Kyrgyzstan",
            tj: "Қирғизистон"
        }
    }
];

const b2cData = () => {
    return {
        client_code:  process.env.REACT_APP_CLIENT_CODE,
        partner_code:  process.env.REACT_APP_PARTNER_CODE,
        token_type:  process.env.REACT_APP_TOKEN_TYPE,
        access_token:  process.env.REACT_APP_ACCESS_TOKEN
    }
};

module.exports = config;
