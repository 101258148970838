import { SET_PASSENGERS, SET_COUNTRIES, SET_PASSENGER_ITEM } from './constants';
import initialState from './initialState';

export const setPassengers = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUNTRIES:
            return {
                ...state,
                countriesItems: action.payload
            };
        case SET_PASSENGERS:
            return {
                ...state,
                passengers: action.payload.data,
                isLoad: action.payload.loadStatus
            };
        case SET_PASSENGER_ITEM:
            return {
                ...state,
                passengerItem: action.payload.data
            };
        default:
            return state;
    }
};