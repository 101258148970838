const initialState = {
    balanceItems: false,
    filters: {
        dateStart: null,
        dateEnd: null,
        orderId: "",
        bookingNumber: "",
        limit: 10,
        currentPage: 1,
        lastPage: 1
    },
    isLoading: true
};

export default initialState;