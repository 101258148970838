export const SET_PARTNER_FEES = 'SET_PARTNER_FEES';
export const SET_PARTNER_DATA = 'SET_PARTNER_DATA';

export const FLAT = 'flat';
export const COMBINATION = 'combination';
export const PERCENT = 'percent';
export const PERCENT_FROM_TOTAL = 'percent_from_total';
export const PERCENT_FROM_FARE = 'percent_from_fare';
export const FIX_FLAT = 'fix_flat';
export const FIX_FLAT_AND_PERCENT_FROM_TOTAL = 'fix_flat_and_percent_from_total';
export const FIX_FLAT_AND_PERCENT_FROM_FARE = 'fix_flat_and_percent_from_fare';