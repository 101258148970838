import { SET_ORDERS_DATA, SET_ORDERS_FILTERS, SET_CHILD_PARTNERS, SET_SHOW_ACTIONS_FOR_ORDER } from './constants';
import initialState from './initialState';

export const setOrdersData = (state = initialState, action) => {
  switch (action.type) {
      case SET_ORDERS_DATA:
        return {
            ...state,
            ordersData: action.payload.ordersData.data,
            pageSize: action.payload.ordersData.per_page,
            totalOrdersCount: action.payload.ordersData.total,
            isLoad: action.payload.loadStatus
        };
      case SET_ORDERS_FILTERS:
          return {
              ...state,
              filters: action.payload
          };
      case SET_CHILD_PARTNERS:
          return {
              ...state,
              childPartners: action.payload
          };
      case SET_SHOW_ACTIONS_FOR_ORDER:
          return {
              ...state,
              ordersData: state.ordersData.map((item) => {
                  return {
                      sortDate: item.sortDate,
                      orders: item.orders.map((order) => {
                          if(order.order_id === action.payload.orderId) {
                              return {
                                  ...order,
                                  showActionButtons: action.payload.actionButtonStatus
                              }
                          }
                          return order
                      })
                  }
              })
          };
    default:
        return state;
  }
};