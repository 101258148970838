import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {ArrowDown, LinkText} from "../../../../styles/common/index";
import {NavLink} from "react-router-dom";

class Link extends Component {
    render(){
        const { text, to, arrow, inverting} = this.props;

        return(
            <NavLink {...this.props} to={to}>
                <LinkText>{text}</LinkText>
                { arrow &&
                    <ArrowDown isInvert={inverting} />
                }
            </NavLink>
        )
    }
}

Link.defaultProps = {
    className: null,
    text: null,
    arrow: null,
    inverting: null,
    to: ''
};

Link.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    arrow: PropTypes.string,
    inverting: PropTypes.string,
    to: PropTypes.string
};

export default Link;