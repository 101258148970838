import React, { useEffect, useContext } from 'react';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { setAppFrameData } from '../containers/app-frame/actions';
import { connect } from 'react-redux';
import { LangContext } from '../providers/LangProvider';
import { AccessRoleContext } from '../providers/AccessRoleProvider';
import Config from '../config/config';
import { hasAdminRole, hasAdminManagerRoles } from '../components/result/common';

const authRoutes = Config.AuthRoutes();

const StyledTabs = withStyles({
    indicator: {
        display: 'none'
    },
})((props) =>
        <Tabs
            {...props}
            TabIndicatorProps={{ children: <div /> }}
        />
    );

const StyledTab = withStyles({
    root: {
        textTransform: 'none',
        letterSpacing: '-0.54px',
        fontSize: '18px',
        padding: 0,
        minWidth: '10px',
        marginRight: '32px',
        '&:hover': {
            color: '#c63f17',
            opacity: 1,
        },
        '&$selected': {
            color: '#ff7043',
            borderBottom: '2px solid #ff7043'
        }
    },
    disabled: {
        color: '#9da8ae'
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    selected: {}
})((props) => <Tab
    disableRipple {...props}
/>);

const useStyles = makeStyles({
    rootContainer: {
        backgroundColor: 'transparent',
        paddingTop: '16px'
    }
});

function NavTab(props) {
    const classes = useStyles();
    const { t } = useContext( LangContext );
    const { userRole } = useContext( AccessRoleContext );
    const { history } = props;

    const handleCallToRouter = (event, newValue) => {
        history.push(newValue);
    };

    return (
        <div className={ classes.rootContainer }>
            <StyledTabs
                scrollButtons='auto'
                variant='scrollable'
                value={ history.location.pathname }
                onChange={ handleCallToRouter }
            >
               {
                    hasAdminManagerRoles(userRole) && 
                        <StyledTab label={ t.main }
                            value={ authRoutes.partnerSittings }
                        />
                }
                <StyledTab label={ t.your_profile }
                    value={ authRoutes.personalInfo }
                />
                {
                    hasAdminManagerRoles(userRole) && 
                        <StyledTab label={ t.users }
                            value={ authRoutes.users }
                        />
                }
                <StyledTab label={ t.passenger_list }
                    value={ authRoutes.passengers }
                />
                {
                    hasAdminRole(userRole) && 
                        <StyledTab label={ t.affiliated_companies }
                            value={ authRoutes.companies }
                        />
                }
            </StyledTabs>
        </div>
    );
}

export const withNavTabsForContractSettings = (ComposedComponent) => {
    function RedirectComponent (props) {
        const { setAppFrameData } = props;

        useEffect( () => {
            setAppFrameData({
                showOrdersInHeader: true
            })

            return () => {
                setAppFrameData({
                    showOrdersInHeader: false
                })
            }
        }, []);

        return (
            <Container fixed>
                <NavTab { ...props }/>
                <ComposedComponent {...props} />
            </Container>
        );
    }

    const mapDispatchToProps = dispatch => bindActionCreators({
        setAppFrameData
    }, dispatch);

    return connect(null, mapDispatchToProps)(RedirectComponent);
};

