import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { setAppFrameData } from '../containers/app-frame/actions';
import { connect } from 'react-redux';

export const staticPages = (ComposedComponent) => {
    function RedirectComponent (props) {
        const { setAppFrameData } = props;

        useEffect( () => {
            setAppFrameData({
                headerWithoutImg: true,
                withoutFooter: false,
                withLogo: true,
            })

            return () => {
                setAppFrameData({
                    headerWithoutImg: true,
                    withoutFooter: true,
                    withLogo: true
                })
            }
        }, []);

        return <ComposedComponent { ...props }/>
    }

    const mapDispatchToProps = dispatch => bindActionCreators({
        setAppFrameData
    }, dispatch);

    return connect(null, mapDispatchToProps)(RedirectComponent);
};
