import React, { Component } from 'react';
import * as Styled from "../../../../styles/common/ui/checkboxForm/index";

class FormCheckbox extends Component {
    handelOnClick() {
        this.props.onSelect(!this.props.isChecked);
    }

    render() {
        const { isChecked, backgroundColor } = this.props;

         return(
            <Styled.CheckboxItem
                type="button"
                isActive={isChecked}
                backgroundColor={backgroundColor}
                onClick={() => this.handelOnClick()}
            />
        );
    }
}

FormCheckbox.defaultProps = {
    onSelect: ()=> {},
    isChecked: false,
    backgroundColor: null
};

export default FormCheckbox;
