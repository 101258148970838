import { SET_APP_FRAME_DATA } from './constants';
import initialState from './initialState';

export const setAppFrameData = (state = initialState, action) => {
  switch (action.type) {
      case SET_APP_FRAME_DATA:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};



