import { SET_CLIENT_DATA, SET_IS_AUTH, SET_PARTNER_LOGO, SET_CLIENT_DATA_ALERT } from './constants';

export const setClientData = clientData => dispatch => dispatch({
    type: SET_CLIENT_DATA,
    payload: clientData
});

export const setIsAuth = isAuth => dispatch => dispatch({
    type: SET_IS_AUTH,
    payload: isAuth
});

export const setIsShowEmptyClientDataAlert = isAuth => dispatch => dispatch({
    type: SET_CLIENT_DATA_ALERT,
    payload: isAuth
});

export const setPartnerLogo = data => dispatch => dispatch({
    type: SET_PARTNER_LOGO,
    payload: data
});