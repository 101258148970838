import { BY_MIN_PRICE } from './constants';

const initialState = {
    recommendations: [],
    brandFares: {},
    brandRules: {},
    included: {},
    filterParams: {
        baggage: {
            withBaggage: true,
            withoutBaggage: true
        },
        transfers: {
            withoutTransfers: true,
            oneTransfers: true,
            manyTransfers: true,
        },
        allTransfers: true,
        sortType: BY_MIN_PRICE,
        airlines: [],
        airports: false,
        duration: false,
        arrivalTime: false,
        departureTime: false,
        flightNumbers: false,
        routesData: [],
        priceRange: {},
        flightNumbersList: null,
        recId: null
    },
    session: null,
    isLoad: false,
    searchParams: {
        searchParams: null,
        formField: null,
        passengersData: null
    }
};

export default initialState;