import React, { useState, useEffect, useContext } from 'react';
import { LangContext } from '../../providers/LangProvider';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../../containers/search/styles';
import moment from 'moment';
import { Dialog, DialogContent, DialogTitle, Button, IconButton, ExpansionPanel, 
    ExpansionPanelSummary, ExpansionPanelDetails, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TimerIcon from '@material-ui/icons/Timer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getActualHistory, addToLocalStorage } from '../../lib/searchHistory';

const ShowHistorySearch = () => {
    const [searchHistory, setSearchHistory] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const { t, language } = useContext(LangContext)
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        const resultHistory = async () => {
            const actualHistory = await getActualHistory();
            setSearchHistory(actualHistory.reverse());
        };

        resultHistory();
    }, []);

    const flightClassItems = [
        { value: 'economy', label: t.economy },
        { value: 'business', label: t.business },
        { value: 'first', label: t.first },
        { value: 'all', label: t.all_class }
    ];

    const onClose = () => {
        setOpenModal(false);
    };

    const goToTheHistoryPage = searchId => {
        history.push(`/result/${searchId}`);
        window.location.reload();
    };

    const clearHistorySearch = () => {
        setSearchHistory([]);
        addToLocalStorage([]);
    };

    const removeFromSearchHistory = searchTime => {
        const result = searchHistory.filter(x => x.searchTime !== searchTime);
        setSearchHistory(result);
        addToLocalStorage(result);
    };

    const renderHistoryItem = item => {
        const { searchTime, searchId, flightInfo, passengers } = item;

        return (
            <div key={ searchTime } className={ classes.mainHistoryBlock }>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={ <ExpandMoreIcon /> }>
                        <div className={ classes.routes }>
                            { flightInfo.routes[0].from.value[language] }({ flightInfo.routes[0].from.code })
                            - { flightInfo.routes[flightInfo.routes.length - 1].to.value[language] }({ flightInfo.routes[flightInfo.routes.length - 1].to.code }) 
                            - { moment.unix(flightInfo.routes[0].date_from).format('DD.MM.YYYY') }
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className={ classes.details }>
                            <div className={ classes.searchedTime }> 
                                <TimerIcon fontSize='small' />
                                <span className={ classes.time }>{ moment.unix(searchTime).format('DD.MM.YYYY HH:mm') }</span>
                            </div>
                            <div className={ classes.passengers }>
                                { t.passengers }: { passengers.passengersCount }
                                <span className={ classes.passengersInfo }>({ t.many_adt }: { passengers.passengers.adt }, { t.many_chd }: { passengers.passengers.chd }, { t.many_inf } ({t.inf_with_seat_second_label}): { passengers.passengers.ins }, { t.many_inf }: { passengers.passengers.inf })</span>    
                            </div>
                            <div>{ t.class }: { flightClassItems[flightClassItems.findIndex(x => x.value === flightInfo.cabin)].label }</div>
                            <div className={ classes.searchButtons }>
                                <Button 
                                    onClick={ () => goToTheHistoryPage(searchId) }
                                    className={ classes.openBrowser }
                                >
                                    { t.go_to_page }
                                </Button>
                                <Button 
                                    className={ classes.deleteIcon } 
                                    onClick={ () => removeFromSearchHistory(searchTime) } 
                                >
                                    { t.remove }
                                </Button>
                            </div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        )
    };

    return (
        <div>
            <Button 
                className={ classes.historyButton } 
                onClick={ () => setOpenModal(true) }
            >
                { t.history_search }
            </Button>
            <Dialog 
                open={ openModal } 
                scroll={ 'paper' }
                aria-labelledby={ 'mobile-recommendation-info-modal' }
                fullWidth={ true }
                maxWidth={ 'xs' }
            >
                <DialogTitle 
                    id={ 'mobile-recommendation-info-modal-title' }
                    className={ classes.titleStyles }
                    disableTypography={ true }
                >
                    { t.history_search }
                    <IconButton 
                        aria-label={ 'close-modal' } 
                        onClick={ () => onClose() }
                        className={ classes.closeButtonStyles }
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={ classes.dialogContent }>
                    {
                        searchHistory.map(value => renderHistoryItem(value))
                    }
                    
                    { 
                        searchHistory.length > 0
                            ?
                            <div className={ classes.footer }>
                                <Button 
                                    onClick={ () => clearHistorySearch() }
                                    className={ classes.clearAllHistory }
                                >
                                    { t.clear }
                                </Button>
                            </div> 
                            : 
                            <div className={ classes.footer }>
                                { t.no_result }
                            </div>
                    }
                </DialogContent>
            </Dialog>
        </div>
    )
};

export default ShowHistorySearch;