import { createStore, applyMiddleware, compose } from 'redux'
import { enableBatching } from 'redux-batched-actions';
import indexReducer from './reducers';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares = [
    thunkMiddleware
];

if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger({ collapsed: true }));
}

export default function configureStore(initialState = {}) {
    return createStore(
        enableBatching(indexReducer),
        initialState,
        composeWithDevTools(
            compose(
                applyMiddleware(...middlewares)
            )
        ),
    );
}