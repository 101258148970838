import React, { useContext } from 'react';
import { LangContext } from '../../providers/LangProvider';
import SelectTransparentUI from '../ui/components/SelectTransparentUI/SelectTransparentUI';

function FlightClassField (props) {
    const { onChangeFlightClass, input, cabin } = props;
    const { t } = useContext( LangContext );

    const flightClassItems = [
        { value: 'economy', label: t.economy },
        { value: 'business', label: t.business },
        { value: 'first', label: t.first },
        { value: 'all', label: t.all_classes }
    ];

    return <SelectTransparentUI
        items={ flightClassItems }
        value={ cabin }
        input={ input }
        onChange={ onChangeFlightClass }
    />
}

export default FlightClassField;