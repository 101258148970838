import { OW, RT, CF, LIMITS } from '../containers/search/constants';
import moment from 'moment';
import { API } from './api';
import { parserLangCode } from '../components/result/common';

export const encodeSearchId = (flightType, formValues, passengersData) => {
    const { passengers } = passengersData;

    if(flightType === OW) {
        const from = formValues.routes[0].from.code;
        const to = formValues.routes[0].to.code;
        const date = moment.unix(formValues.routes[0].date_from).format('DDMM');
        const cabin = formValues.cabin.charAt(0).toUpperCase();
        const passengersCode = getPassengersCode(passengers);
        return `${flightType}-${from}${date}${to}-${cabin}${passengersCode}`;
    } else if(flightType === RT) {
        const from = formValues.routes[0].from.code;
        const to = formValues.routes[0].to.code;
        const date_from = moment.unix(formValues.routes[0].date_from).format('DDMM');
        const date_to = moment.unix(formValues.routes[0].date_to).format('DDMM');
        const cabin = formValues.cabin.charAt(0).toUpperCase();
        const passengersCode = getPassengersCode(passengers);
        return `${flightType}-${from}${date_from}${to}${date_to}-${cabin}${passengersCode}`;
    } else {
        let searchId = `${flightType}-`;
        formValues.routes.map(value => {
            const from = value.from.code;
            const to = value.to.code;
            const date = moment.unix(value.date_from).format('DDMM');
            searchId += `${from}${date}${to}-`;
        });
        const cabin = formValues.cabin.charAt(0).toUpperCase();
        const passengersCode = getPassengersCode(passengers);
        return `${searchId}${cabin}${passengersCode}`;
    }
};

export const decodeSearchId = async searchId => {
    const data = searchId.split('-');
    if(data.length > 0){
        const flightType = data[0];
        const searchParams = await getSearchParams(flightType, data);
        const passengersData = getPassengersData(searchParams.passengers);
        const formField = getFormField(flightType, searchParams);
        return {
            formField,
            passengersData,
            searchParams
        }
    } else {
        throw {
            error_code: 'Validate',
            error_desc: 'Не правильно указанные данные. Проверьте правильность введенных данных и повторите попытку!'
        };
    }
};

const getPassengersCode = (passengers) => {
    let result = `${passengers.adt}`;
    
    if (passengers.chd > 0) {
        result = `${result}${passengers.chd}`;
    } 

    if (passengers.ins > 0) {
        result = `${result}${passengers.chd === 0 ? 0 : ''}${passengers.ins}`;
    }

    if (passengers.inf > 0) {
        result = `${result}${passengers.chd === 0 ? 0 : ''}${passengers.ins === 0 ? 0 : ''}${passengers.inf}`;
    }

    return result;
}

const getPassengersFromSearchId = passengers => {
    const adt = parseInt(passengers.substr(1, 1)) || 1;
    const chd = parseInt(passengers.substr(2, 1)) || 0;
    const ins = parseInt(passengers.substr(3, 1)) || 0;
    const inf = parseInt(passengers.substr(4, 1)) || 0;

    if(adt > 9 || adt + chd + ins + inf > 9 || adt < inf) {
        throw {
            error_code: 'Validate',
            error_desc: 'Не правильно указанные данные. Проверьте правильность введенных данных и повторите попытку!'
        };
    }

    return { adt, chd, ins, inf };
};

const getCabinFromSearchId = cabinCode => {
    switch(cabinCode) {
        case 'A': return 'all';
        case 'E': return 'economy';
        case 'B': return 'business';
        case 'F': return 'first';
        default: return 'all';
    }
};

const getRouteFromSearchId = (flightType, route) => {
    if(flightType === RT) {
        return [
            {
                from: route.substr(0, 3),
                to: route.substr(7, 3),
                date: getDateFromSearchId(route.substr(3, 4))
            }, {
                from: route.substr(7, 3),
                to: route.substr(0, 3),
                date: getDateFromSearchId(route.substr(10))
            }
        ];
    } else {
        return {
            from: route.substr(0, 3),
            to: route.substr(7),
            date: getDateFromSearchId(route.substr(3, 4))
        }
    }
};

const getFullRouteInfoFromSearchId = (from, to, value) => {
    return {
        date_from: moment(from.date).unix(),
        date_to: to ? moment(to.date).unix() : null,
        from: {
            code: from.from,
            value: value.from
        },
        to: {
            code: from.to,
            value: value.to
        }
    }
}

const getDateFromSearchId = date => {
    const today = moment();
    const currentYear = today.get('year');
    const selectedDate = `${currentYear}-${date.substr(2)}-${date.substr(0, 2)}`;
    const momentSelectedDate = moment(selectedDate);
    const format = 'YYYY-MM-DD';

    if (
        momentSelectedDate.isSameOrAfter(today.format(format)) &&
        momentSelectedDate.isSameOrBefore(today.add(9, 'M').format(format))
    ) {
        return selectedDate;
    }

    const nextYear = momentSelectedDate.add(1, 'y');

    if (moment(nextYear).isSameOrAfter(today.add(9, 'M').format(format))) {
        throw {
            error_code: 'Validate',
            error_desc: 'Не правильно указанные данные. Проверьте правильность введенных данных и повторите попытку!',
        };
    }

    return nextYear.format(format);
};

const getLoadingDataFromSearchId = async routes => {
    const loadingData = [];

    for(let i = 0; i < routes.length; i++) {
        const from = await getCityInfoByCode(routes[i].from);
        const to = await getCityInfoByCode(routes[i].to);
        loadingData.push(getLoadingRoute(from, to, moment(routes[i].date)));
    }

    return loadingData;
};

const getCityInfoByCode = async code => {
    const cities = await API.getCities(code, { language: 'en' })
                            .then(response => {
                                return response;
                            });
    if(cities.length > 0){
        const result = cities.filter(city => city.code === code);
        if(result.length > 0) {
            return result[0];
        }
    } else {
        throw {
            error_code: 'Validate',
            error_desc: 'Указанный город или аэропорт не найден. Проверьте правильность введенных данных и повторите попытку!'
        };
    }
};

const getLoadingRoute = (from, to, date) => {
    return {
        'from': from.value,
        'to': to.value,
        'date': date
            .locale(parserLangCode('ru'))
            .format('DD MMM YYYY')
            .replace('.', '')
    }
};

const getFormField = (flightType, searchParams) => {
    const fields = {
        cabin: searchParams.cabin,
        flightType,
        routes: []
    }
    if(flightType === OW) {
        fields.routes = [getFullRouteInfoFromSearchId(searchParams.routes[0], null, searchParams.loadingRoutes[0])];
    } else if(flightType === RT) {
        fields.routes = [getFullRouteInfoFromSearchId(searchParams.routes[0], searchParams.routes[1], searchParams.loadingRoutes[0])];
    } else if(flightType === CF) {
        const routes = [];
        for (let i = 0; i < searchParams.routes.length; i++){
            routes.push(getFullRouteInfoFromSearchId(searchParams.routes[i], null, searchParams.loadingRoutes[i]));
        }
        fields.routes = routes;
    }
    return fields;
}

const getPassengersData = passengers => {
    const passengersCount = passengers.adt + passengers.chd + passengers.ins + passengers.inf;
    return {
        isMaximumInf: passengers.inf >= passengers.adt,
        isMaximumPassengers: passengersCount >= LIMITS,
        passengers,
        passengersCount
    }
};

const getSearchParams = async (flightType, data) => {
    const params = {
        cabin: '',
        flightType,
        loadingRoutes: [],
        passengers: {},
        routes: []
    };
    if(data.length < 2) {
        throw {
            error_code: 'Validate',
            error_desc: 'Не правильно указанные данные. Проверьте правильность введенных данных и повторите попытку!'
        };
    }
    if(!data[2]) {
        data[2] = 'A1000';
    }
    if(flightType === OW) {
        params.routes = [getRouteFromSearchId(flightType, data[1])];
        params.cabin = getCabinFromSearchId(data[2].substr(0, 1));
        params.loadingRoutes = await getLoadingDataFromSearchId(params.routes);
        params.passengers = getPassengersFromSearchId(data[2]);
    } else if(flightType === RT) {
        params.routes = [...getRouteFromSearchId(flightType, data[1])];
        params.cabin = getCabinFromSearchId(data[2].substr(0, 1));
        params.loadingRoutes = await getLoadingDataFromSearchId(params.routes);
        params.passengers = getPassengersFromSearchId(data[2]);
    } else if(flightType === CF) {
        const routes = [];
        for (let i = 1; i < data.length - 1; i++){
            routes.push(getRouteFromSearchId(flightType, data[i]));
        }
        params.routes = routes;
        params.cabin = getCabinFromSearchId(data[data.length - 1].substr(0, 1));
        params.loadingRoutes = await getLoadingDataFromSearchId(routes);
        params.passengers = getPassengersFromSearchId(data[data.length - 1]);
    } else {
        throw {
            error_code: 'Validate',
            error_desc: 'Не правильно указанные данные. Проверьте правильность введенных данных и повторите попытку!'
        };
    }

    return params;
}
