import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Field, FieldArray } from 'redux-form';
import DirectionField from './directionField';
import FlightClassField from './flightClassField';
import PassengersSelectorForm from './passengersSelectorForm';
import { useStyles } from '../../containers/search/styles';
import { OW, RT, CF } from '../../containers/search/constants';
import { AddRouteButtonStyled, SearchContainer, FlightTypeBlockStyled, 
    FlightClassBlockStyled } from '../../styles/search/index';
import { StyledMobileSearchButton } from '../../styles/common/index';
import { LangContext } from '../../providers/LangProvider';
import moment from 'moment';
import device from 'current-device';
import ShowHistorySearch from './showHistorySearch';

function SearchOptionsForm (props) {
    const classes = useStyles();
    const { language } = useContext( LangContext );

    const { changeDirection, isResultPage, onSend, handleSubmit, onChangeFlightClass, 
        routesCount, direction, addRoute, routesFields, passengersData, cabin, 
        minimizeSearch, setMinimizeSearch, formValues } = props;

    const addRouteButton = direction === CF && routesCount < 4;

    const [windowWidth, setWindowWidth] = useState(window.screen.width);
    const [hiddenRoutes, setHiddenRoutes] = useState(0);
    const [top, setTop] = useState(0);
    const [shouldOpen, setShouldOpen] = useState(false);

    useEffect(() => {
        const handleChangeWidth = () => {
            setWindowWidth(window.screen.width);
        };

        if(isResultPage) {
            window.addEventListener('resize', handleChangeWidth);
            checkWidth();
        }

        return () => {
            window.removeEventListener('resize', handleChangeWidth);
        }
    }, []);

    useEffect(() => {
        checkWidth();
    }, [windowWidth]);

    useEffect(() => {
        if(isResultPage) {
            if(formValues && formValues.flightType === CF) {
                if(formValues.routes[0].from && formValues.routes[0].to && formValues.routes[0].date_from) {
                    if(windowWidth > 960 && !minimizeSearch && top > 0) {
                        setMinimizeSearch(true);
                    }
                }
            }

            if(shouldOpen && top === 0 && formValues.flightType === CF) {
                setMinimizeSearch(false);
                setShouldOpen(false);
            }
        }
    }, [top]);

    const handleScroll = () => {
        setTop(document.documentElement.scrollTop);
    };

    useEffect(() => {
        checkWidth();

        if(formValues && windowWidth > 960) {
            if(formValues.flightType !== CF) {
                setMinimizeSearch(false);
            } else {
                document.addEventListener('scroll', handleScroll, false);

                return () => document.removeEventListener('scroll', handleScroll, false);
            }
        }
    }, [formValues]);

    const checkWidth = () => {
        if(formValues && formValues.flightType === CF && routesCount > 2) {
            setHiddenRoutes(0);
            for(let i = 3; i <= routesCount; i++) {
                if(windowWidth < (115 * i) + 48) {         // 115 (рх) - Длина элемента, i (количество роутов), 48 (рх) - Длина padding-ов вокруг элементов
                    if(hiddenRoutes + 2 <= routesCount)
                        setHiddenRoutes(routesCount - (hiddenRoutes + 2));
                } else {
                    setHiddenRoutes(0);
                }
            }            
        }
    };
    const getFormattedValue = (value) => {
        if(value) {
            return moment
                .unix(value)
                .locale(language)
                .format('DD.MM.YYYY');
        }

        return '';
    };

    const renderRoutesItems = (type, routes) => {
        if(type === OW) {
            return (
                <div className={ classes.searchMinimizedItem }>
                    <div className={ classes.searchMinimizedItemFlight }>{routes[0].from.code} - {routes[0].to.code}</div>
                    <div className={ classes.searchMinimizedItemTime }>{getFormattedValue(routes[0].date_from)}</div>
                </div>
            )
        } else if(type === RT && routes[0].date_to) {
            return (
                <>
                    <div className={ classes.searchMinimizedItem }>
                        <div className={ classes.searchMinimizedItemFlight }>{routes[0].from.code} - {routes[0].to.code}</div>
                        <div className={ classes.searchMinimizedItemTime }>{getFormattedValue(routes[0].date_from)}</div>
                    </div>
                    <div className={ classes.searchMinimizedItem }>
                        <div className={ classes.searchMinimizedItemFlight }>{routes[0].to.code} - {routes[0].from.code}</div>
                        <div className={ classes.searchMinimizedItemTime }>{getFormattedValue(routes[0].date_to)}</div>
                    </div>
                </>
            )
        } else if(type === RT && !routes[0].date_to) {
            return (
                <div className={ classes.searchMinimizedItem }>
                    <div className={ classes.searchMinimizedItemFlight }>{routes[0].from.code} - {routes[0].to.code}</div>
                    <div className={ classes.searchMinimizedItemTime }>{getFormattedValue(routes[0].date_from)}</div>
                </div>
            )
        } else if(type === CF) {
            return routes.map((value, index) => {
                if(value.from && value.to && value.date_from)
                    if(index < routesCount - hiddenRoutes)
                        return (
                            <div className={ classes.searchMinimizedItem } key={ `route-item-${index}` }>
                                <div className={ classes.searchMinimizedItemFlight }>{value.from.code} - {value.to.code}</div>
                                <div className={ classes.searchMinimizedItemTime }>{getFormattedValue(value.date_from)}</div>
                            </div>
                        )
            })
        }
    };

    const handleFalseMinimizing = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setShouldOpen(true);
        setMinimizeSearch(false);
    };

    const renderHiddenRoutesCount = () => {
        if(hiddenRoutes > 0) {
            return (
                <div className={ classes.searchHiddenRoutesCount }>
                    +{ hiddenRoutes }
                </div>
            )
        } else {
            return null;
        }
    };

    const renderMinimizedContainer = () => {
        return (
            <>
                <div 
                    className={ classes.searchMinimizedContainer }
                    onClick={ () => handleFalseMinimizing() }
                >
                    { renderRoutesItems(formValues.flightType, formValues.routes) }
                </div>
                { renderHiddenRoutesCount() }
            </>
        )
    };

    const renderFullContainer = () => {
        return (
            <form onSubmit={ handleSubmit(onSend) } autoComplete='off'>
                <Grid container>
                    <FieldArray
                        name='routes'
                        component={ routesFields }
                    />
                    { addRouteButton &&
                        <Grid item className={ classes.addRouteButtonBlock }>
                            <AddRoutesButtonBlock
                                addRoute={ addRoute }
                            />
                        </Grid>
                    }
                    <Grid item className={ classes.flightOptionsContainer }>
                        <Grid container className={ classes.flightOptionsBlock }>
                            <Grid item className={ classes.flightType }>
                                <FlightType
                                    changeDirection={ changeDirection }
                                    direction={ direction }
                                />
                            </Grid>
                            <Grid item className={ classes.passengersSelector }>
                                <PassengersSelectorForm
                                    passengersData={ passengersData }
                                />
                            </Grid>
                            <Grid item className={ classes.flightClass }>
                                <FlightClass
                                    onChangeFlightClass={ onChangeFlightClass }
                                    cabin={ cabin }
                                />
                            </Grid>
                            <Grid item className={ classes.flightHistory }>
                                <ShowHistorySearch />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={ classes.mobileSearchSubmit }>
                        <SearchSubmit/>
                    </Grid>
                    { renderMinimizeButton() }
                </Grid>
            </form>
        )
    };

    const renderMinimizeButton = () => {
        const arrowUp = 'data:image/svg+xml;utf8,<svg width="55" height="10" viewBox="0 0 55 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.6" d="M0 8.12998C0 7.46527 0.450887 6.88523 1.09506 6.72126L26.76 0.188373C27.2456 0.0647617 27.7544 0.064762 28.24 0.188373L53.9049 6.72126C54.5491 6.88523 55 7.46527 55 8.12998C55 9.07825 54.1067 9.77262 53.1878 9.5387L28.24 3.18837C27.7544 3.06476 27.2456 3.06476 26.76 3.18837L1.81223 9.5387C0.893268 9.77262 0 9.07825 0 8.12998Z" fill="white"/></svg>';
        let show = false;

        if(isResultPage && !minimizeSearch) {
            if(windowWidth > 960){
                if(formValues.flightType === CF) {
                    show = true;
                }
            } else {
                show = true;       
            }
        }

        if(show) {
            return (
                <div className={ classes.minimizeButton }>
                    <img 
                        onClick={ () => setMinimizeSearch(true) }
                        src={ arrowUp } 
                        alt={ 'arrow-up' } 
                        className={ classes.minimizeButtonIcon }
                    />
                </div>
            );
        } else {
            return null;
        }
    };

    const renderContent = () => {
        if(device.mobile() || device.tablet()) {
            return minimizeSearch ? renderMinimizedContainer() : renderFullContainer();
        } else {
            if(formValues && formValues.flightType == CF) {
                return minimizeSearch ? renderMinimizedContainer() : renderFullContainer();
            } else {
                return renderFullContainer();
            }
        }
    };

    return (
        <SearchContainer isResultPage={ isResultPage } minimizeSearch={ minimizeSearch }>
            <Container fixed>
                { formValues && renderContent() }
            </Container>
        </SearchContainer>
    );
}

const AddRoutesButtonBlock = props => {
    const { addRoute } = props;

    return (
        <AddRouteButton addRoute={ addRoute } />
    );
};

const SearchSubmit = () => {
    const { t } = useContext(LangContext);

    return (
        <StyledMobileSearchButton type='submit'>
            { t.find }
        </StyledMobileSearchButton>
    )
};

const FlightType = props => {
    const { changeDirection, direction } = props;

    return (
        <FlightTypeBlockStyled>
            <Field
                name='flightType'
                component={ DirectionField }
                onChangeDirection={ changeDirection }
                direction={ direction }
            />
        </FlightTypeBlockStyled>
    )
};

const FlightClass = props => {
    const { onChangeFlightClass, cabin } = props;

    return (
        <FlightClassBlockStyled>
            <Field
                name='cabin'
                component={ FlightClassField }
                onChangeFlightClass={ onChangeFlightClass }
                cabin={ cabin }
            />
        </FlightClassBlockStyled>
    )
};

export const AddRouteButton = props => {
    const classes = useStyles();
    const { t } = useContext( LangContext );
    const { addRoute } = props;

    return (
        <AddRouteButtonStyled
            type='button'
            onClick={ addRoute }
        >
            <div className={ classes.addRouteButtonTitle }>
                { t.add_route }
            </div>
        </AddRouteButtonStyled>
    )
};

export default SearchOptionsForm;
