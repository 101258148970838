import React, { Fragment, useContext } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { setAppFrameData } from '../app-frame/actions';
import * as Styled from '../../styles/loadingResult/index';
import { LangContext } from '../../providers/LangProvider';

function LoadingCommonContainer () {
    return <LoadingBlock/>
}

const LoadingBlock = () => {
    const { t } = useContext( LangContext );

    return(
        <Fragment>
            <Styled.TitleBlock>
                <Styled.RtTitle>
                    <Styled.TitleItem>{ t.wait_please }</Styled.TitleItem>
                </Styled.RtTitle>
            </Styled.TitleBlock>
            <LoadingSniper/>
        </Fragment>
    );
}

const LoadingSniper = () => {
    return(
        <Styled.LoadingSniperBlock>
            <Styled.LoadingSniper/>
        </Styled.LoadingSniperBlock>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setAppFrameData
}, dispatch);

export default compose(
    connect(null, mapDispatchToProps)
)(LoadingCommonContainer);
