import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { SESSION } from "../../../../containers/common/constants";
import { isB2c } from "../../../../config/config";

const useStyles = makeStyles({
    sessionBlock: {
        color: '#7e878b',
        fontSize: '12px'
    },
});

export default function SessionBlock(props) {
    const classes = useStyles();
    const { session } = props;

    if (isB2c) {
        return null;
    }

    return (
        <Box className={ classes.sessionBlock }>
            { SESSION }: { session }
        </Box>
    );
};