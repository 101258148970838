import styled from 'styled-components'
import { SelectArrowDown } from '../../index';

export const DropdownSelectInput = styled.input`
    cursor: pointer;
    background-color: transparent;
    border: ${props => props.withoutBorder && 'none'}; 
    color:  ${props => props.textColor || '#ffffff'};
    font-size:  ${props => props.fontSize || '16px'};
    caret-color: transparent;
    
    width:  ${props => props.widthSize || '160px'};
    
    ${props => props.withArea && `
        height: 14px;
        border-radius: 4px;
        border: solid 1px #cfd8dc;
        background-color: #ffffff;
        color: #212121;
    `}; 
    
    ${props => props.isPadding && `
        padding-left: 16px;
        padding-top: 30px;
        padding-bottom: 8px;
    `}
    
    ${props => props.withoutLabel && `
        padding-left: 16px;
        padding-top: 18px;
        padding-bottom: 18px;
    `}
    
    :focus { 
        outline: none;
    }
`;

export const DropdownBlock = styled.div`
    position: relative;
`;

export const ArrowDown = styled(SelectArrowDown)`
`;

export const ArrowDownContainer = styled.div`
    display: inline-table;
    
    ${props => props.withArea && `
        position: relative;
        right: 18px;
        bottom: '10px;
    `}; 
`;

export const ArrowDownWithoutLabelContainer = styled.div`
    display: inline-block;
    position: absolute;
    right: 18px;
    bottom: 14px;
`;